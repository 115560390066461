import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a007338"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "inline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_ctx.hero)
    ? (_openBlock(), _createBlock(_component_v_card, {
        key: 0,
        class: _normalizeClass(`pa-2 ma-1 flex-grow-1 boksi ${_ctx.imgName} `)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                class: "type_img",
                src: `${_ctx.publicPath}images/${_ctx.imgName}.png`
              }, null, 8, _hoisted_1),
              _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.hero) + "% " + _toDisplayString(_ctx.title), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}