
import { defineComponent, ref } from "vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";

const __default__ = defineComponent({
  el: "#login_modal",
  name: "LoginForm",
  data: () => ({
    selectedIntent: "Your Best Opportunities",
    publicPath: process.env.BASE_URL,
    stayLoggedIn: false,
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
    imageUrl: ref(`url(${process.env.BASE_URL}images/content_marketing.png)`),
  }),
  mounted() {
    this.$el.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.code === "Enter") {
        this.validateAndSubmit();
      }
    });
  },
  unmounted() {
    this.$el.removeEventListener("keydown", KeyboardEvent);
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  methods: {
    validateAndSubmit() {
      const emailRegex = /.*@[a-zA-Z0-9-]*\.[a-zA-Z]*/g;
      let hasErrors = false;
      if (!this.email) {
        this.emailError = "Please fill out your email";
        hasErrors = true;
      } else if (!this.email.match(emailRegex)) {
        this.emailError = "Not a valid email";
        hasErrors = true;
      } else {
        this.emailError = "";
      }
      if (!this.password) {
        this.passwordError = "Please fill out your password";
        hasErrors = true;
      } else {
        this.passwordError = "";
      }
      if (!hasErrors) {
        this.store.dispatch("login", {
          email: this.email,
          password: this.password,
          stayLoggedIn: this.stayLoggedIn,
        });
      }
    },
    goToPasswordResetView() {
      this.router.push("/reset_password/trigger");
    },
  },
  components: { MaireButton },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7c0bb035": (_ctx.imageUrl)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__