<template>
  <Transition>
    <div class="trending-checkboxes">
      <div class="btn">
        <MaireButton
          :text="`${selected?.length ? 'Unselect All' : 'Select All'}`"
          compact
          :onClick="toggleAll"
        />
      </div>
      <v-layout :key="box.text" v-for="box in checkboxes">
        <v-checkbox
          :hide-details="true"
          class="shrink mr-0 mt-0 xs"
          :value="box.text"
          v-model="selected"
          :label="box.userText ?? box.text"
          :append-icon="box.icon"
        ></v-checkbox>
      </v-layout>
    </div>
  </Transition>
</template>

<style scoped>
.btn {
  width: 200px;
}
.slider {
  width: 100%;
}
i {
  float: left;
}
</style>
<script lang="ts" setup>
import { CheckboxState } from "@/store/modules/analysis/types";
import { onMounted, watch, ref, Ref, defineEmits, defineProps } from "vue";
import MaireButton from "../ButtonBlock/MaireButton.vue";

const emit = defineEmits(["change"]);
const props = defineProps<{ checkboxes: CheckboxState[] }>();
const selected: Ref<string[]> = ref([]);
const onValueChange = () => {
  emit("change", selected.value);
};

watch(selected, onValueChange, { deep: true });
onMounted(() => {
  if (props.checkboxes) {
    selected.value = props.checkboxes.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.text);
  }
});

const toggleAll = () => {
  if (selected.value?.length) {
    selected.value = [];
  } else {
    selected.value = props.checkboxes.map((checkbox) => checkbox.text);
  }
};
</script>
