<template>
  <div>
    <MaireDialog
      :title="`Share ${itemType} ${item.name}`"
      description=""
      confirm-text="Share"
      cancel-text="Close"
      :open="!!props.item"
      :confirmDisabled="!shareToCompany && shareToUserIds.length === 0"
      :hideCancel="false"
      :onClose="close"
      :fn="share"
    >
      <div id="share-modal">
        <v-switch v-model="shareToCompany" color="purple" label="Share company wide" hide-details />
        <v-select
          v-if="!shareToCompany"
          :items="company.active_users"
          :item-props="userToSelectItem"
          variant="outlined"
          v-model="shareToUserIds"
          label="Share to users"
          density="compact"
          persistent-hint
          multiple
          hide-details
        >
        </v-select>
        <v-switch v-model="shareWithEditAccess" color="purple" label="Edit access" hide-details />
        <div>
          <h3>Shared to</h3>
          <v-progress-circular
            v-if="loadingShares"
            size="20"
            indeterminate
            color="#8200FF"
          ></v-progress-circular>
          <div v-else-if="shares.user.length == 0 && shares.company.length == 0" class="smallText">
            Not shared yet
          </div>
          <v-list v-else density="compact">
            <v-list-item
              v-for="c in shares.company"
              :key="`${c.id}${c.edit_access}`"
              :title="company.name"
              subtitle="Company"
            >
              <template v-slot:append>
                <v-icon>{{ c.edit_access ? "mdi:mdi-pencil" : "mdi:mdi-eye" }}</v-icon
                >&nbsp;
                <v-icon @click="() => unShare('company', c.id)" color="red">mdi:mdi-delete</v-icon>
              </template>
            </v-list-item>
            <v-list-item
              v-for="user in userShares"
              :key="`${user.id}${user.edit_access}`"
              :title="user.name"
              :subtitle="user.email"
            >
              <template v-slot:append>
                <v-icon>{{ user.edit_access ? "mdi:mdi-pencil" : "mdi:mdi-eye" }}</v-icon
                >&nbsp;
                <v-icon @click="() => unShare('user', user.id)" color="red">mdi:mdi-delete</v-icon>
              </template>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </MaireDialog>
    <MaireDialog
      :onClose="() => (confirmUnshareOpen = false)"
      :open="confirmUnshareOpen"
      :description="`The ${itemType} can be shared again from this view.`"
      :fn="() => unshareFunction()"
    />
  </div>
</template>

<style scoped>
#share-modal {
  color: black;
}
h3 {
  font-size: 20px;
  line-height: 20px;
  margin-top: 10px;
}

.v-list {
  margin-top: 0px;
  padding-top: 0px !important;
}

.v-list-subheader {
  padding-inline-start: 0 !important;
  font-size: 30px !important;
}

.v-list-item {
  text-align: left;
  padding-inline-start: 0 !important;
}

.v-icon {
  font-size: 20px;
}

.smallText {
  width: 100%;
  text-align: left;
  font-size: 14px;
  margin-top: 10px;
}
</style>

<script setup lang="ts">
import { defineProps, ref, computed, onMounted } from "vue";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import { Analysis, View, ItemShares } from "@/store/modules/analysis/types";
import { Company, CompanyUser, useStore } from "@/store";
import { fetchShares } from "@/api";

const props = defineProps<{
  item: Analysis | View;
  itemType: "analysis" | "view";
  clearItem: Function;
}>();

const store = useStore();

const shareToCompany = ref<boolean>(false);
const shareToUserIds = ref<number[]>([]);
const shareWithEditAccess = ref<boolean>(false);
const loadingShares = ref<boolean>(false);
const shares = ref<ItemShares>({ company: [], user: [] });

const confirmUnshareOpen = ref<boolean>(false);
const unshareFunction = ref<Function>(() => {});

const company = computed<Company>(() => {
  return store.state.company;
});

const parseUserName = (user?: CompanyUser) => (user ? `${user.first_name} ${user.last_name}` : "Unknown");

const userShares = computed<{ id: number; edit_access: boolean; name: string; email: string }[]>(() => {
  return shares.value.user.map((user) => {
    const companyUser = company.value.active_users?.find((companyUser) => companyUser.id === user.id);
    return {
      ...user,
      name: parseUserName(companyUser),
      email: companyUser?.email || "",
    };
  });
});

const refreshShares = () => {
  loadingShares.value = true;
  fetchShares(props.itemType, props.item.id)
    .then((r) => {
      loadingShares.value = false;
      shares.value = r.data;
    })
    .catch(() => {
      loadingShares.value = false;
    });
};

onMounted(() => {
  refreshShares();
});

const userToSelectItem = (user: CompanyUser) => ({
  value: user.id,
  title: parseUserName(user),
  subtitle: user.email,
});

const share = () => {
  const payload = {
    itemType: props.itemType,
    item: props.item,
    editAccess: shareWithEditAccess.value,
    refreshShares,
  };
  if (shareToCompany.value) {
    store.dispatch("analysis/shareItem", {
      ...payload,
      actorType: "company",
      actorIds: [company.value.id],
    });
  } else if (shareToUserIds.value.length > 0) {
    store.dispatch("analysis/shareItem", {
      ...payload,
      actorType: "user",
      actorIds: shareToUserIds.value,
    });
  }
};

const unShare = (actorType: "company" | "user", actorId: number) => {
  confirmUnshareOpen.value = true;
  unshareFunction.value = () => {
    store.dispatch("analysis/unShareItem", {
      itemType: props.itemType,
      item: props.item,
      actorType,
      actorIds: [actorId],
      refreshShares,
    });
    confirmUnshareOpen.value = false;
  };
};

const close = () => {
  props.clearItem();
};
</script>
