export const EXAMPLE_FILTERS = {
    searchWord: "",
    volumeRange: {
      rangeMin: 0,
      rangeMax: 100000,
    },
    positionRange: {
      rangeMin: 0,
      rangeMax: 102,
    },
    trendingFilter: [
      {
        text: "Rocketing +50%",
        value: "rocketing",
        checked: false,
        icon: "fa-regular fa-rocket",
      }, //rocket icon
      {
        text: "On fire +25-50%",
        value: "on_fire",
        checked: false,
        icon: "fas fa-fire",
      }, //ban icon
      {
        text: "On the rise +1-25%",
        value: "on_the_rise",
        checked: false,
        icon: "fa-light fa-chart-mixed",
      }, //ban icon
      {
        text: "Declining",
        value: "declining",
        checked: false,
        icon: "fa-regular fa-chart-line-down",
      }, //ban icon
    ],
    countryFilter: [],
    languageFilter: [],
    labelFilters: {
      include: {
        labels: [],
      },
    },
  };
  
  export const generateExampleFilters = () => {
    return JSON.parse(JSON.stringify(EXAMPLE_FILTERS));
  };