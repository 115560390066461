<template>
  <v-container>
    <iframe width="100%" height="100%" :src="source?.replace('watch?v=', 'embed/')"> </iframe>
    <h4>{{ title }}</h4>
    <p>{{ channel }}</p>
    <p>{{ timestamp }}</p>
  </v-container>
</template>
<style scoped>
.video {
  max-width: 100%;
}

h1,
h4 {
  color: white !important;
}

p {
  font-size: 12px;
  color: white;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "VideoCard",
  props: {
    source: String,
    channel: String,
    title: String,
    timestamp: String,
  },
});
</script>
