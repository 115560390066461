import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62343f62"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "m"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MaireButton = _resolveComponent("MaireButton")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`container ${$props.alternative ? 'alternative' : ''} ${$props.category}`)
  }, [
    _createElementVNode("div", {
      class: "content",
      style: _normalizeStyle($props.buttonType !== 'compact' ? { '--padding': '10px' } : { '--padding': '0px' })
    }, [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              ($props.title)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString($props.title), 1))
                : _createCommentVNode("", true),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: $props.imageUrl ? '9' : '12'
                  }, {
                    default: _withCtx(() => [
                      ($props.description)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($props.description), 1))
                        : _createCommentVNode("", true),
                      _createVNode(_component_MaireButton, {
                        text: $props.buttonText,
                        link: $props.link,
                        disabled: !!$props.disabled
                      }, null, 8, ["text", "link", "disabled"])
                    ]),
                    _: 1
                  }, 8, ["cols"]),
                  ($props.imageUrl)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        cols: "3"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            class: "card-image",
                            src: $props.imageUrl
                          }, null, 8, _hoisted_3)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ], 4)
  ], 2))
}