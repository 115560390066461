<template>
  <!-- <p v-if="label" class="label strong text-primary s">{{ label }}</p> -->
  <div
    v-if="pieData?.length > 0"
    ref="pieElement"
    style="height: 100%"
    :class="`chart-paper ${loading ? 'loading' : ''}`"
  >
    <div v-if="rendered" style="height: 100%">
      <apexchart
        :options="pieOptions"
        :series="pieData"
        ref="pieChart"
        height="100%"
        @dataPointSelection="handleClick"
      />
    </div>
  </div>
  <Transition>
    <div v-if="loading" class="loading-animation">
      <WaitAnimation message="Analyzing" />
    </div>
  </Transition>
</template>
<style scoped>
.chart-paper {
  transition: filter 0.5s ease-in-out;
}
.loading {
  filter: blur(4px) brightness(80%);
}
.loading-animation {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.label {
  margin-right: 200px;
}
</style>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { numberFormatter } from "@/components/helpers/helpers";
import { topKElements } from "@/components/helpers/MinHeap";
import WaitAnimation from "../WaitAnimation/WaitAnimation.vue";
import { GrowthStat } from "@/composables/worker";
import { useLabelColor } from "@/composables/useLabelColor";

export default defineComponent({
  emits: ["select", "unselect"],
  props: {
    stats: {
      type: Array as () => GrowthStat[],
      required: true,
    },
    field: {
      type: String,
      required: false,
      default: "potential",
    },
    postFix: {
      type: String,
      required: false,
      default: "€",
    },
    legendLocation: {
      type: String,
      required: false,
      default: "left",
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    rendered: false,
    pieElement: ref(null),
    pieChart: ref(null),
    legendWidth: 600,
  }),
  setup() {
    const { getLabelColor } = useLabelColor();
    return { getLabelColor };
  },
  watch: {
    stats() {
      this.rendered = false;
      const elChart = this.$refs.pieChart as any;
      if (elChart?.chart) {
        elChart.chart.destroy();
      }
      this.rendered = false;
      this.$nextTick(() => {
        this.rendered = true;
      });
    },
  },
  mounted() {
    const el = this.$refs.pieElement as HTMLElement;
    if (el?.clientWidth && this.legendLocation === "left") {
      this.legendWidth = el.clientWidth * 0.55;
    }
    this.$nextTick(() => {
      this.rendered = true;
    });
  },
  computed: {
    sortedStats() {
      return topKElements(this.stats, 100, this.field as keyof GrowthStat);
    },
    pieData() {
      return this.sortedStats.map((label: any) => label?.[this?.field]);
    },
    pieOptions() {
      const postFix = this.postFix;
      const counts = this.sortedStats.map((label: any) => label?.count);
      return {
        chart: {
          type: "donut",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "55%",
            },
            expandOnClick: false,
          },
        },
        legend: {
          show: true,
          width: this.legendLocation === "left" ? this.legendWidth : "100%",
          position: this.legendLocation,
          horizontalAlign: "left",
          formatter: function (seriesName: any, opts: any) {
            let total = opts.w.globals.seriesTotals.reduce((a: any, b: any) => a + b, 0);
            let value = opts.w.globals.series[opts.seriesIndex];
            let percentage = total !== 0 ? (value / total) * 100 : 0;
            const count = counts[opts.seriesIndex];
            const base = `${seriesName}: ${percentage.toFixed(1)}%`;
            if (!count) {
              return base;
            } else {
              return `${base} (${count})`;
            }
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          y: {
            formatter: function (val: number) {
              if (val === 0) return "0";
              return `${numberFormatter(val, 2)}${postFix}`;
            },
          },
        },
        theme: {
          monochrome: {
            enabled: false,
            color: "#8200FF",
          },
        },
        labels: this.sortedStats.map((label: any) => label.label),
        colors: this.sortedStats.map((label: any) => this.getLabelColor(label.label)),
        stroke: { width: 0 },
      };
    },
  },
  methods: {
    handleClick(_event: any, _chartContext: any, config: any) {
      const label = config?.w?.config?.labels?.[config?.dataPointIndex];
      const nothingSelected = config?.w?.globals?.selectedDataPoints?.[0]?.length === 0;
      if (nothingSelected) {
        this.$emit("unselect", label);
      } else {
        this.$emit("select", label);
      }
    },
  },
  components: { WaitAnimation },
});
</script>
