
import { defineComponent } from "vue";

const __default__ = defineComponent({
  name: "RevenueGauge",
  props: {
    width: { required: true, type: Number },
    percentage: { required: true, type: Number },
  },

  computed: {
    style() {
      return "height: " + this.width / 2 + "px; width: " + this.width + "px";
    },
    gaugeDegrees() {
      return `${(100 - this.percentage) * 1.8 * -1}deg`;
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0db75e60": (_ctx.gaugeDegrees)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__