import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f53fdfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "error"
}
const _hoisted_2 = {
  key: 0,
  class: "waiting_container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WaitAnimation = _resolveComponent("WaitAnimation")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.trends)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, " Unfortunately search trends are not available for this keyword. "))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_WaitAnimation, { message: "Maire is predicting the future" })
          ]))
        : _createCommentVNode("", true),
      (!_ctx.loading && _ctx.trends)
        ? (_openBlock(), _createBlock(_component_apexchart, {
            key: 1,
            loading: true,
            width: "100%",
            options: _ctx.options,
            series: _ctx.trends
          }, null, 8, ["options", "series"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}