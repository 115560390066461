import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e93e6ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inline" }
const _hoisted_2 = { class: "inline" }
const _hoisted_3 = { class: "inline result_title_url" }
const _hoisted_4 = { class: "result_title" }
const _hoisted_5 = { class: "website_url_container" }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 0,
  class: "category"
}
const _hoisted_8 = {
  key: 1,
  class: "category extra"
}
const _hoisted_9 = {
  key: 2,
  class: "category video"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, {
    class: _normalizeClass(`${_ctx.category ? _ctx.category.split(' ')[0] : _ctx.classes} result`)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.prefix), 1)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.text), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("a", {
                target: "_blank",
                href: _ctx.description,
                class: "website_url"
              }, _toDisplayString(_ctx.description?.replace("https://", "")), 9, _hoisted_6)
            ])
          ]),
          (_ctx.category && (!_ctx.expandable || _ctx.description?.includes('www.youtube.com')))
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.sentenceCase(_ctx.category)), 1))
            : _createCommentVNode("", true),
          (_ctx.extra)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.sentenceCase(_ctx.extra)), 1))
            : _createCommentVNode("", true),
          (_ctx.description?.includes('www.youtube.com'))
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString("Video")))
            : _createCommentVNode("", true),
          (_ctx.expandable)
            ? (_openBlock(), _createBlock(_component_v_icon, {
                key: 3,
                onClick: _cache[0] || (_cache[0] = () => _ctx.expand('related_searches')),
                class: "expand_drawer",
                icon: `${_ctx.expanded.includes('related_searches') ? 'mdi:mdi-chevron-up' : 'mdi:mdi-chevron-down'}`
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(`${_ctx.expanded.includes('related_searches') ? 'visible' : 'hidden'} animated_drawer`)
          }, [
            _createVNode(_component_v_container, null, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _: 3
            })
          ], 2)
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["class"]))
}