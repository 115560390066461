
import { defineComponent, ref } from "vue";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import LoginForm from "@/components/LoginForm/LoginForm.vue";
import PasswordResetTriggerForm from "@/components/PasswordResetTriggerForm/PasswordResetTriggerForm.vue";
import PasswordResetForm from "@/components/PasswordResetForm/PasswordResetForm.vue";
import { useStore } from "@/store"
import { useRoute, useRouter } from "vue-router"

const __default__ = defineComponent({
  data: () => ({
    publicPath: process.env.BASE_URL,
    imageUrl: ref(`url(${process.env.BASE_URL}images/content_marketing.png)`),
  }),
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  components: { WaitAnimation, LoginForm, PasswordResetForm, PasswordResetTriggerForm },
  computed: {
    isLoading() {
      return this.store.state.loading.loadingKeywords;
    },
    isPasswordResetView() {
      return this.route.params.token && this.route.params.token !== "trigger";
    },
    isPasswordResetTrigger() {
      return this.route.params.token === "trigger";
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "ae21a05e": (_ctx.imageUrl)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__