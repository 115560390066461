<template>
  <MaireTooltip @triggered="triggered">
    <template #trigger>
      <span ref="rowz" class="hover-white">{{ volume }}</span>
    </template>
    <template #content>
      <span v-if="volumesByYear">
        <strong>Volumes until: {{ volumesUntil }}</strong>
        <ul>
          <li :key="volumeData.year" v-for="volumeData in volumesByYear">
            {{ volumeData.year }}: <strong>{{ volumeData.volume }}</strong>
          </li>
        </ul>
      </span>
      <span v-else>
        <WaitAnimation :width="50" message=" " />
      </span>
    </template>
  </MaireTooltip>
</template>

<script lang="ts" setup>
import { computed, defineProps, ref } from "vue";
import { numberFormatter } from "@/components/helpers/helpers";
import MaireTooltip from "../MaireTooltip/MaireTooltip.vue";
import { useStore } from "@/store";
import WaitAnimation from "../WaitAnimation/WaitAnimation.vue";

const store = useStore();
const props = defineProps({
  fieldValue: Number,
  item: Object,
});

const volumes = ref(undefined);
const untilDate = ref("");

const triggered = async () => {
  const kwId = props.item?.id;
  if (kwId && !volumes.value) {
    const result = await store.dispatch("keywords/fetchKwVolumes", kwId);
    if (result) {
      volumes.value = result.volumes;
      untilDate.value = result.until_date;
    }
  }
};
const volume = computed(() => {
  {
    if (props.fieldValue === null) return null;
    return numberFormatter(props.fieldValue || 0, 2);
  }
});
const volumesUntil = computed(() => {
  if (!untilDate.value) {
    return "N/A";
  } else {
    const until = untilDate.value.slice(0, 7);
    return `${until.slice(5, 7)}/${until.slice(0, 4)}`;
  }
});
const volumesByYear = computed(() => {
  if (!volumes.value) return undefined;
  {
    const volumeEntries = Object.entries(volumes.value || {});
    let maxYear = 0;
    return volumeEntries.map(([year, volumes]) => {
      if (typeof Number(year) === "number" && Number(year) > maxYear) {
        maxYear = Number(year);
      }
      // @ts-ignore
      const volumesForYear: number = (volumes.filter(Boolean) as Array<number>).reduce(
        (acc: number, volume: number) => acc + volume,
        0
      );
      return {
        year: year === "proj" ? `${maxYear} Projected` : year,
        volume: numberFormatter(volumesForYear, 2),
      };
    });
  }
});
</script>
