<template>
  <MaireDialog
    :onClose="() => (deletionModalOpen = false)"
    :open="deletionModalOpen"
    :description="`Are you sure you
  want to delete the label &quot;${labelToDelete?.text}&quot;? It will remove it from all keywords and analyses. This cannot be undone!`"
    confirmText="Delete"
    :fn="deleteLabel"
  />
  <div v-if="labelToEdit">
    <MaireDialog
      :title="`Edit ${labelToEdit.text}`"
      :open="editModalOpen"
      description="Edit the name and color of the label"
      :onClose="() => (editModalOpen = false)"
      confirmText="Save"
      :fn="updateLabel"
    >
      <p class="s strong my-2">Label name</p>
      <v-text-field
        hide-details
        density="compact"
        type="string"
        variant="outlined"
        v-model="newName"
        required
      >
      </v-text-field>
      <p class="s strong my-2">Label color</p>
      <v-color-picker elevation="0" mode="hex" v-model="newColor"> </v-color-picker>
    </MaireDialog>
  </div>
  <div class="label-list maire-scrollbar">
    <div class="table-content">
      <div id="create-label-button">
        <MaireButton
          compact
          inverse
          text="Create new label"
          @click="handleNewLabelButtonClick"
          icon="fas fa-plus"
        />
      </div>
      <div>
        <div class="mb-3"></div>
        <div class="search_container">
          <SearchBar @set="searchWordChanged" />
        </div>
      </div>
      <DataTable
        :items="labels"
        :fields="fields"
        hide-actions
        :actions="actions"
        :handleRowClick="handleRowClick"
      />
    </div>
  </div>
</template>
<style scoped>
.search_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 0px;
  width: 400px;
  float: right;
}
#create-label-button {
  width: 230px;
  float: left;
  position: relative;
  margin-bottom: 12px;
  z-index: 2;
}
.label-list {
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  width: 100%;
  padding: 50px 24px;
  border-radius: 0px !important;
}

.table-content {
  max-width: 90%;
  max-height: 100%;
  left: 0px;
  right: 0px;
  margin: auto;
}
</style>
<script lang="ts" setup>
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import { computed, Ref, ref } from "vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import ChipCell from "@/components/DataTable/DataTableCells/ChipCell.vue";
import { Label, LabelRule } from "@/store/modules/labels/types";
import { Analysis } from "@/store/modules/analysis/types";
import { useLabelColor } from "@/composables/useLabelColor";
import { onMounted } from "vue";
import SearchBar from "@/components/DataTable/SearchBar.vue";
import { keywordMatchesSearchWord } from "@/components/helpers/helpers";
const store = useStore();
const { getLabelColor } = useLabelColor();

const fields = [
  {
    field: "text",
    name: "Label",
    width: 11,
    component: () => ChipCell,
    allow_filtering: true,
    filtering_key: "text",
    aggregation: "count_distinct",
  },
  {
    field: "user",
    name: "User",
    width: 11,
    renderer: (value: string) => value,
    aggregation: "count_distinct",
    allow_filtering: true,
  },
  {
    field: "keyword_count",
    name: "Keywords",
    width: 11,
    renderer: (value: string) => value,
  },
  {
    field: "analyses",
    name: "Analyses",
    width: 11,
    allow_filtering: true,
    filtering_key: "name",
    renderer: (value: Array<Analysis>) => (value ? value.map((af) => af.name).join(",") : ""),
  },
  {
    field: "rules",
    name: "Rules",
    width: 1,
    // @ts-ignore
    renderer: (value: Array<LabelRule>) => (value ? (value?.[0].is_active ? "✅" : "☑️") : ""),
  },
];
const router = useRouter();
const handleRowClick = (e: any, item: Label) => router.push(`/labels/${item.id}`);
const handleNewLabelButtonClick = () => router.push("/labels/new");
const users = computed(() => store.state.company.active_users);
const userMap = computed(() =>
  Object.fromEntries(users.value?.map((user) => [user.id, `${user.first_name} ${user.last_name}`]) ?? [])
);
const labels = computed(() =>
  Object.values(store.state.labels.labels)
    .map((label) => ({
      ...label,
      user: label.user_id ? userMap.value[label.user_id] : "",
    }))
    .filter((label) =>
      keywordMatchesSearchWord(
        // @ts-ignore
        { keyword: label.text },
        searchWord.value,
        JSON.stringify({ ...label, id: undefined })
      )
    )
);
const deletionModalOpen = ref(false);
const editModalOpen = ref(false);
const newName = ref("");
const newColor = ref("");
const labelToDelete: Ref<Label | undefined> = ref();
const labelToEdit: Ref<Label | undefined> = ref();
const deleteLabel = async () => {
  deletionModalOpen.value = false;
  if (labelToDelete.value) {
    await store.dispatch("labels/deleteLabels", [labelToDelete.value.text]);
    router.push("/labels");
  }
};
const searchWord = ref("");
const searchWordChanged = (val: string) => {
  searchWord.value = val;
};
onMounted(() => {
  if (!labels.value.length) store.dispatch("labels/fetchLabels");
});
const updateLabel = async () => {
  editModalOpen.value = false;
  await store.dispatch("labels/updateLabel", {
    label: labelToEdit.value,
    color: newColor.value,
    text: newName.value,
  });
  labelToEdit.value = undefined;
  newName.value = "";
  newColor.value = "";
};
const actions = [
  {
    icon: "fa-regular fa-pencil",
    name: "Edit name & color",
    fn: (lbl: Label) => {
      newName.value = lbl.text;
      newColor.value = lbl.color ?? getLabelColor(lbl.text ?? "");
      labelToEdit.value = lbl;
      editModalOpen.value = true;
    },
  },
  {
    icon: "fa-regular fa-trash",
    name: "Delete",
    fn: (lbl: Label) => {
      labelToDelete.value = lbl;
      deletionModalOpen.value = true;
    },
  },
];
</script>
