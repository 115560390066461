<template>
  <div id="maire-tab" class="tabs maire-drop-shadow">
    <v-row class="kek">
      <v-col cols="9">
        <div class="tabs">
          <ul>
            <li
              @click="() => onSelect(tab)"
              :class="`tab ${selectedTab === tab ? 'selected' : ''}`"
              :key="tab"
              v-for="tab in tabs"
            >
              {{ tab }}
              <span class="chip google-only" v-if="selectedTab === tab && extraLabel">{{ extraLabel }}</span>
            </li>
          </ul>
        </div>
      </v-col>
      <v-col cols="3">
        <div v-if="!fullWidthContent" class="content"><slot></slot></div>
      </v-col>
    </v-row>
    <v-row class="fullWidthContent" v-if="fullWidthContent">
      <v-col cols="12">
        <div class="nomargin"></div>
        <slot></slot>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.nomargin {
  margin-left: -24px;
}
/* .fullWidthContent {
  width: 100%;
} */
.content {
  position: relative;
  /* position middle vertically */
  top: 50%;
  transform: translateY(-50%);
  max-height: 80px;
}

#maire-tab {
  margin: 30px 0px 30px 0px;
}
.tabs {
  position: relative;
  /* display: flex; */
  list-style-type: none;
  border-radius: 10px;
  /* margin: 30px 0px 30px 0px; */
  width: 100%;
  padding: 12px;
  margin-bottom: -12px;
  background-color: white;
}
.tab {
  display: inline-block;
  padding: 12px 18px;
  margin-right: 16px;
  color: black;
  border-radius: 10px;
  cursor: pointer;
}
.selected {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.05);
  color: rgb(var(--v-theme-mairePurpleSecondary));
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { PropType } from "vue";

export default defineComponent({
  name: "MaireTab",
  props: {
    tabs: {
      type: Array as PropType<string[]>,
      required: true,
    },
    selectedTab: {
      type: String,
      required: true,
    },
    onSelect: {
      type: Function,
      required: true,
    },
    fullWidthContent: {
      type: Boolean,
      required: false,
      default: false,
    },
    extraLabel: {
      type: String,
      required: false
    }
  },
});
</script>
