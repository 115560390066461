<template>
  <Transition>
    <div class="notificationAlert" v-if="message" :type="type">
      <v-row>
        <v-col cols="1">
          <div class="icon">
            <v-icon @click="() => handleClick()" :style="`float: left;color: ${icon.color};`">
              {{ icon.icon }}
            </v-icon>
          </div>
        </v-col>
        <v-col cols="10"> {{ message }}</v-col>
        <v-col cols="1">
          <div class="icon">
            <v-icon class="icon" @click="() => handleClick()" :style="`float: right;color: ${icon.color}`">
              mdi:mdi-close
            </v-icon>
          </div>
        </v-col>
      </v-row>
    </div>
  </Transition>
</template>

<style scoped>
.icon {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.notificationText {
  color: black !important;
}

.notificationAlert {
  width: 40%;
  padding: 25px 40px;
  right: 100px;
  position: fixed;
  top: 20px;
  z-index: 10000;
  box-shadow: 0px 0px 20px 0px rgba(41, 54, 87, 0.1);
  background-color: white;
  color: #293657;
}

.notificationAlert button {
  color: white !important;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "MaireNotification",
  data() {
    return {
      timer: 0,
    };
  },
  props: {
    type: { required: false, type: String, default: "success" },
    handleClick: { required: true, type: Function },
    message: { required: true, type: String },
  },
  mounted() {
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(this.handleClick, 3000);
  },
  watch: {
    message() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(this.handleClick, 3000);
    },
  },
  computed: {
    icon() {
      if (this.type === "error") {
        return { color: "#FF002E", icon: "mdi:mdi-alert-outline" };
      } else {
        return { color: "#E500FF", icon: "mdi:mdi-alert-circle-outline" };
      }
    },
  },
});
</script>
