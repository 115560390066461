import { LabelStructure, ViewLabel } from "./types";

export const viewLabelsToStructure = (labels: ViewLabel[]): LabelStructure => {
  const selected = {} as { [key: number]: any[] };
  labels?.forEach((label: ViewLabel) => {
    if (!selected[label.level]) {
      selected[label.level] = [];
    }
    selected[label.level].push(label);
  });
  if (!Object.keys(selected).length) {
    selected[1] = [];
  }
  return selected;
};