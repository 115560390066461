<template>
  <p v-if="analysisFilters.length === 0" class="text-paragraph">
    You have not saved any analysis yet. You can save an analysis from the Analyse page.
  </p>
  <MaireDialog
    :onClose="() => (deletionModalOpen = false)"
    v-if="analysisToDelete"
    :open="deletionModalOpen"
    :title="`Are you sure you want to delete analysis: ${analysisToDelete.name}?`"
    confirmText="Delete"
    :fn="() => analysisToDelete?.id && deleteAnalysis(analysisToDelete)"
  />
  <MaireDialog
    :onClose="() => (deletionModalOpen = false)"
    v-if="viewToDelete"
    :open="deletionModalOpen"
    :title="`Are you sure you want to delete view ${viewToDelete.name}?`"
    confirmText="Delete"
    :fn="() => viewToDelete?.id && deleteView(viewToDelete)"
  />
  <span style="position: absolute; right: 48px; top: 24px; z-index: 2; width: 250px">
    <MaireButton
      @click="showOnlyOwnAnalyses = !showOnlyOwnAnalyses"
      :text="showOnlyOwnAnalyses ? 'Show all analyses' : 'Show only my own analyses'"
      compact
    />
  </span>
  <div class="my-analyses maire-scrollbar">
    <div class="table-header-row">
      <div class="table-header">
        <span></span>
        <div class="table-header-cell xs">Modified</div>
        <div class="table-header-cell xs">Name & Description</div>
        <div class="table-header-cell xs">Owner</div>
        <div class="search_container">
          <SearchBar @set="searchWordChanged" />
        </div>
      </div>
    </div>
    <div class="table-content">
      <div :key="analysis.id" v-for="analysis in sortedAnalysisFilters">
        <div class="table-row" @click="showViewsForAnalysis(analysis)">
          <div class="cell" v-if="userCanDeleteAnalysis(analysis)">
            <ThreeDotMenu
              :actions="getActionsForAnalysis(analysis)"
              :isOpen="analysisMenuOpen === analysis.id"
              @menu-opened="analysisMenuOpen = analysis.id"
              @menu-closed="analysisMenuOpen = undefined"
            />
          </div>
          <div v-else></div>
          <p class="cell xs">{{ latestDateForAnalysis(analysis) }}</p>
          <div class="cell">
            <div>
              <p class="s strong">{{ analysis.name }}</p>
              <p class="xs">{{ analysis.description }}</p>
            </div>
          </div>
          <div class="cell">
            <div>
              <p class="s strong">{{ userMap?.[analysis.user_id]?.name }}</p>
              <p class="xs">{{ userMap?.[analysis.user_id]?.email }}</p>
            </div>
          </div>
          <div>
            <MaireIcon
              icon="fas fa-users"
              :tooltip="`Shared by: ${analysisSharedBy(analysis)}`"
              v-if="analysisSharedBy(analysis)"
            />
            <MaireIcon
              icon="fab fa-google"
              tooltip="Includes search volumes from Google only"
              v-if="analysis?.volume_field === 'monthly_google_searches'"
            />
          </div>
          <span class="caret">
            <v-icon v-if="analysis.views?.length > 1">{{
              expanded.includes(analysis.id) ? "far fa-caret-up" : "far fa-caret-down"
            }}</v-icon>
          </span>
        </div>

        <Transition name="slide">
          <div class="views" v-if="expanded.includes(analysis.id)">
            <div
              class="table-row view-row"
              :key="view"
              v-for="view in analysis.views"
              @click="selectView(analysis, view)"
            >
              <div class="cell" v-if="userCanDeleteView(view)">
                <ThreeDotMenu
                  :actions="[{ name: 'Delete', icon: 'far fa-trash', fn: () => triggerViewDeletion(view) }]"
                  :isOpen="viewMenuOpen === view.id"
                  @menu-opened="viewMenuOpen = view.id"
                  @menu-closed="viewMenuOpen = undefined"
                />
              </div>
              <div v-else></div>
              <p class="cell xs">{{ getFormattedDateForView(view) }}</p>
              <div class="cell">
                <div>
                  <p class="s strong">{{ view.name }}</p>
                  <p class="xs">{{ view.description }}</p>
                </div>
              </div>
              <div class="cell">
                <div>
                  <p class="s strong">{{ userMap?.[view.user_id]?.name }}</p>
                  <p class="xs">{{ userMap?.[view.user_id]?.email }}</p>
                </div>
              </div>
              <div>
                <MaireIcon
                  icon="fas fa-users"
                  :tooltip="`Shared by: ${viewSharedBy(view)}`"
                  v-if="viewSharedBy(view)"
                />
                <MaireIcon
                  icon="fab fa-google"
                  tooltip="Includes search volumes from Google only"
                  v-if="analysis?.volume_field === 'monthly_google_searches'"
                />
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>
<style scoped>
.search_container {
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin: auto;
  right: 0px;
  margin-right: 0px;
  width: 300px;
  float: right;
}
.caret {
  color: rgb(var(--v-theme-mairePurpleSecondary));
  display: flex;
  align-items: center;
  justify-content: center;
}
.cell {
  display: flex;
  align-items: center;
}
.header-text {
  padding: 24px 0px 36x;
  max-width: 70%;
  max-height: 100%;
  left: 0px;
  right: 0px;
  margin: auto;
  text-align: left;
}
.slide-leave-active,
.slide-enter-active {
  transition: all 0.1s ease-in-out;
}

.slide-enter-to,
.slide-leave-from {
  max-height: 500px;
}

.slide-enter-from,
.slide-leave-to {
  max-height: 24px;
  opacity: 0;
}
.action {
  margin-right: 5px;
  margin-left: 12px !important;
  right: 0px;
  text-align: right;
  display: flex;
  align-items: center;
  white-space: nowrap; /* Prevent line wrapping */
}
.my-analyses {
  position: relative;

  width: 100%;
  padding: 70px 24px;
  border-radius: 0px !important;
}

.table-content {
  max-width: 90%;
  max-height: 100%;
  left: 0px;
  right: 0px;
  margin: auto;
}

.table-row {
  display: flex;
  width: 100%;
  background-color: white;
  margin: 12px 0px;
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 0.3fr 1fr 5fr 0.3fr 2fr 0.2fr;
  border-radius: 8px;
}
.chip {
  float: right;
}

.table-row:hover {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.2);
}
.table-header {
  width: 100%;
  display: grid;
  grid-template-columns: 0.3fr 1fr 5fr 0.3fr 2fr;
  white-space: nowrap;
  max-width: 90%;
  max-height: 100%;
  left: 0px;
  right: 0px;
  margin: auto;
}

.table-header-cell {
  width: 25%;
  padding: 12px 12px;
}
.views {
  margin: 12px 0px 12px 0px;
}
.view-row {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.1);
  padding-left: 12px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { Analysis, View } from "@/store/modules/analysis/types";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import MaireIcon from "@/components/MaireIcon/MaireIcon.vue";
import ThreeDotMenu from "@/components/ThreeDotMenu/ThreeDotMenu.vue";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import SearchBar from "@/components/DataTable/SearchBar.vue";
import { keywordMatchesSearchWord } from "@/components/helpers/helpers";
export default defineComponent({
  name: "SavedFilters",
  data: () => ({
    deletionModalOpen: false,
    showOnlyOwnAnalyses: false,
    analysisToDelete: undefined as Analysis | undefined,
    viewToDelete: undefined as View | undefined,
    expanded: [] as number[],
    openAnalysisMenu: undefined as Analysis | undefined,
    analysisMenuOpen: undefined as number | undefined,
    viewMenuOpen: undefined as number | undefined,
    searchWord: "",
  }),
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  watch: {},
  mounted() {
    if (!this.analysisFilters?.length) {
      this.store.dispatch("analysis/fetchAnalyses");
    }
  },
  methods: {
    searchWordChanged(val: string) {
      this.searchWord = val;
    },
    getActionsForAnalysis(analysis: Analysis) {
      const actions = [
        {
          name: "Delete",
          icon: "far fa-trash",
          fn: () => this.triggerAnalysisDeletion(analysis),
          inProgress: false,
        },
      ];
      if (["admin", "company_admin"].includes(this.user.type)) {
        actions.push({
          name: "Download volumes",
          icon: "far fa-download",
          fn: () => this.triggerVolumeDownload(analysis),
          inProgress: this.loadingVolumes.includes(analysis.id),
        });
      }
      return actions;
    },
    toggleViewMenu(id: number) {
      this.viewMenuOpen = this.viewMenuOpen === id ? undefined : id;
    },
    toggleAnalysisMenu(id: number) {
      this.analysisMenuOpen = this.analysisMenuOpen === id ? undefined : id;
    },
    formatDate(dt: Date) {
      const now = new Date();

      // If today, show time (12:15)
      if (dt.toDateString() === now.toDateString()) {
        return dt.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
      }

      // If this year, show date (13 Jun)
      if (dt.getFullYear() === now.getFullYear()) {
        return dt.toLocaleDateString([], { day: "numeric", month: "short" });
      }

      // If previous years, show month year (Jul 2022)
      return dt.toLocaleDateString([], { year: "numeric", month: "short" });
    },
    getFormattedDateForView(view: View) {
      const date = new Date(view.time_updated || view.time_created);
      return this.formatDate(date);
    },
    latestDateForAnalysis(analysis: Analysis) {
      // Get all timestamps from the views
      const dates = analysis.views.map((view) => new Date(view.time_updated || view.time_created).getTime());

      // Find the latest date
      const maxDate = new Date(Math.max(...dates));
      return this.formatDate(maxDate);
    },
    triggerAnalysisDeletion(analysis: Analysis) {
      this.viewToDelete = undefined;
      this.analysisToDelete = analysis;
      this.deletionModalOpen = true;
    },
    triggerVolumeDownload(analysis: Analysis) {
      this.store.dispatch("analysis/downloadVolumes", analysis);
    },
    deleteAnalysis(analysis: Analysis) {
      this.deletionModalOpen = false;
      this.store.dispatch("analysis/deleteAnalysis", analysis);
    },
    showViewsForAnalysis(analysis: Analysis) {
      if (analysis.views.length === 1) {
        this.selectView(analysis, analysis.views[0]);
        return;
      }
      if (this.expanded.includes(analysis.id)) {
        this.expanded = this.expanded.filter((id) => id !== analysis.id);
      } else {
        this.expanded.push(analysis.id);
      }
    },
    showActionsForAnalysis(analysis: Analysis) {
      if (this.openAnalysisMenu?.id === analysis?.id) {
        this.openAnalysisMenu = undefined;
      } else {
        this.openAnalysisMenu = analysis;
      }
    },
    selectView(analysis: Analysis, view: View) {
      if (!view) return;
      if (!analysis) return;
      this.router.push(`/analysis/${analysis.id}?view=${view.id}`);
    },
    triggerViewDeletion(view: View) {
      this.analysisToDelete = undefined;
      this.viewToDelete = view;
      this.deletionModalOpen = true;
    },
    deleteView(view: View) {
      this.deletionModalOpen = false;
      this.store.dispatch("analysis/deleteView", view);
    },
    userCanDeleteAnalysis(analysis: Analysis) {
      return this.user?.type === "company_admin" || analysis.user_id === this.user?.id;
    },
    userCanDeleteView(view: View) {
      return this.user?.type === "company_admin" || view.user_id === this.user?.id;
    },
    analysisSharedBy(analysis: Analysis) {
      if (!this.user || analysis.user_id === this.user?.id) {
        return null;
      }
      const user = this.company?.active_users?.find((user) => user.id === analysis.user_id);
      if (!user) {
        return null;
      }
      return `${user.first_name} ${user.last_name}`;
    },
    viewSharedBy(view: View) {
      if (!this.user || view.user_id === this.user?.id) {
        return null;
      }
      const user = this.company?.active_users?.find((user) => user.id === view.user_id);
      if (!user) {
        return null;
      }
      return `${user.first_name} ${user.last_name}`;
    },
  },
  computed: {
    company() {
      return this.store.state.company;
    },
    users() {
      return this.store.state.company.active_users;
    },
    userMap() {
      if (!this.users) return {};
      return Object.fromEntries(
        this.users?.map((user) => [
          user.id,
          { name: `${user.first_name} ${user.last_name}`, email: user.email },
        ]) ?? []
      );
    },
    user() {
      return this.store.state.user;
    },
    views() {
      return this.store.state.analysis.views;
    },
    analysisFilters() {
      const afs = this.store.state.analysis.analyses.map((analysis: Analysis) => {
        return {
          ...analysis,
          views: analysis.views.map((view) => this.views[view.id]),
        };
      });
      return (this.showOnlyOwnAnalyses ? afs.filter((af) => af.user_id === this.user.id) : afs).filter((af) =>
        keywordMatchesSearchWord(
          // @ts-ignore
          { keyword: af.name },
          this.searchWord,
          JSON.stringify({ ...af, id: undefined, user: this.userMap?.[af.user_id] })
        )
      );
    },
    sortedAnalysisFilters(): Analysis[] {
      return [...this.analysisFilters].sort((a: Analysis, b: Analysis) => {
        return a.name.localeCompare(b.name);
      });
    },
    loadingVolumes(): number[] {
      return this.store.state.loading.downloadingAnalysisVolumes;
    },
  },
  components: {
    MaireButton,
    MaireDialog,
    MaireIcon,
    ThreeDotMenu,
    SearchBar,
  },
});
</script>
