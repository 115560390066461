<template>
  <v-container>
    <FeaturegateDialog
      :feature-to-enable="featureToEnable"
      :feature-to-disable="featureToDisable"
      :reset-features="
        () => {
          featureToEnable = undefined;
          featureToDisable = undefined;
        }
      "
      :users="users"
      :companies="companies"
      :fetch-featuregates="fetchFeatureGates"
    />
    <EditUserDialog
      v-if="userToEdit"
      :user="userToEdit"
      :user-types="userTypes"
      :reset-user="() => (userToEdit = undefined)"
    />
    <div class="alerts">
      <v-alert v-if="successMessage" type="success"
        >{{ successMessage }}<v-btn @click="clearSuccess" style="float: right"> close</v-btn>
      </v-alert>
      <v-alert v-if="errorMessage" type="error"
        >{{ errorMessage }}<v-btn @click="clearError" style="float: right"> close </v-btn>
      </v-alert>
    </div>
    <v-row>
      <v-col>
        <v-sheet elevation="5" class="sheet">
          <h2 class="display-2 font-weight-bold mb-3">Usage</h2>
          <a
            target="_blank"
            href="https://lookerstudio.google.com/u/0/reporting/799aa989-610f-4c0b-859c-aa6db8247abc/page/tEnnC"
            >Usage logs on Looker Studio</a
          >
        </v-sheet>
      </v-col>
      <v-col>
        <v-sheet elevation="5" class="sheet">
          <h2 class="display-2 font-weight-bold mb-3">Authentication</h2>
          <v-btn class="plain-btn" :href="getAdminTokenUpdateUrl">Update Google Auth</v-btn>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-sheet elevation="5" class="sheet">
          <h2 class="display-2 font-weight-bold mb-3">Companies & Users</h2>
          <v-table density="compact" style="max-height: 500px; overflow: scroll">
            <thead>
              <tr>
                <th>Company</th>
                <th>Email</th>
                <th>Name</th>
                <th>Analysis count</th>
                <th>User type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="user" v-for="user in users">
                <td>
                  {{ user.company.name }}
                </td>
                <td>
                  {{ user.email }}
                </td>
                <td>{{ user.first_name }} {{ user.last_name }}</td>
                <td>
                  {{ user.analysis_filter_count }}
                </td>
                <td>
                  {{ user.type }}
                </td>
                <td>
                  <v-btn
                    style="height: 20px !important; margin-top: 5px; margin-bottom: 5px"
                    class="plain-btn"
                    @click="loginAs(user.id)"
                    >Login as</v-btn
                  >
                  <v-btn
                    style="height: 20px !important; margin-top: 5px; margin-bottom: 5px"
                    class="plain-btn"
                    @click="() => (userToEdit = user)"
                    ><v-icon>mdi:mdi-pencil-outline</v-icon>Edit</v-btn
                  >
                  <v-btn
                    style="height: 20px !important; margin-top: 5px; margin-bottom: 5px"
                    class="plain-btn"
                    @click="toggleUserActivity(user)"
                    >{{ user.is_active ? "Deactivate" : "Activate" }}</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12" class="mb-4"> </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12" class="mb-4"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="mb-4">
        <v-sheet elevation="5" class="sheet">
          <h2 class="display-2 font-weight-bold mb-3">Create company</h2>
          <v-form class="form">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :rules="[rules.required]"
                  ref="name"
                  variant="outlined"
                  v-model="name"
                  label="Company Name"
                  cols="4"
                  class="mb-4"
                  density="compact"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :rules="[rules.required]"
                  ref="domain"
                  variant="outlined"
                  v-model="domain"
                  label="Company Domain"
                  cols="4"
                  class="mb-4"
                  density="compact"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-select
                  ref="location"
                  :items="countries"
                  variant="outlined"
                  v-model="location"
                  label="Location"
                  cols="4"
                  class="mb-4"
                  density="compact"
                >
                </v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  ref="language"
                  :items="languages"
                  variant="outlined"
                  v-model="language"
                  label="Main language"
                  cols="4"
                  class="mb-4"
                  density="compact"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :rules="[rules.required]"
                  ref="customerAveragePurchase"
                  variant="outlined"
                  v-model="customerAveragePurchase"
                  type="number"
                  label="Customer Average Purchase"
                  cols="4"
                  class="mb-4"
                  density="compact"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  ref="descriptiveWords"
                  multiple=""
                  variant="outlined"
                  v-model="descriptiveWords"
                  chips
                  label="Descriptive words"
                  cols="4"
                  class="mb-4"
                  density="compact"
                >
                </v-combobox>
              </v-col>
              <v-row>
                <v-col cols="6">
                  <v-btn
                    class="plain-btn"
                    :disabled="!this.companyFormFilled"
                    variant="outlined"
                    label="Company id"
                    cols="4"
                    @click="createCompany"
                    >Create company
                  </v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-sheet>
      </v-col>

      <v-col cols="6" class="mb-4">
        <v-sheet elevation="5" class="sheet">
          <h2 class="display-2 font-weight-bold mb-3">Create user</h2>
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="companies"
                  item-title="name"
                  item-value="id"
                  ref="companyId"
                  variant="outlined"
                  :rules="[rules.company]"
                  v-model="companyId"
                  label="Company"
                  cols="4"
                  class="mb-4"
                  density="compact"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  ref="firstName"
                  variant="outlined"
                  :rules="[rules.required]"
                  v-model="firstName"
                  label="First name"
                  cols="4"
                  class="mb-4"
                  density="compact"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  ref="lastName"
                  variant="outlined"
                  :rules="[rules.required]"
                  v-model="lastName"
                  label="Last name"
                  cols="4"
                  class="mb-4"
                  density="compact"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  ref="email"
                  variant="outlined"
                  :rules="[rules.required, rules.email]"
                  v-model="email"
                  label="Email"
                  cols="4"
                  class="mb-4"
                  density="compact"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  ref="password"
                  variant="outlined"
                  :rules="[rules.required, rules.password]"
                  v-model="password"
                  type="password"
                  label="Password"
                  cols="4"
                  class="mb-4"
                  density="compact"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="userTypes"
                  item-title="name"
                  item-value="id"
                  ref="userType"
                  variant="outlined"
                  v-model="userType"
                  label="User type"
                  cols="4"
                  class="mb-4"
                  density="compact"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  class="plain-btn"
                  :disabled="!this.userFormFilled"
                  variant="outlined"
                  label="Company id"
                  cols="4"
                  @click="createUser"
                  >Create user</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="5" class="sheet">
          <h2 class="inline">Feature Gates</h2>
          <v-table v-if="featureGates.length > 0" density="compact">
            <thead>
              <tr>
                <th class="text-left">Feature</th>
                <th class="text-left">Type</th>
                <th class="text-left">Actions</th>
                <th class="text-left">Enabled for</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="featureGate in featureGates" :key="featureGate.name">
                <td>{{ featureGate.name }}</td>
                <td>{{ featureGate.actor_type }}</td>
                <td>
                  <v-btn
                    class="plain-btn"
                    @click="() => (featureToEnable = featureGate)"
                    density="compact"
                    style="height: 20px !important; margin-top: 5px; margin-bottom: 5px"
                  >
                    Enable
                  </v-btn>
                  <v-btn
                    class="plain-btn"
                    @click="() => (featureToDisable = featureGate)"
                    density="compact"
                    style="height: 20px !important; margin-top: 5px; margin-bottom: 5px"
                  >
                    Disable
                  </v-btn>
                </td>
                <td>{{ featureGate.enabled_actors.map((a) => a.email || a.name || a).join(", ") }}</td>
              </tr>
            </tbody>
          </v-table>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="5" class="sheet">
          <h2 class="inline">Duplicate keywords</h2>
          <v-btn class="plain-btn" @click="fetchKeywordsWithDuplicates" variant="outlined" density="compact">
            Fetch keywords with duplicates
          </v-btn>
          <v-table v-if="keywordsWithDuplicates.length > 0" density="compact">
            <thead>
              <tr>
                <th class="text-left">Keyword</th>
                <th class="text-left">Location</th>
                <th class="text-left">Language</th>
                <th class="text-left">Duplicates</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="keyword in keywordsWithDuplicates" :key="keyword.id">
                <td>{{ keyword.text }}</td>
                <td>{{ keyword.location_name }}</td>
                <td>{{ keyword.language_code }}</td>
                <td>
                  <a
                    v-for="(duplicate, idx) in keyword.child_variants"
                    :key="duplicate.id"
                    @click.prevent="() => changeKeywordParent(keyword, duplicate)"
                    href="#"
                  >
                    <span v-if="idx > 0">, </span>{{ duplicate.text }}
                  </a>
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
td,
th {
  font-size: 10px !important;
}

.inline {
  display: inline;
}

.sheet {
  color: black;
  padding: 15px;
  margin-top: 30px;
}

.table_container {
  height: 400px;
  overflow: scroll;
}

.table_container_small {
  height: 200px;
  overflow: scroll;
}

.table_container {
  height: 400px;
  overflow: scroll;
}

.alerts {
  position: fixed;
  top: 10px;
  margin: auto;
  right: 5%;
  left: 5%;
  width: 50%;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import { BACKEND_PATH } from "../../helpers";
import * as api from "@/api";
import { SUPPORTED_COUNTRIES, SUPPORTED_LANGUAGES } from "../helpers/geo_constants";
import FeaturegateDialog from "./FeaturegateDialog.vue";
import EditUserDialog from "./EditUserDialog.vue";
import { useStore } from "@/store";
import { Keyword } from "@/store/modules/keywords/types";

export default defineComponent({
  name: "AuthenticationBlock",
  components: {
    FeaturegateDialog,
    EditUserDialog,
  },
  data: () => ({
    successMessage: "",
    errorMessage: "",
    firstName: "",
    lastName: "",
    email: "",
    companyId: "",
    password: "",
    name: "",
    domain: "",
    location: "",
    language: "",
    userType: "alpha",
    model_to_update: "keyword_intent",
    finetuneFiles: [],
    finetunes: [],
    models: [],
    keywordsWithDuplicates: [],
    featureGates: [],
    selectedFile: "",
    selectedModel: "",
    selectedUser: undefined,
    customerAveragePurchase: undefined,
    descriptiveWords: "",
    rules: {
      required: (value: any) => !!value || "Required.",
      counter: (value: any) => value.length <= 20 || "Max 20 characters",
      password: (value: any) => value.length > 14 || "Must be at least 15 characters long",
      email: (value: any) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      domain: (value: any) => !!value.includes("."),
    },
    userFormHasErrors: false,
    companyFormHasErrors: false,
    fileUrl: "",
    featureToEnable: undefined,
    featureToDisable: undefined,
    userToEdit: undefined,
    userTypes: [
      { id: "alpha", name: "Normal user" },
      { id: "viewer", name: "Viewer" },
      { id: "company_admin", name: "Company admin" },
    ],
  }),
  setup() {
    const store = useStore();
    return { store };
  },
  created() {
    this.store.dispatch("fetchAllCompanies");
    this.fetchFeatureGates();
  },
  computed: {
    companies() {
      return this.store.state.admin.companies;
    },
    users() {
      if (this.companies) {
        return this.companies.flatMap((c) => c.users?.map((u) => ({ ...u, company: c })));
      } else {
        return [];
      }
    },
    getAdminTokenUpdateUrl() {
      return `${BACKEND_PATH}/admin/update_token`;
    },
    userFormFilled() {
      return this.firstName && this.lastName && this.email && this.companyId && this.password;
    },
    companyFormFilled() {
      return (
        this.name &&
        this.domain &&
        this.location &&
        this.language &&
        this.customerAveragePurchase &&
        this.descriptiveWords
      );
    },
    userForm() {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        companyId: this.companyId,
        password: this.password,
      };
    },
    companyForm() {
      return {
        name: this.name,
        domain: this.domain,
        location: this.location,
        language: this.language,
        customerAveragePurchase: this.customerAveragePurchase,
        descriptiveWords: this.descriptiveWords,
      };
    },
    countries() {
      return SUPPORTED_COUNTRIES;
    },
    languages() {
      return SUPPORTED_LANGUAGES;
    },
  },
  mounted() {
    this.store.dispatch("fetchUser");
  },
  methods: {
    loginAs(userId: number) {
      if (userId) {
        this.store.dispatch("impersonate", userId);
      }
    },
    toggleUserActivity(userId: number) {
      this.store.dispatch("toggleUserActivity", userId);
    },
    fetchKeywordsWithDuplicates() {
      api
        .fetchKeywordsWithDuplicates()
        .then((r) => {
          this.keywordsWithDuplicates = r.data;
        })
        .catch((e) => (this.errorMessage = e));
    },
    changeKeywordParent(currentKw: Keyword, newKw: Keyword) {
      if (confirm(`Change parent from ${currentKw.text} to ${newKw.text}?`)) {
        api
          .changeKeywordParent(currentKw.id, newKw.id)
          .then(() => {
            this.successMessage = "Parent changed";
            this.fetchKeywordsWithDuplicates();
          })
          .catch((e) => (this.errorMessage = e));
      }
    },
    fetchFeatureGates() {
      api
        .fetchFeatureGates()
        .then((r) => {
          this.featureGates = r.data;
        })
        .catch((e) => (this.errorMessage = e));
    },
    clearError() {
      this.errorMessage = "";
    },
    clearSuccess() {
      this.successMessage = "";
    },
    createUser() {
      const userToCreate = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        companyId: this.companyId,
        password: this.password,
        type: this.userType,
      };
      api
        .createUser(userToCreate)
        .then(() => {
          this.successMessage = "User created successfully";
        })
        .catch((e) => {
          this.errorMessage = `User creation failed: ${e}`;
        });
    },
    createCompany() {
      const companyToCreate = {
        name: this.name,
        domain: this.domain,
        location: this.location,
        language: this.language,
        customerAveragePurchase: this.customerAveragePurchase,
        // @ts-ignore
        descriptiveWords: this.descriptiveWords.join(","),
      };
      api
        // @ts-ignore
        .createCompany(companyToCreate)
        .then(() => {
          this.successMessage = "Company created successfully";
        })
        .catch((e) => {
          this.errorMessage = `Company creation failed: ${e}`;
        });
    },
  },
});
</script>
