export const BACKEND_PATH = "/api";

export const userResponseToUser = (data: any) => {
  return {
    id: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
    avatar: "https://randomuser.me/api/portraits/men/62.jpg",
    impersonator: data.impersonator,
    type: data.type,
    isActive: data.is_active,
    favorited_keywords: data.favorited_keywords,
    hash: data.hash,
    google_auth: data.google_auths.length > 0 ? data.google_auths[0] : null,
    categories: data.categories,
    consent: data.consent,
  };
};

type Obj = {
  [string: string]: string;
};
export const csvToArray = (str: string, delimiter = ",") => {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows.map(function (row) {
    const values = row.split(delimiter);
    const el = headers.reduce(function (object: Obj, header: string, index: number) {
      object[header.trim().replace(" ", "_")] = values[index];
      return object;
    }, {});
    return el;
  });

  // return the array
  return arr;
};

export const POTENTIAL_REACHED_BY_POSITION = {
  1: 0.276 / 0.276,
  2: 0.158 / 0.276,
  3: 0.11 / 0.276,
  4: 0.084 / 0.276,
  5: 0.063 / 0.276,
  6: 0.049 / 0.276,
  7: 0.039 / 0.276,
  8: 0.033 / 0.276,
  9: 0.027 / 0.276,
  10: 0.024 / 0.276,
  11: 0.003 / 0.276,
  12: 0.002 / 0.276,
  13: 0.001 / 0.276,
  14: 0.0,
  15: 0.0,
  16: 0.0,
  17: 0.0,
  18: 0.0,
  19: 0.0,
  20: 0.0,
};
