<template>
  <MaireCard title="Organic traffic" style="text-align: left">
    <apexchart v-if="gsc_performance_data" :options="gsc_options" :series="gsc_series" height="500" />
    <div v-else-if="gsc_performance_data === null" style="padding: 50px 20px">
      <h4 style="margin-bottom: 20px">❗ No data ❗</h4>
      <p>
        Check that you have connected your Google Search Console account in the settings and selected a site
        to be used.
      </p>
    </div>
    <WaitAnimation v-else />
  </MaireCard>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// import { ChartSeries } from "@/types";
import MaireCard from "@/components/MaireCard/MaireCard.vue";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import { numberFormatter } from "@/components/helpers/helpers";
import { GSCChartData } from "@/store/modules/gsc/types";
import { useStore } from "@/store"

export default defineComponent({
  name: "OrganicTrafficWidget",
  data: () => ({}),
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    gsc_performance_data() {
      return this.store.state.gsc.gsc_chart_data;
    },
    maxClicks() {
      return Math.max(...(this.gsc_performance_data?.map((row: GSCChartData) => row.clicks) || []));
    },
    gsc_options() {
      return {
        chart: {
          type: "line",
        },
        colors: ["#14ccc9", "#8200ff", "#14ccc9"],
        legend: {
          horizontalAlign: "center",
          show: true,
        },
        stroke: {
          show: true,
          curve: "straight",
          width: [0, 5],
          lineCap: "butt",
          colors: ["#14ccc9", "#8200ff"],
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            borderRadiusApplication: "around",
            borderRadiusWhenStacked: "last",
          },
        },
        grid: {
          show: false,
        },
        labels: this.gsc_performance_data?.map((row) => row.date),
        xaxis: {
          type: "datetime",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          title: {
            style: {
              color: "#14ccc933",
            },
          },
          labels: {
            rotate: -0,
            rotateAlways: true,
            format: "dd MMM",
          },
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0, 1],
          offsetY: -20,
          style: {
            fontSize: "12px",
            fontFamily: "Nunito Sans",
            fontWeight: "bold",
            colors: ["#000000", "#8200ff"],
          },
          background: {
            enabled: false,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45,
            },
          },
          formatter: function (val: number) {
            return numberFormatter(val, 1);
          },
        },
        yaxis: [
          {
            show: false,
            title: {
              show: false,
            },
            labels: {
              show: false,
            },
          },
          {
            show: false,
            title: {
              show: false,
            },
            labels: {
              show: false,
            },
            max: this.maxClicks * 3,
            min: 0,
          },
        ],
      };
    },
    gsc_series() {
      if (!this.gsc_performance_data) {
        return [
          {
            name: "Impressions",
            data: [],
          },
          {
            name: "Clicks",
            data: [],
          },
        ];
      }
      const series = [] as any[];
      series.push({
        type: "column",
        name: "Impressions",
        data: this.gsc_performance_data.map((row: GSCChartData) => row.impressions),
      });
      series.push({
        type: "line",
        name: "Clicks",
        data: this.gsc_performance_data.map((row: GSCChartData) => row.clicks),
      });
      //   Object.entries(this.gsc_performance_data).forEach(([key, value]) => {
      //     series.push({
      //       name: key,
      //       data: value?.["daily_performance"]
      //         .map((row) => ({
      //           x: row.date,
      //           y: row.impressions,
      //         }))
      //         .sort(
      //           (a: ChartDataPoint, b: ChartDataPoint) =>
      //             a.x.valueOf() - b.x.valueOf()
      //         ),
      //     });
      //   });
      //   // Ensure that all series have all the same x values populated, if not, assign null
      //   const all_dates = Array.from(
      //     new Set(
      //       series.map((s) => s.data.map((d: ChartDataPoint) => d.x)).flat()
      //     )
      //   );
      //   for (const s of series) {
      //     const dates_in_series = s.data.map((d: ChartDataPoint) => d.x);
      //     const missing_dates = all_dates.filter(
      //       (d) => !dates_in_series.includes(d)
      //     );
      //     for (const d of missing_dates) {
      //       s.data.push({ x: d, y: null });
      //     }
      //     s.data.sort(
      //       (a: ChartDataPoint, b: ChartDataPoint) =>
      //         new Date(a.x).valueOf() - new Date(b.x).valueOf()
      //     );
      //   }
      return series;
    },
  },
  components: { MaireCard, WaitAnimation },
});
</script>
