<template>
  <div class="label_cell inputs">
    <div class="category-input-field inline">
      <v-chip
        :style="{ 'background-color': getLabelColor(fieldValue), color: 'white' }"
        v-if="fieldValue"
        class="label"
      >
        <span class="text-truncate label-content kw-label">
          {{ fieldValue }}
          <v-tooltip top activator="parent" location="top">{{ fieldValue }}</v-tooltip>
        </span>
      </v-chip>
    </div>
  </div>
</template>
<style scoped>
.label span {
  max-width: 150px;
}
.label {
  cursor: pointer;
}
.label {
  margin: 2px;
}

p {
  color: white;
  font-size: 12px;
}
</style>

<script lang="ts" setup>
import { defineProps } from "vue";
import { useLabelColor } from "@/composables/useLabelColor";
const { getLabelColor } = useLabelColor();

defineProps({
  fieldValue: String,
  field: Object,
  item: Object,
});
</script>
