<template>
  <div class="label_cell inputs">
    <v-icon class="preventClick plusIcon centeredIcon" v-if="!creatingNew" @click="creatingNew = true">
      fas fa-plus
    </v-icon>
    <div
      v-for="label in parsedLabels.slice(0, noOfVisible)"
      :key="label.text"
      class="category-input-field inline"
    >
      <LabelCellLabel
        :label="label"
        @clickClose="(lbl: ViewLabel) => openModal(lbl)"
        @clickLabel="(lbl, e) => searchLabel(lbl.text ?? lbl, e)"
      />
    </div>
    <div v-if="parsedLabels.length > noOfVisible" style="display: inline-block; margin-left: 12px">
      <MaireTooltip :follow="false">
        <template #trigger>
          <span class="xs"> + {{ parsedLabels.length - noOfVisible }} </span>
        </template>
        <template #content>
          <div
            v-for="label in parsedLabels.slice(noOfVisible, parsedLabels.length)"
            :key="label.text"
            class="category-input-field inline"
          >
            <LabelCellLabel
              :label="label"
              @clickClose="(lbl: ViewLabel) => openModal(lbl)"
              @clickLabel="(lbl, e) => searchLabel(lbl.text, e)"
            />
          </div>
        </template>
      </MaireTooltip>
    </div>
    <v-autocomplete
      v-if="creatingNew"
      variant="outlined"
      v-model="newLabel"
      :items="analysisLabels"
      item-value="text"
      item-title="text"
      autofocus
      class="category-input-field preventClick labelInput"
      density="compact"
      :hide-details="true"
      @update:modelValue="saveLabel"
      @keydown.enter="(e: any) => saveLabel(e.target?.value)"
    />
  </div>
  <MaireDialog
    :fn="removeLabelFromKeyword"
    :title="`Remove ${labelToDelete?.text}`"
    :description="`Do you want to remove this label from selected keywords, or just remove it from the keyword: ${item?.keyword}? Note that removing a label from a keyword applies across all analyses where it's used.`"
    :open="modalOpen"
    :onClose="closeModal"
    hidebuttons
  >
    <v-btn @click="modalOpen = false" class="plain-btn inline"> Cancel </v-btn>
    <v-btn v-if="selectedItems?.length" @click="removeLabelFromSelected" class="plain-btn inline danger"> Remove from selected</v-btn>
    <v-btn @click="removeLabelFromKeyword" class="plain-btn inline inverse"> Remove from this keyword</v-btn>
  </MaireDialog>
</template>
<style scoped>
.plusIcon {
  color: rgba(var(--v-theme-mairePurple)) !important;
  border-radius: 25px;
  margin: 5px;
  padding: 0px;
  text-align: center;
  outline: 2px solid rgba(var(--v-theme-mairePurple));
}
.labelInput {
  margin-top: 10px;
}

.label span {
  max-width: 50px;
}
.label {
  cursor: pointer;
  position: relative;
  margin: 2px;
}
.danger {
  background-color: red !important;
  color: white !important;
  border-style: none;
}
.inverse {
  background-color: rgba(var(--v-theme-mairePurpleSecondary)) !important;
}

.danger:hover {
  border-style: none;
  color: white !important;
}

p {
  color: white;
  font-size: 12px;
}
</style>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import { ViewLabel } from "@/store/modules/labels/types";
import { Keyword } from "@/store/modules/keywords/types";
import { useStore } from "@/store";
import { useLabelColor } from "@/composables/useLabelColor";
import MaireTooltip from "@/components/MaireTooltip/MaireTooltip.vue";
import LabelCellLabel from "./LabelCellLabel.vue";

export default defineComponent({
  name: "LabelCell",
  data: () => ({
    modalOpen: false,
    noOfVisible: 3,
    tooltipVisible: undefined as number | undefined,
    labelToDelete: undefined as ViewLabel | undefined,
    creatingNew: false,
    newLabel: "",
  }),
  setup() {
    const store = useStore();
    const { getLabelColor } = useLabelColor();
    return { store, getLabelColor };
  },
  props: {
    fieldValue: Array as PropType<ViewLabel[]>,
    field: Object,
    item: Object,
    viewId: { type: Number, required: false },
    selectedItems: Array as PropType<Keyword[]>,
  },

  computed: {
    currentAnalysis() {
      return this.store.state.analysis.currentAnalysis;
    },
    labels() {
      return this.store.state.labels.labelsById;
    },
    parsedLabels() {
      const lbls = this.fieldValue?.map((lbl: ViewLabel | number) => {
        if (typeof lbl === "number") {
          return this.labels?.[lbl as unknown as number];
        } else {
          return lbl;
        }
      });
      // @ts-ignore
      return lbls?.filter((lbl) => lbl?.text) ?? [];
    },
    analysisLabels() {
      if (!this.currentAnalysis?.id) {
        return Object.values(this.store.state.labels.labels) ?? [];
      }
      return this.store.state.labels.analysisLabels?.[this.currentAnalysis?.id] ?? [];
    },
  },
  methods: {
    saveLabel(label?: string) {
      this.creatingNew = false;
      const labelToCreate = label || this.newLabel;
      this.newLabel = "";
      if (!labelToCreate || !this.item?.id) {
        return;
      }
      this.store.dispatch("keywords/addLabelToKeywords", {
        label: labelToCreate,
        kwIds: [this.item?.id],
        filter: this.currentAnalysis,
        viewId: this.viewId,
      });
    },
    closeModal() {
      this.modalOpen = false;
    },
    openModal(label: ViewLabel) {
      this.modalOpen = true;
      this.labelToDelete = label;
    },
    removeLabelFromKeyword() {
      this.closeModal();
      this.store.dispatch("keywords/removeLabelFromKeywords", {
        labels: [this.labelToDelete],
        kwIds: [this.item?.id],
      });
    },
    removeLabelFromSelected() {
      this.closeModal();
      if (!this.selectedItems?.length || !this.labelToDelete) return;

      this.store.dispatch("keywords/removeLabelFromKeywords", {
        labels: [this.labelToDelete],
        kwIds: this.selectedItems.map((kw) => kw.id),
      });
    },
    searchLabel(label: string, e: any) {
      if (e.target?.className?.includes("fa-times-circle")) {
        return;
      }
      this.store.dispatch("analysis/addLabelFilter", { label: label });
    },
  },
  components: { MaireDialog, MaireTooltip, LabelCellLabel },
});
</script>
