import { ActionTree, Module, MutationTree } from "vuex";
import { State as RootState } from "@/store/index";
import { GSCChartData, GSCCountryStats, GSCPerformanceData, GSCSite, GSCState } from "./types";
import * as api from "@/api";

const state = {
  gsc_sites: [],
  gsc_countries: [],
  gsc_performance_data: undefined,
  gsc_chart_data: undefined,
};

// Mutations
const mutations: MutationTree<GSCState> = {
  setGSCPerformanceData: (state, data: GSCPerformanceData) => {
    state.gsc_performance_data = data;
  },
  setGSCChartData: (state, data: GSCChartData[]) => {
    state.gsc_chart_data = data;
  },
  setGSCSites: (state, data: GSCSite[]) => {
    state.gsc_sites = data;
  },
  setGSCCountries: (state, data: GSCCountryStats[]) => {
    state.gsc_countries = data;
  },
};

// Actions
const actions: ActionTree<GSCState, RootState> = {
  async fetch_gsc_countries({ commit }) {
    const r = await api.gsc_countries();
    commit("setGSCCountries", r.data);
  },
  async fetch_gsc_chart_data({ commit, dispatch }, country: string) {
    try {
      if (this.state.user?.google_auth || state.gsc_sites?.length > 0) {
        commit("setGSCChartData", undefined);
        const r = await api.gsc_chart_data(country);
        commit("setGSCChartData", r.data);
      } else {
        commit("setGSCChartData", null);
      }
    } catch (e: any) {
      const msg = e?.response?.data || "Error fetching GSC data.";
      commit("setGSCChartData", null);
      dispatch("sendErrorMessage", msg);
    }
  },
  async clear_gsc_performance_data({ commit }) {
    commit("setGSCPerformanceData", undefined);
  },
  async fetch_gsc_sites({ commit }) {
    try {
      const r = await api.gsc_sites();
      commit("setGSCSites", r.data);
    } catch (e: any) {
      const msg = e?.response?.data || "Error fetching GSC data.";
      console.log(e);
    }
  },
  async select_gsc_site({ commit, dispatch }, site_url: string) {
    const r = await api.select_gsc_site(site_url);
    dispatch("sendSuccessMessage", "Successfully selected GSC site.", {
      root: true,
    });
    commit("setGSCSites", r.data);
  },
};

const gscModule: Module<GSCState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default gscModule;
