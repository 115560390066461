import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MaireSelect = _resolveComponent("MaireSelect")!

  return (_ctx.selectProps)
    ? (_openBlock(), _createBlock(_component_MaireSelect, _mergeProps({
        key: 0,
        onSave: _ctx.afterSave
      }, _ctx.selectProps), null, 16, ["onSave"]))
    : _createCommentVNode("", true)
}