<template>
  <div id="trigger_password_reset" class="login_modal">
    <div class="logo"><img class="maire_logo" :src="`${publicPath}images/maire_logo.png`" /></div>
    <h2>Lost your password?</h2>
    <p>Give us your email and we will send you a link to reset your password.</p>
    <v-text-field
      v-model="email"
      :error="!!emailError"
      :error-messages="emailError"
      class="maire-teal"
      label="Email"
      density="comfortable"
      variant="outlined"
    />
    <MaireButton :is-loading="emailBeingSent" :on-click="validateAndSubmit" class="btn" text="Send link" />
  </div>
</template>

<style scoped>
.remove-margin {
  margin-left: -295px !important;
}

.btn {
  width: 100%;
}

.image {
  background-image: v-bind(imageUrl);
  background-size: cover;
  position: fixed;
  right: 0px;
  height: 102vh;
  max-width: 1000px;
  z-index: -1000 !important;
}

.login_modal {
  width: 350px;
  background: white;
  margin: auto;
  margin-top: 20%;
  padding: 40px;
  box-shadow: 0px 0px 20px rgba(130, 0, 255, 0.2);
}

.login_modal h2 {
  font-size: 28px;
  padding-left: 0px;
}

.login_modal p {
  padding: 0px 0px 20px 0px;
}

.login_modal .maire_logo {
  width: 40%;
  padding: 10px 20px;
}

.logo {
  text-align: center;
}

.nomargin {
  margin-left: -4px !important;
  margin-bottom: -10px !important;
}
</style>
<script lang="ts">
import { defineComponent, ref } from "vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import { useStore } from "@/store"

export default defineComponent({
  el: "#trigger_password_reset",
  name: "PasswordResetTriggerForm",
  data: () => ({
    publicPath: process.env.BASE_URL,
    stayLoggedIn: false,
    email: "",
    emailError: "",
    imageUrl: ref(`url(${process.env.BASE_URL}images/content_marketing.png)`),
  }),
  setup() {
    const store = useStore();
    return { store };
  },
  mounted() {
    this.$el.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.code === "Enter") {
        this.validateAndSubmit();
      }
    });
  },
  unmounted() {
    this.$el.removeEventListener("keydown", KeyboardEvent);
  },
  methods: {
    validateAndSubmit() {
      const emailRegex = /.*@[a-zA-Z0-9-]*\.[a-zA-Z]*/g;
      let hasErrors = false;
      if (!this.email) {
        this.emailError = "Please fill out your email";
        hasErrors = true;
      } else if (!this.email.match(emailRegex)) {
        this.emailError = "Not a valid email";
        hasErrors = true;
      } else {
        this.emailError = "";
      }
      if (!hasErrors) {
        this.store.dispatch("reset_password", { email: this.email });
      }
    },
  },
  components: { MaireButton },
  computed: {
    emailBeingSent() {
      return this.store.state.loading.emailBeingSent;
    },
  },
});
</script>
