<template>
  <div :class="`card maire-drop-shadow paper-background ${inverse ? 'inverse' : ''}`">
    <div class="card-content">
      <div class="card-title">{{ title }}</div>
      <slot></slot>
    </div>
  </div>
</template>
<style scoped>
.card {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  padding: 12px 24px;
  color: black;
  background-color: white;
  border-radius: 10px;
  width: 100%;
}

.card-title {
  color: #8200ff;
  font-size: 16px;
  font-weight: 800;
  font-family: "Nunito Sans", extrabold;
  text-align: left;
}

.inverse {
  background-color: #293657 !important;
  font-family: "Nunito Sans", extrabold;
  width: 100%;
  color: white;
}

.inverse .card-title {
  color: #4fe0b5;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: -20px;
  text-align: center;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MaireCard",
  props: {
    inverse: { type: Boolean, default: false },
    title: { type: String, default: "" },
  },
});
</script>
