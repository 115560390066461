import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WaitAnimation = _resolveComponent("WaitAnimation")!
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_PasswordResetTriggerForm = _resolveComponent("PasswordResetTriggerForm")!
  const _component_PasswordResetForm = _resolveComponent("PasswordResetForm")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_WaitAnimation, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_v_row, {
      class: "remove-margin",
      cols: "12"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "5" }, {
          default: _withCtx(() => [
            (!_ctx.isPasswordResetView && !_ctx.isPasswordResetTrigger)
              ? (_openBlock(), _createBlock(_component_LoginForm, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.isPasswordResetTrigger)
              ? (_openBlock(), _createBlock(_component_PasswordResetTriggerForm, { key: 1 }))
              : _createCommentVNode("", true),
            (_ctx.isPasswordResetView)
              ? (_openBlock(), _createBlock(_component_PasswordResetForm, { key: 2 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_v_col, {
          class: "image",
          cols: "7"
        })
      ]),
      _: 1
    })
  ], 64))
}