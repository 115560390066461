import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`table-container ${_ctx.loading ? 'loading' : ''}`)
  }, [
    _createVNode(_component_DataTable, {
      items: _ctx.keywords,
      totals: _ctx.totals,
      viewId: _ctx.viewId,
      fields: _ctx.fields,
      handleRowClick: _ctx.handleRowClick,
      isLoading: _ctx.isLoading,
      hideFields: _ctx.hideFields,
      externalTotals: _ctx.externalTotals
    }, null, 8, ["items", "totals", "viewId", "fields", "handleRowClick", "isLoading", "hideFields", "externalTotals"])
  ], 2))
}