import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "action-icon",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createVNode(_component_v_icon, {
      class: "icon",
      "aria-label": "My Account",
      role: "img",
      "aria-hidden": "false"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.icon), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_v_icon, { class: "caret" }, {
      default: _withCtx(() => [
        _createTextVNode("fa-regular " + _toDisplayString(_ctx.toggled ? "fa-angle-up" : "fa-angle-down"), 1)
      ]),
      _: 1
    })
  ]))
}