<template>
  <v-progress-linear
    height="25"
    rounded-bar
    color="#14CCC9"
    :model-value="fieldValue > 1 ? 1 : fieldValue"
    max="1"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProgressCell",
  props: {
    fieldValue: Number,
    field: Object,
  },
});
</script>
