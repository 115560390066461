<template>
  <v-row :class="`${category ? category.split(' ')[0] : classes} result`">
    <v-col>
      <div class="inline">
        <h4 class="inline">{{ prefix }}</h4>
      </div>
      <div class="inline result_title_url">
        <p class="result_title">
          <strong>{{ text }}</strong>
        </p>
        <div class="website_url_container">
          <a target="_blank" :href="description" class="website_url">{{
            description?.replace("https://", "")
          }}</a>
        </div>
      </div>
      <span v-if="category && (!expandable || description?.includes('www.youtube.com'))" class="category">{{
        sentenceCase(category)
      }}</span>
      <span v-if="extra" class="category extra">{{ sentenceCase(extra) }}</span>
      <!-- <span v-if="score" class="category extra">{{ sentenceCase(score.toFixed(0).toString()) }}</span> -->
      <span v-if="description?.includes('www.youtube.com')" class="category video">{{ "Video" }}</span>
      <v-icon
        v-if="expandable"
        @click="() => expand('related_searches')"
        class="expand_drawer"
        :icon="`${expanded.includes('related_searches') ? 'mdi:mdi-chevron-up' : 'mdi:mdi-chevron-down'}`"
      >
      </v-icon>
      <div :class="`${expanded.includes('related_searches') ? 'visible' : 'hidden'} animated_drawer`">
        <v-container>
          <slot></slot>
        </v-container>
      </div>
    </v-col>
  </v-row>
</template>
<style scoped>
.expand_drawer {
  position: relative;
  float: right;
  color: white;
}

.animated_drawer {
  transition: max-height 0.5s ease-in-out, visibility 0.5s ease-in-out, top 0.5s ease-in-out;
}

.visible {
  overflow: hidden;
  visibility: visible;
  max-height: 1200px;
}

.hidden {
  overflow: hidden;
  visibility: hidden;
  max-height: 0px;
}

.result {
  background: gray;
}

.category {
  position: relative;
  float: right;
  color: white;
  font-size: 10px;
  border: 1px white solid;
  box-shadow: none;
  border-radius: 20px;
  padding: 3px 8px 3px 8px;
}

.extra {
  background-color: orange;
  border-color: orange;
  margin-right: 5px;
}

.video {
  background: orange;
  margin-right: 5px;
}

.website_url_container {
  padding-left: 10px;
  margin-top: -8px;
}

.website_url_container a {
  text-decoration: none;
}

.website_url {
  height: 100%;
  color: #4fe0b5 !important;
  font-size: 12px;
  font-family: "Nunito Sans";
}

.result_title_url {
  position: absolute;
  margin-top: -6px;
}

.result_title_url p {
  margin-left: 10px !important;
}

.result_title strong {
  color: white;
}

p {
  font-size: 12px;
  color: white;
}

h1,
h4 {
  color: white !important;
}

.inline {
  display: inline;
}

h4 {
  padding: 3px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ResultRow",
  props: {
    prefix: String,
    text: String,
    description: String,
    category: String,
    icon: String,
    classes: String,
    extra: String,
    score: Number,
    expandable: Boolean,
  },
  data: () => ({
    highlight: false,
    expanded: [] as string[],
    publicPath: process.env.BASE_URL,
  }),
  methods: {
    sentenceCase(text: string) {
      const words = text.split("_");
      const capitalizedWords = [];
      for (const word of words) {
        const characters = word.split("") || [];
        capitalizedWords.push(`${characters.shift()?.toUpperCase()}${characters.join("")}`);
      }
      return capitalizedWords.join(" ");
    },
    expand(section: string) {
      if (this.expanded.includes(section)) {
        this.expanded = this.expanded.filter((val) => val != section);
      } else {
        this.expanded.push(section);
      }
    },
  },
});
</script>
