<template>
  <div>
    <div v-if="open" class="layer"></div>
    <Transition>
      <div v-if="open" class="dialogPopup inline">
        <h2 class="white">{{ title }}</h2>
        <p v-if="description">{{ description }}</p>
        <div class="content maire-scrollbar">
          <slot></slot>
        </div>
        <div class="buttons" v-if="!hidebuttons">
          <!-- <v-btn v-if="!hideCancel" @click="onClose" :class="`plain-btn inline`">{{ cancelText }}</v-btn> -->
          <div class="btn" style="float: right">
            <MaireButton comfortable @click="fn" :text="confirmText" />
          </div>
          <div class="btn cancel" style="float: right">
            <MaireButton comfortable inverse @click="onClose" :text="cancelText" />
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<style scoped>
.content {
  max-height: 600px;
  width: 100%;
  padding-top: 24px;
  overflow: auto;
}

.buttons .btn {
  width: 100px;
  display: inline-block;
  margin-left: 12px;
}
p {
  text-align: center;
  color: rgb(var(--v-theme-maireDarkBlue)) !important;
}
.v-enter-active {
  transition: opacity 0.2s ease;
}

.v-leave-from,
.v-enter-from {
  opacity: 0;
}

.layer {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  margin: 0px;
  left: 0px;
  top: 0px;
  z-index: 2000;
  height: 100vh;
  position: fixed;
}

.dialogPopup {
  position: fixed;
  text-align: center;
  vertical-align: middle;
  background-color: white;
  border-radius: 24px;
  max-height: 80vh;
  /* overflow: scroll; */
  color: white;
  padding: 24px;
  max-width: 60%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2001;
  /* box-shadow: 0px 0px 50px white; */
}

.buttons {
  position: relative;
  margin: auto;
  bottom: 0px;
  width: 100%;
  margin-bottom: -20px;
}
.error {
  border: 0px solid white;
}
.plain-btn {
  margin-top: 20px;
  padding: 0px 50px;
}

.inverse {
  background-color: #8200ff;
  color: white !important;
}
.plain-btn.inverse:hover {
  color: white !important;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";

export default defineComponent({
  name: "MaireDialog",
  props: {
    fn: { type: Function, required: true },
    onClose: { required: true },
    confirmDisabled: { type: Boolean, required: false, default: false },
    title: { type: String, required: false, default: "Are you sure?" },
    variant: { type: String, required: false },
    confirmText: { type: String, required: false, default: "Confirm" },
    cancelText: { type: String, required: false, default: "Cancel" },
    description: {
      type: String,
      required: false,
      default: "This action cannot be undone.",
    },
    open: { type: Boolean, required: true, default: false },
    hidebuttons: { type: Boolean, required: false, default: false },
    hideCancel: { type: Boolean, required: false, default: false },
  },
  data() {
    return { modalOpen: this.open };
  },
  methods: {
    enterPressed(e: KeyboardEvent) {
      if (this.open && !this.confirmDisabled && e.key === "Enter") {
        this.fn();
      }
    },
  },
  mounted() {
    // add listener for enter and call fn if its presed, disable on unmount
    document.addEventListener("keydown", this.enterPressed);
  },
  unmounted() {
    document.removeEventListener("keydown", this.enterPressed);
  },
  components: { MaireButton },
});
</script>
