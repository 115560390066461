<template>
  <MaireDialog
    :title="
      featureToEnable
        ? `Enable ${featureToEnable.name}`
        : featureToDisable
        ? `Disable ${featureToDisable.name}`
        : 'Unkown feature'
    "
    description=""
    :open="!!feature"
    :confirmDisabled="!userEmailOrCompanyName && feature?.actor_type != 'generic'"
    :fn="enableOrDisableFeature"
    :onClose="close"
  >
    <div>
      <v-text-field
        v-if="['company', 'user'].includes(feature.actor_type)"
        :placeholder="feature.actor_type == 'user' ? 'User email' : 'Company name'"
        variant="outlined"
        v-model="userEmailOrCompanyName"
        density="compact"
      />
      <p v-else>{{`Click confirm to ${enabling ? 'enable' : 'disable'} for all users`}}</p>
      <p v-if="errorText">{{ errorText }}</p>
    </div>
  </MaireDialog>
</template>
<style scoped></style>
<script>
import { defineComponent } from "vue";
import * as api from "@/api";
import MaireDialog from "../MaireDialog/MaireDialog.vue";

export default defineComponent({
  name: "FeaturegateDialog",
  components: {
    MaireDialog,
  },
  props: {
    featureToEnable: { type: Object },
    featureToDisable: { type: Object },
    resetFeatures: { type: Function, required: true },
    users: { type: Array, default: () => [] },
    companies: { type: Array, default: () => [] },
    fetchFeaturegates: { type: Function, required: true },
  },
  data: () => ({
    userEmailOrCompanyName: undefined,
    errorText: "",
  }),
  computed: {
    feature() {
      return this.featureToEnable || this.featureToDisable;
    },
    enabling() {
      return !!this.featureToEnable;
    },
  },
  methods: {
    getActorId() {
      if (this.feature.actor_type == "user") {
        return this.users.find((u) => u.email == this.userEmailOrCompanyName?.trim())?.id;
      } else if (this.feature.actor_type == "company") {
        return this.companies.find((c) => c.name == this.userEmailOrCompanyName?.trim())?.id;
      }
    },
    enableOrDisableFeature() {
      this.errorText = "";
      const actorId = this.getActorId();
      if (!actorId && this.feature?.actor_type != "generic") {
        this.errorText = `Could not find ${this.feature?.actor_type}`;
        return;
      }
      if (this.enabling) {
        api
          .enableFeatureGate(this.feature.name, actorId)
          .then(() => {
            this.fetchFeaturegates();
            this.close();
          })
          .catch(() => (this.errorText = "Failed to enable"));
      } else {
        api
          .disableFeatureGate(this.feature.name, actorId)
          .then(() => {
            this.fetchFeaturegates();
            this.close();
          })
          .catch(() => (this.errorText = "Failed to disable"));
      }
    },
    close() {
      this.errorText = "";
      this.userEmailOrCompanyName = "";
      this.resetFeatures();
    },
  },
});
</script>
