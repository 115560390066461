<template>
  <MaireDialog
    title="Your Data and Privacy"
    description=""
    :open="isOpen"
    :confirmDisabled="!checkbox || !checkbox2"
    :hideCancel="true"
    :fn="saveConsent"
  >
    <v-card id="consent_content">
      <v-card-text>
        Here at Maire.ai, we respect your data and your privacy. As part of our efforts to provide you with
        the best possible service, we utilize certain third-party tools and platforms. Before we do so, we
        need your explicit consent to share some of your data with them.<br /><br />
        <v-checkbox
          v-model="checkbox"
          label="I allow the use of OpenAI to analyze keyword intent and categorize them. Only non-personal and non-proprietary data is shared."
        />
        <v-checkbox
          v-model="checkbox2"
          label="I allow the use of OpenAI to power chat (Maire-assistant) functionalities and help you in content creation."
        />
        For more information on how we and our third-party tools handle your data, refer to our
        <a target="_blank" href="https://www.maire.ai/privacy-statement">[Privacy Policy]</a><br />
        <br />OpenAI's Enterprise Privacy Policy can be found
        <a target="_blank" href="https://openai.com/enterprise-privacy">[Here]</a>
      </v-card-text>
    </v-card>
  </MaireDialog>
</template>
<style>
#consent_content .v-label {
  word-break: break-word;
  white-space: normal;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import { useStore } from "@/store"
export default defineComponent({
  name: "ConsentModla",
  data: () => ({
    checkbox: true,
    checkbox2: true,
  }),
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    saveConsent() {
      this.store.dispatch("saveConsent", {
        allow_kw: this.checkbox,
        allow_chat: this.checkbox2,
      });
    },
  },
  computed: {
    user() {
      return this.store.state.user;
    },
    isOpen() {
      return !this.user?.consent;
    },
  },
  components: {
    MaireDialog,
  },
});
</script>
