<template>
  <MaireCard title="Google page ranking" style="text-align: left">
    <div id="search-widget" v-if="performance_data?.length > 0" class="content">
      <div style="margin-left: -12px; margin-right: -12px">
        <apexchart
          v-if="performance_data?.length > 0"
          :options="chartOptions"
          :series="series"
          height="300"
        />
      </div>
      <ul>
        <li>
          <p>
            <strong>{{ donutSeries[0] }}</strong> search terms with your content on the 1st page of Google
          </p>
        </li>
        <li>
          <p>
            <strong>{{ donutSeries.reduce((x: number, y: number) => x + y, 0) }} </strong>
            search terms with your content on the 1st 10 pages of Google
          </p>
        </li>
      </ul>
    </div>
    <div v-else-if="performance_data?.length === 0" style="padding: 50px 20px">
      <h4 style="margin-bottom: 20px">❗ No data ❗</h4>
      <p>
        We have no position information for your keywords. Try to add some favorites and we'll fetch the
        positions for those.
      </p>
    </div>
    <WaitAnimation v-else />
  </MaireCard>
</template>
<style scoped>
#search-widget {
  padding-bottom: 20px;
}
ul {
  list-style: none; /* Hide the default bullets */
  padding: 0;
}
.content {
  padding-top: 30px;
}

ul li {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically in the center */
  padding-left: 20px; /* Adjust the indentation for the content */
  position: relative; /* Use relative positioning to create space for the bullet */
  padding-top: 12px;
}

li p {
  margin-top: -12px;
  padding-left: 10px;
  font-size: 14px;
  line-height: 1.5;
  color: rgb(var(--v-theme-maireGrey1));
}
ul li::before {
  content: ""; /* Create a pseudo-element for the bullet */
  width: 12px; /* Adjust the bullet width */
  height: 12px; /* Adjust the bullet height */
  background-color: purple; /* Color of the bullet */
  border-radius: 50%; /* Create a circular bullet */
  position: absolute; /* Position the bullet */
  left: 0; /* Adjust the position of the bullet */
  top: 4px;
}

ul li:nth-child(odd)::before {
  background-color: rgba(var(--v-theme-maireTeal), 0.2);
}
ul li:nth-child(even)::before {
  background-color: rgb(var(--v-theme-maireGrey3));
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import MaireCard from "@/components/MaireCard/MaireCard.vue";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import { numberFormatter } from "@/components/helpers/helpers";
import { useStore } from "@/store";

export default defineComponent({
  name: "GooglePresenceAlternateWidget",
  setup() {
    const store = useStore();
    const performance_data = store.state.performance_data || [];
    return { performance_data };
  },
  data: () => ({
    chartOptions: {
      plotOptions: {
        bar: {
          borderRadius: 5,
          borderRadiusApplication: "around",
          borderRadiusWhenStacked: "last",
          dataLabels: {
            enabled: true,
            orientation: "horizontal",
            textAnchor: "middle",
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: "12px",
          fontFamily: "Nunito Sans",
          colors: ["#232323"],
        },
        formatter: function (val: number) {
          return numberFormatter(val, 1);
        },
      },
      chart: {
        type: "bar",
      },
      colors: [
        function ({ dataPointIndex }: { dataPointIndex: number }) {
          return dataPointIndex === 0 ? "#14ccc9" : "#14ccc933";
        },
      ],
      fill: {
        type: "solid",
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      yaxis: [
        {
          show: false,
        },
      ],
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        type: "number",
        title: {
          text: "Google page number",
          style: {
            fontWeight: 400,
            fontSize: "14px",
          },
        },
        labels: {
          style: {
            colors: "232323",
            fontWeight: 400,
          },
        },
        categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      },
      tooltip: {
        x: {
          show: true,
          formatter: (text: any) => `Google's ${text}. Page`,
        },
      },
    },
  }),
  computed: {
    series() {
      if (!this.performance_data) return [];
      const obj = {} as { [key: number]: number };
      const series = Array.from(Array(11).keys()).reduce((obj: { [key: number]: number }, key: number) => {
        if (key !== 0) {
          obj[key] = 0;
        }
        return obj;
      }, obj as { [key: number]: number }) as { [key: number]: number };
      for (const row of this.performance_data) {
        const bucket = Math.floor(row.position / 10) + 1;
        series[bucket] += 1;
      }
      return [
        {
          name: "# of your content",
          data: Object.entries(series).map(([key, value]) => ({
            x: key,
            y: value,
          })),
        },
      ];
    },

    donutSeries() {
      let firstPage = 0;
      let otherPages = 0;
      for (const row of this.performance_data) {
        if (row.position <= 10) {
          firstPage += 1;
        } else {
          otherPages += 1;
        }
      }
      return [firstPage, otherPages];
    },
  },
  components: { MaireCard, WaitAnimation },
});
</script>
