<template>
  <div @mousemove="setPos" @mouseleave="unsetPos" @mouseenter="triggered">
    <slot name="trigger"></slot>
    <div
      v-if="left > 0"
      class="tooltip-content"
      @mouseenter="enterTooltip"
      @mouseleave="unsetPos"
      ref="contentRef"
      :style="`top:${top}px;left:${left}px`"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>
<style scoped>
@keyframes rotation {
  from {
    transform: rotate(0deg);
    opacity: 0.2;
  }
  to {
    transform: rotate(359deg);
    opacity: 0.6;
  }
}
.tooltip-content {
  position: fixed;
  text-align: left;
  max-width: 400px;
  margin-top: -20px;
  margin-left: 20px;
  padding: 12px 24px;
  z-index: 999;
  background-color: rgb(var(--v-theme-maireDarkBlue));
  border-radius: 10px;
  color: white;
}

p {
  font-size: 10px;
  position: absolute;
}
</style>

<script lang="ts" setup>
import { ref, defineProps, nextTick, Ref, defineEmits } from "vue";
const contentRef: Ref<HTMLDivElement | null> = ref(null);
const left = ref(0);
const top = ref(0);
const tooltipHovered = ref(false);
const timerId: any = ref(null);
const props = defineProps({
  position: { type: String, required: false, default: "right" },
  follow: { type: Boolean, required: false, default: true },
});
const emit = defineEmits(["triggered"]);
const triggered = () => emit("triggered");
const setPos = async (ev: any) => {
  if (props.follow) {
    left.value = ev.clientX;
    top.value = ev.clientY;
  } else if (left.value === 0) {
    left.value = ev.clientX;
    top.value = ev.clientY;
  }
  await nextTick();
  const contentHeight = contentRef.value?.offsetHeight || 0;
  const viewportHeight = window.innerHeight;

  if (top.value + contentHeight > viewportHeight) {
    top.value = top.value - (top.value + contentHeight - viewportHeight);
  }
};
const enterTooltip = () => {
  if (timerId.value) {
    clearTimeout(timerId.value);
  }
};
const unsetPos = () => {
  timerId.value = setTimeout(() => {
    tooltipHovered.value = false;
    left.value = 0;
    top.value = 0;
  }, 100);
};
</script>
