import { ActionContext, ActionTree, Module, MutationTree } from "vuex";
import { State as RootState } from "@/store/index";
import {
  AnalysisState,
  AnalysisFilters,
  Analysis,
  View,
  KeywordWithLabels,
  CheckboxState,
  RangeSliderState,
  LabelFilter,
  AnalysisSummary,
} from "./types";
import * as api from "@/api";
import { EXAMPLE_FILTERS } from "./helpers";
import { Keyword } from "../keywords/types";
import router from "@/router";
import { ViewLabel } from "../labels/types";
import saveAs from "file-saver";
import { TimeRangeIncomplete } from "@/components/MonthPicker/MonthPicker.vue";
import { CanceledError } from "axios";

const getDefaultState = (): AnalysisState => ({
  analyses: [],
  views: {},
  currentFilters: JSON.parse(JSON.stringify(EXAMPLE_FILTERS)),
  requestTimings: {},
});

// Mutations
const mutations: MutationTree<AnalysisState> = {
  resetState: (state) => {
    state = Object.assign(state, getDefaultState());
  },
  setAnalyses: (state, analyses: Analysis[]) => {
    state.analyses = analyses;
  },
  setAnalysis: (state, analysis: Analysis) => {
    state.analyses = state.analyses.map((a) => (a.id === analysis.id ? analysis : a));
  },
  setAnalysisSummary: (state, data: { analysisId: number; summary: AnalysisSummary }) => {
    const { analysisId, summary } = data;
    const af = state.analyses.find((a) => a.id === analysisId);
    if (af) {
      af.summary = summary;
    }
  },
  filterOutAnalysis: (state, analysisId: number) => {
    state.analyses = state.analyses.filter((af) => af.id !== analysisId);
  },
  filterOutView: (state, viewId: number) => {
    state.analyses.forEach((af) => {
      af.views = af.views.filter((v) => v.id !== viewId);
    });
    delete state.views[viewId];
  },
  updateOrAddAnalysis: (state, analysis: Analysis) => {
    // add if id doesn't exist, update if does
    const existingAnalysis = state.analyses.find((a) => a.id === analysis.id);
    if (existingAnalysis) {
      existingAnalysis.name = analysis.name;
      existingAnalysis.description = analysis.description;
    } else {
      state.analyses.push(analysis);
    }
  },
  setViews: (state, views: Record<number, View>) => {
    state.views = views;
  },
  setView: (state, view: View) => {
    const oldView = state.views?.[view.id] || {};
    const newView = { ...oldView, ...view };
    state.views = { ...state.views, [view.id]: { ...oldView, ...newView } };
  },
  setViewCurrentLevel: (state, data: { view: View; level: number }) => {
    if (state.views?.[data.view.id]?.currentLevel === data.level) return;
    state.views = { ...state.views, [data.view.id]: { ...data.view, currentLevel: data.level } };
  },
  setAnalysisView: (state, data: { analysisId: number; view: View }) => {
    const analysis = state.analyses.find((analysis) => analysis.id === data.analysisId);
    if (analysis) {
      analysis.views = analysis.views.map((view) => {
        return view.id === data.view.id ? data.view : view;
      });
    }
  },
  addViewToAnalysis: (state, view: View) => {
    const analysis = state.analyses.find((analysis) => analysis.id === view.analysis_filter_id);
    if (analysis) {
      analysis.views = [...analysis.views, view];
    }
  },
  setViewFilters: (state, data: { viewId: number; filters: AnalysisFilters }) => {
    if (!state.views[data.viewId]) {
      return;
    }
    state.views[data.viewId].filters = data.filters;
  },
  setCurrentFilters: (state, filters: AnalysisFilters) => {
    state.currentFilters = filters;
  },
  setCurrentAnalysis: (state, analysis: Analysis) => {
    state.currentAnalysis = analysis;
  },
  addKeywordsToAnalysis: (state, data: { filter_id: number; keywords: Keyword[]; skipIfEmpty?: boolean }) => {
    const f = state.analyses.find((f) => f.id === data.filter_id);
    if (!f || !data.keywords || !data.filter_id) {
      return;
    }
    if (!f.keywords && !data.skipIfEmpty) {
      f.keywords = data.keywords;
    } else {
      const existingIds = f.keywords?.map((kw) => kw.id) ?? [];
      if (!(existingIds.length === 0 && data.skipIfEmpty)) {
        f.keywords = (f?.keywords || []).concat(data.keywords.filter((kw) => !existingIds.includes(kw.id)));
      }
    }
  },
  removeKeywordsFromAnalysis: (state, data: { filter_id: number; kwIds: number[] }) => {
    const f = state.analyses.find((f) => f.id === data.filter_id);
    if (f) {
      f.keywords = f.keywords.filter((kw) => !data.kwIds.includes(kw.id));
    }
  },
  setLabelFilters: (state: AnalysisState, filter: LabelFilter) => {
    state.currentFilters.labelFilters = filter;
  },
  setIncludeLabels: (state: AnalysisState, labels: CheckboxState[]) => {
    state.currentFilters.labelFilters.include.labels = labels;
  },
  setVolumeRange: (state: AnalysisState, range: RangeSliderState) => {
    state.currentFilters.volumeRange = {
      ...state.currentFilters.volumeRange,
      ...range,
    };
  },
  setTrendingFilter: (state: AnalysisState, trendingFilter: CheckboxState[]) =>
    (state.currentFilters.trendingFilter = trendingFilter),
  setCountryFilter: (state: AnalysisState, countryFilter: CheckboxState[]) =>
    (state.currentFilters.countryFilter = countryFilter),
  setLanguageFilter: (state: AnalysisState, languageFilter: CheckboxState[]) =>
    (state.currentFilters.languageFilter = languageFilter),
  resetLocalizationFilters: (state: AnalysisState, kws: Keyword[]) => {
    const countries = new Set();
    const languages = new Set();
    kws.forEach((kw) => {
      countries.add(kw.localization.location_name);
      languages.add(kw.localization.language_code);
    });
    state.currentFilters.countryFilter = Array.from(countries).map((country) => ({
      text: country as string,
      checked: false,
    }));
    state.currentFilters.languageFilter = Array.from(languages).map((language) => ({
      text: language as string,
      checked: false,
    }));
  },
  setSearchWord: (state: AnalysisState, searchWord: string) => {
    state.currentFilters.searchWord = searchWord;
  },
  setVolumeRangeSliderState: (state: AnalysisState, range: number[]) =>
    (state.currentFilters.volumeRange = {
      ...state.currentFilters.volumeRange,
      min: range[0],
      max: range[1],
    }),
  setPositionFilter: (state: AnalysisState, range: number[]) =>
    (state.currentFilters.positionRange = {
      ...state.currentFilters.positionRange,
      min: range[0],
      max: range[1],
    }),
};

// Actions
const actions: ActionTree<AnalysisState, RootState> = {
  async fetchAnalyses({ commit }: ActionContext<AnalysisState, RootState>) {
    const r = await api
      .fetchAnalyses()
      .then((r) => {
        const analyses = r.data;
        const views = analyses.reduce((acc: Record<string, View>, analysis: Analysis) => {
          analysis.views.forEach((view) => {
            acc[view.id] = view;
          });
          return acc;
        }, {});
        commit("setAnalyses", analyses);
        commit("setViews", views);
      })
      .catch(() => {});
  },
  async fetchAnalysisSummary({ commit }, data: { analysisId: number }): Promise<AnalysisSummary> {
    const { analysisId } = data;
    const r = await api.fetchAnalysisSummary({ analysisId });
    commit("setAnalysisSummary", { analysisId, summary: r.data });
    return r.data;
  },
  async deleteAnalysis({ commit }: ActionContext<AnalysisState, RootState>, analysis: Analysis) {
    if (!analysis.id) {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
      return;
    }
    const r = await api.deleteAnalysis(analysis.id);
    // filter out the deleted filter
    if (r.status === 200) {
      commit("setSuccessText", "Analysis deleted", { root: true });
      commit("filterOutAnalysis", analysis.id);
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },
  async deleteView({ commit }: ActionContext<AnalysisState, RootState>, view: View) {
    if (!view.id) {
      commit("setErrorText", "No view provided. Please contact Maire support.", { root: true });
      return;
    }
    const r = await api.deleteFilterView(view.id);
    // filter out the deleted filter
    if (r.status === 200) {
      commit("setSuccessText", "View deleted", { root: true });
      commit("filterOutView", view.id);
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },
  async switchAnalysisFilterLevel(
    { dispatch, state }: ActionContext<AnalysisState, RootState>,
    { filterId, viewId, level }: { filterId: number; viewId: number; level: number }
  ) {
    const analysis = state.analyses.find((f) => f.id === filterId);
    if (analysis) {
      dispatch("selectView", { filterId: analysis.id, viewId, level }, { root: true });
    }
  },
  async fetchAnalysisLabelStats(
    { state, commit }: ActionContext<AnalysisState, RootState>,
    { analysisId, labels }: { analysisId: number; viewId: number; kwIds: number[]; labels: string[] }
  ) {
    if (!analysisId || !labels.length) return;
    const currentFilters = state.currentFilters;
    const currentAnalysis = state.currentAnalysis;
    commit("loading/addFilterToLoadingLabelVolumes", analysisId, { root: true });
    return api
      .fetchAnalysisLabelStats(analysisId, labels, currentAnalysis?.viewId, currentFilters)
      .then((r) => {
        commit("loading/removeFilterFromLoadingLabelVolumes", analysisId, { root: true });
        return r.data;
      })
      .catch((e) => {
        if (e instanceof CanceledError) {
          console.log(e);
        } else {
          commit("loading/removeFilterFromLoadingLabelVolumes", analysisId, { root: true });
          throw e; // let others bubble up
        }
      });
  },
  async fetchPredictions(
    { commit }: ActionContext<AnalysisState, RootState>,
    { analysisId, series }: { analysisId: number; series: Record<string, Record<number, number[]>> }
  ) {
    if (!series || !analysisId) return;
    commit("loading/addFilterToLoadingPredictions", analysisId, { root: true });
    return await api
      .fetchPredictions({ series })
      .then((r) => {
        return r.data;
      })
      .catch((e) => {
        console.log("Something went wrong with predicting", e);
      })
      .finally(() => {
        commit("loading/removeFilterFromLoadingPredictions", analysisId, { root: true });
      });
  },
  async fetchKwStatsForKwIds(
    _,
    {
      timerange,
      comparison,
      include_only_new,
      field,
      preset,
      kwIds,
    }: {
      timerange: TimeRangeIncomplete;
      comparison: TimeRangeIncomplete;
      kwIds: number[];
      include_only_new?: boolean;
      field?: string;
      preset?: string;
    }
  ) {
    if (!kwIds || !kwIds?.length) return;
    const r = await api.fetchKwStatsForKeywordIds({
      kwIds,
      field,
      preset,
      timerange,
      comparison,
      include_only_new,
    });
    return r.data;
  },
  async fetchAnalysisKwStats(
    { state }: ActionContext<AnalysisState, RootState>,
    {
      timerange,
      comparison,
      include_only_new,
      field,
      preset,
      comparisonType,
      labels,
    }: {
      timerange: TimeRangeIncomplete;
      comparison: TimeRangeIncomplete;
      include_only_new?: boolean;
      field?: string;
      preset?: string;
      comparisonType?: string;
      level?: number;
      labels?: string[];
    }
  ) {
    const filters = state.currentFilters;
    const currentAnalysis = state.currentAnalysis;
    const analysisId = currentAnalysis?.id;
    if (!analysisId || !timerange) return Promise.reject("Error: no analysis, or timerange provided.");
    if (!field) {
      field = "volume";
    }
    const r = await api.fetchAnalysisKwStats({
      analysisId,
      filters,
      timerange,
      comparison,
      include_only_new,
      field,
      preset,
      comparisonType,
      labels,
    });
    return r.data;
  },
  async fetchMatchingKeywords(
    { state }: ActionContext<AnalysisState, RootState>,
    { labels }: { labels: string[] }
  ) {
    const filters = state.currentFilters;
    const currentAnalysis = state.currentAnalysis;
    const analysisId = currentAnalysis?.id;
    if (!analysisId) return;
    const promises = [];
    promises.push(
      api.fetchMatchingKeywords({
        analysisId,
        filters,
        labels: labels,
        field: "volume",
      })
    );
    promises.push(
      api.fetchMatchingKeywordsSummary({
        analysisId,
        filters,
        labels: labels,
        field: "volume",
      })
    );

    const [r1, r2] = await Promise.all(promises);
    return { kws: r1.data, summary: r2.data };
  },
  async createOrUpdateAnalysis({ commit }: ActionContext<AnalysisState, RootState>, filter: Analysis) {
    const filterSaved = filter.id ? true : false;
    const r = await api.createOrUpdateAnalysis(filter);
    if (r.status === 200) {
      commit("setSuccessText", `Analysis ${filterSaved ? "updated" : "created"}!`, { root: true });
      commit("updateOrAddAnalysis", r.data);
      commit("setCurrentAnalysis", {
        id: r.data.id,
        name: r.data.name,
        description: r.data.description,
        volume_field: r.data.volume_field,
        edit_access: r.data.edit_access,
      });
      if (r.data?.views?.length > 0) {
        commit("setView", r.data.views?.[0]);
      }
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },
  async createView({ commit }: ActionContext<AnalysisState, RootState>, view: View) {
    const r = await api.createFilterView(view.analysis_filter_id, view);
    if (r.status === 200) {
      commit("setSuccessText", "View created!", { root: true });
      commit("setView", r.data);
      commit("addViewToAnalysis", r.data);
      router.push(`/analysis/${r.data.analysis_filter_id}?view=${r.data.id}`);
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },
  async updateView({ commit }: ActionContext<AnalysisState, RootState>, view: View) {
    const r = await api.updateFilterView(view);
    if (r.status === 200) {
      commit("setSuccessText", `View updated!`, { root: true });
      commit("setView", r.data);
      commit("setAnalysisView", { analysisId: r.data.analysis_filter_id, view: r.data });
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },

  async addKeywordsToAnalysis(
    { commit }: ActionContext<AnalysisState, RootState>,
    data: { filter: Analysis; kws: Keyword[]; skipIfEmpty?: boolean }
  ) {
    const { filter, kws, skipIfEmpty } = data;
    const r = await api.addKeywordsToAnalysis(
      kws.map((kw) => kw.id),
      filter.id
    );
    if (r.status === 200) {
      commit("addKeywordsToAnalysis", { filter_id: filter.id, keywords: kws, skipIfEmpty });
      commit("setSuccessText", `Added ${kws.length} keywords to the analysis ${filter.name}`, { root: true });
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },

  async removeKeywordsFromAnalysis(
    { commit }: ActionContext<AnalysisState, RootState>,
    data: { filter: Analysis; kwIds: number[] }
  ) {
    const { filter, kwIds } = data;
    const r = await api.removeKeywordsFromAnalysis(kwIds, filter.id);
    if (r.status === 200) {
      commit("removeKeywordsFromAnalysis", { filter_id: filter.id, kwIds });
      commit("setSuccessText", `Removed ${kwIds.length} keywords from the analysis ${filter.name}`, {
        root: true,
      });
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },

  async addLabelFilter(
    { commit, dispatch }: ActionContext<AnalysisState, RootState>,
    data: { type: string; label: string }
  ) {
    dispatch("toggleLabelFilter", {
      cb: { text: data.label, checked: true },
    });
  },
  async selectLabels({ commit, state }: ActionContext<AnalysisState, RootState>, labels: ViewLabel[]) {
    const labelFilters = state.currentFilters.labelFilters;
    const labelTexts = labels.map((lbl) => lbl.text);
    const updatedLabels = labelFilters.include.labels.map((cb) => ({
      ...cb,
      checked: labelTexts.includes(cb.text) ? true : cb.checked,
    }));
    commit("setIncludeLabels", updatedLabels);
  },
  async unselectLabels({ commit, state }: ActionContext<AnalysisState, RootState>, labels: ViewLabel[]) {
    const labelFilters = state.currentFilters.labelFilters;
    const labelTexts = labels.map((lbl) => lbl.text);
    const updatedLabels = labelFilters.include.labels.map((cb) => ({
      ...cb,
      checked: labelTexts.includes(cb.text) ? false : cb.checked,
    }));
    commit("setIncludeLabels", updatedLabels);
  },
  async toggleLabelFilter(
    { commit, state }: ActionContext<AnalysisState, RootState>,
    data: { cb?: CheckboxState; label?: string }
  ) {
    if (!data.cb && data.label) {
      data.cb = { text: data.label, checked: true };
    } else if (!data.cb && !data.label) {
      return;
    }
    const labelFilters = state.currentFilters.labelFilters;
    const updatedLabels = labelFilters.include.labels
      .map((cb) => (cb.text === data.cb?.text ? { ...cb, checked: !cb.checked } : cb))
      .sort((a, b) => a.text.localeCompare(b.text))
      .sort((a, b) => (a.checked ? -1 : 1) - (b.checked ? -1 : 1));
    commit("setIncludeLabels", updatedLabels);
  },
  async adjustTrendingFilter({ commit, state }: ActionContext<AnalysisState, RootState>, selected: string[]) {
    const newTrendingFilter = state.currentFilters.trendingFilter.map((cb) =>
      selected.includes(cb.text) ? { ...cb, checked: true } : { ...cb, checked: false }
    );
    commit("setTrendingFilter", newTrendingFilter);
  },
  async adjustCountryFilter({ commit, state }: ActionContext<AnalysisState, RootState>, selected: string[]) {
    const newCountryFilter = state.currentFilters.countryFilter.map((cb) =>
      selected.includes(cb.text) ? { ...cb, checked: true } : { ...cb, checked: false }
    );
    commit("setCountryFilter", newCountryFilter);
  },
  async adjustLanguageFilter({ commit, state }: ActionContext<AnalysisState, RootState>, selected: string[]) {
    const newLanguageFilter = state.currentFilters.languageFilter.map((cb) =>
      selected.includes(cb.text) ? { ...cb, checked: true } : { ...cb, checked: false }
    );
    commit("setLanguageFilter", newLanguageFilter);
  },
  async downloadVolumes({ commit }: ActionContext<AnalysisState, RootState>, analysis: Analysis) {
    try {
      commit("loading/addAnalysisToDownloadingVolumes", analysis.id, { root: true });
      const response = await api.downloadAnalysisVolumes(analysis.id);

      // Extract the filename from the Content-Disposition header
      const contentDisposition = response.headers["content-disposition"];
      let filename = "default.csv"; // Default filename if not found in header
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (filenameMatch?.length === 2) {
          filename = filenameMatch[1];
        }
      }

      // Create a new Blob object using the response data of the file
      const blob = new Blob([response.data], { type: "text/csv" });

      // Use file-saver to save the Blob as a file
      saveAs(blob, filename);
    } catch (error) {
      commit("setErrorText", "Error when downloading the file", { root: true });
    }
    commit("loading/removeAnalysisFromDownloadingVolumes", analysis.id, { root: true });
  },
  async shareItem(
    { commit }: ActionContext<AnalysisState, RootState>,
    data: {
      itemType: "analysis" | "view";
      item: Analysis | View;
      actorType: "user" | "company";
      actorIds: number[];
      editAccess: boolean;
      refreshShares?: Function;
    }
  ) {
    const { itemType, item, refreshShares, ...rest } = data;
    const r = await api.shareItem(itemType, item.id, rest);
    if (r.status === 200) {
      const successText =
        data.actorType === "user"
          ? `Shared ${data.itemType} ${data.item.name} to ${data.actorIds.length} users`
          : `Shared ${data.itemType} ${data.item.name} to the company`;
      commit("setSuccessText", successText, { root: true });
      refreshShares && refreshShares();
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },
  async unShareItem(
    { commit }: ActionContext<AnalysisState, RootState>,
    data: {
      itemType: "analysis" | "view";
      item: Analysis | View;
      actorType: "user" | "company";
      actorIds: number[];
      refreshShares?: Function;
    }
  ) {
    const { itemType, item, refreshShares, ...rest } = data;
    const r = await api.unShareItem(itemType, item.id, rest);
    if (r.status === 200) {
      const successText =
        data.actorType === "user"
          ? `Unshared ${data.itemType} ${data.item.name} from ${data.actorIds.length} users`
          : `Unshared ${data.itemType} ${data.item.name} from the company`;
      commit("setSuccessText", successText, { root: true });
      refreshShares && refreshShares();
    } else {
      commit("setErrorText", "Something went wrong. Please try again later.", { root: true });
    }
  },
};

const analysisModule: Module<AnalysisState, RootState> = {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
};

export default analysisModule;
