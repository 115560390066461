<template>
  <div class="container">
    <div class="gauge_container" :style="style">
      <div class="rainbow_container">
        <div class="gauge">
          <div class="blue_block">
            <!-- <div class="blue" :style="'transform: rotate(' + progress + 'deg)'"> -->
          </div>
          <div class="white"></div>
        </div>
      </div>
      <div class="tick_container">
        <!-- <div class="tick"> -->
        <div class="tick"></div>
        <div class="another_white"></div>
        <div class="gauge-label">{{ Math.round(percentage || 0) }}%</div>
        <!-- Add this line to add label -->
      </div>
    </div>
  </div>
</template>

<style scoped>
.gauge {
  background-image: linear-gradient(to right, red, yellow, green);
  height: 100%;
  width: 100%;
  border-radius: 50%;
  position: absolute;
  overflow: hidden;
}

.white {
  border-radius: 50%;
  height: 60%;
  width: 60%;
  background-color: white;
  position: absolute;
  top: 20%;
  left: 20%;
}

.another_white {
  border-radius: 50%;
  height: 40%;
  width: 40%;
  left: 30%;
  top: 30%;
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
}
.gauge-label {
  position: absolute;
  top: 43%;
  left: 50%;
  font-size: 1em;
  transform: translate(-50%, -50%);
  color: #0f0f0f;
}

@keyframes gaugeAnimation {
  from {
    transform: rotate(-180deg);
  }

  to {
    transform: rotate(v-bind(gaugeDegrees));
  }
}

.blue_block {
  width: 100%;
  height: 100%;
  background-color: #293657;
  position: absolute;
  top: 50%;
  animation-name: gaugeAnimation;
  animation-duration: 2s;
  transform: rotate(v-bind(gaugeDegrees));
  transform-origin: top;
}
.rainbow_container {
  width: 100%;
  height: 200%;
  background-color: black;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  position: absolute;
}
.tick_container {
  width: 110%;
  height: 220%;
  left: -5%;
  top: -10%;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  position: absolute;
}
.gauge_container {
  position: relative;
  margin: auto;
}
.container {
  padding: 30px 20px 0px 20px;
  margin-bottom: 20px;
  overflow: hidden;
}

.tick {
  height: 0.5%;
  width: 100%;
  right: 0%;
  animation-name: gaugeAnimation;
  animation-duration: 2s;
  transform: rotate(v-bind(gaugeDegrees));
  transform-origin: top;
  top: 49.75%;
  background-image: linear-gradient(to right, transparent 0 50%, #293657 50%);
  position: absolute;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "RevenueGauge",
  props: {
    width: { required: true, type: Number },
    percentage: { required: true, type: Number },
  },

  computed: {
    style() {
      return "height: " + this.width / 2 + "px; width: " + this.width + "px";
    },
    gaugeDegrees() {
      return `${(100 - this.percentage) * 1.8 * -1}deg`;
    },
  },
});
</script>
