import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a7b067f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-text" }
const _hoisted_2 = {
  key: 1,
  class: "select-options-list overflow-y-auto"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_RangeSlider = _resolveComponent("RangeSlider")!
  const _component_CheckboxGroup = _resolveComponent("CheckboxGroup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showSelect)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(`select ${_ctx.expanded || _ctx.filterInUse ? 'expanded' : ''} ${_ctx.disabled ? 'disabled' : ''}`)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`select-content`),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.disabled && (_ctx.expanded = !_ctx.expanded)))
          }, [
            _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.getSelectText), 1),
            _createVNode(_component_v_icon, {
              icon: `${_ctx.expanded ? 'mdi:mdi-chevron-up' : 'mdi:mdi-chevron-down'}`,
              class: "caret-icon"
            }, null, 8, ["icon"])
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.expanded || !_ctx.showSelect)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(`select-options ${_ctx.type === 'checkbox' ? 'small' : ''} ${_ctx.transparent ? 'transparent' : ''}`)
        }, [
          (_ctx.type === 'slider')
            ? (_openBlock(), _createBlock(_component_RangeSlider, {
                key: 0,
                hideButtons: _ctx.hideButtons,
                onChange: _ctx.handleSliderChange,
                onSave: _ctx.handleVolumeSave,
                sliderState: _ctx.sliderState
              }, null, 8, ["hideButtons", "onChange", "onSave", "sliderState"]))
            : _createCommentVNode("", true),
          (_ctx.type === 'select')
            ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: _ctx.field && typeof item === 'object' ? item[_ctx.field] : item,
                    onClick: () => _ctx.handleSave(item),
                    class: "select-option"
                  }, _toDisplayString(_ctx.field && typeof item === "object" ? item[_ctx.field] : item), 9, _hoisted_3))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.type === 'checkbox')
            ? (_openBlock(), _createBlock(_component_CheckboxGroup, {
                key: 2,
                onChange: _ctx.handleCheckboxChange,
                checkboxes: _ctx.checkboxes
              }, null, 8, ["onChange", "checkboxes"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}