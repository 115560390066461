<template>
  <div :class="`chart ${loading ? 'loading' : ''}`" v-show="options" style="position: relative; height: 100%">
    <apexchart
      height="400"
      ref="chart"
      :options="options"
      :series="series"
      @legendClick="handleClick"
    ></apexchart>
  </div>
</template>
<style scoped>
.waiting_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  padding: 13%;
}
span {
  background-color: rgb(255, 176, 176);
  margin-top: 70px;
  padding: 10px;
}
.loading {
  filter: blur(4px) brightness(80%);
}
.chart {
  transition: filter 0.5s ease-in-out;
}
</style>
<script lang="ts" setup>
import { defineEmits, defineProps, onMounted, ref, Ref, watch, PropType } from "vue";
import { VueApexChartsComponent } from "vue3-apexcharts";
import { Label } from "@/store/modules/labels/types";
import { useLabelColor } from "@/composables/useLabelColor";
const helpers = useLabelColor();
export interface VolumeDataPoint {
  date_from: Date;
  monthly_searches: number;
  is_forecast?: boolean;
}
const emit = defineEmits(["unselect"]);
const props = defineProps({
  stats: {
    type: Object,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  labels: {
    type: Object as PropType<Record<string, Label>>,
    required: true,
  },
  dataDateRange: {
    type: Object,
    required: false,
    properties: {
      latestYear: {
        type: Number,
        required: false,
      },
      latestMonth: {
        type: Number,
        required: false,
      },
      firstYear: {
        type: Number,
        required: false,
      },
      firstMonth: {
        type: Number,
        required: false,
      },
    },
  },
});
const chart = ref(null);
interface Series {
  name: string;
  type: string;
  color: string;
  data: any;
}

const series: Ref<Series[]> = ref([]);
const options = ref(null);
const yearsLayered = ref(false);
function layerYearsOverEachOther() {
  yearsLayered.value = !yearsLayered.value;
  series.value = calculateSeries();
  options.value = calculateOptions();
}
function calculateSeries() {
  const output: Series[] = [];
  Object.entries(props.stats).forEach(([label, volObject]) => {
    let data: object[] = [];
    Object.entries(volObject).forEach(([year, monthArray]) => {
      // @ts-ignore
      monthArray.forEach((val: number, index: number) => {
        if (!yearsLayered.value && val === null) return;
        data.push({
          x: Date.parse(`${yearsLayered.value ? "1970" : year}-${(index + 1) < 10 ? "0" : ""}${index + 1}-15`),
          y: val,
          forecasted:
            props.dataDateRange?.latestYear && val
              ? Date.parse(`${year}-${index + 1}-15`) >
                Date.parse(`${props.dataDateRange.latestYear}-${props.dataDateRange.latestMonth}-15`)
              : false,
        });
      });
      if (yearsLayered.value) {
        output.push({
          name: `${label} (${year})`,
          type: "line",
          color: helpers.getLabelColor(label, undefined, 1 - (props.dataDateRange?.latestYear - year) * 0.2),
          data,
        });
        data = [];
      }
    });
    !yearsLayered.value &&
      output.push({
        name: label,
        type: "line",
        color: helpers.getLabelColor(label),
        data,
      });
  });
  return output;
}
const createAnnotationOptions = (srs: any[] | undefined) => {
  const predictionData = (srs ?? series.value)?.[0]?.data?.filter((x: any) => x.forecasted);
  const firstDayOfForecast = new Date(predictionData?.[0]?.x).getTime();
  const lastDayOfForecast = new Date(predictionData?.slice(-1)?.[0]?.x).getTime();
  const lastYearInData = lastDayOfForecast
    ? new Date(lastDayOfForecast).getFullYear()
    : props.dataDateRange?.latestYear;
  const yearDividers = [];
  for (let year = props.dataDateRange?.firstYear; year < lastYearInData; year++) {
    yearDividers.push({
      x: new Date(`${year + 1}-01-01`).getTime(),
      borderColor: "rgba(130, 0, 255, 0.2)",
      strokeDashArray: 0,
      label: {
        borderColor: "rgba(0,0,0,0)",
        style: {
          color: "#fff",
          background: "rgba(130, 0, 255, 0.5)",
        },
        orientation: "horizontal",
        text: year + 1,
      },
    });
  }
  return {
    xaxis: lastDayOfForecast
      ? [
          {
            x: firstDayOfForecast,
            x2: lastDayOfForecast,
            fillColor: "#8200FF",
            borderColor: "#8200FF",
            opacity: 0.05,
            label: { text: "Forecasting period", style: { color: "#8200FF" } },
          },
          ...yearDividers,
        ]
      : yearDividers,
  };
};
const calculateAndDraw = () => {
  series.value = calculateSeries(); // tää on kans
  const annotations = createAnnotationOptions(series.value);
  chart.value.chart.updateOptions(
    {
      series: series.value,
      ...{ annotations },
    },
    false,
    true,
    false
  );
};
watch(
  () => props.stats,
  () => {
    if (Object.keys(props.stats)) {
      if (chart.value) {
        calculateAndDraw();
      }
    }
  }
);
onMounted(() => {
  series.value = calculateSeries();
  options.value = calculateOptions();
  const el = chart.value as VueApexChartsComponent;
  chart.value = el;
});

const handleClick = (_event: any, index: any) => {
  const label = series.value?.[index]?.name;
  if (label) {
    emit("unselect", label);
  }
};

const calculateOptions = () => {
  const annotations = createAnnotationOptions(series.value);
  return {
    chart: {
      id: "LabelVolumeChart",
      toolbar: {
        show: true,
        tools: {
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [
            {
              icon: '<i style="padding: 0px 0px 19px 3px" class="far fa-layer-group v-icon notranslate v-theme--myCustomLightTheme v-icon--clickable" />',
              index: 4,
              title: "Show years on top of each other",
              click: layerYearsOverEachOther,
            },
          ],
        },
      },
      animations: {
        enabled: false,
      },
    },
    title: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: yearsLayered?.value ? "MMM" : "MMM yyyy",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "100%",
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 2,
    },
    yaxis: [
      {
        title: {
          text: "Searches / month",
        },
        seriesName: "Search volume",
        forceNiceScale: true,
        decimalsInFloat: 0,
        offsetY: 1000,
        min: 0,
      },
    ],
    tooltip: {
      shared: true,
      followCursor: true,
      x: {
        show: true,
        format: yearsLayered?.value ? "MMM" : "MMM yyyy",
        formatter: undefined,
      },
    },
    ...{ annotations },
  };
};
</script>
