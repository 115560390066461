<template>
  <v-card :class="`pa-2 ma-1 flex-grow-1 boksi ${imgName} `" v-if="hero">
    <v-container>
      <img class="type_img" :src="`${publicPath}images/${imgName}.png`" />
      <h4 class="inline">{{ hero }}% {{ title }}</h4>
      <p>{{ description }}</p>
    </v-container>
  </v-card>
</template>
<style scoped>
.type_img {
  /* float: right; */
  display: inline;
  height: 30px;
}

h1,
h4 {
  color: white !important;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  padding-left: 15px;
  margin-right: -15px;
  padding-bottom: 15px;
}

p {
  font-size: 14px;
  color: white;
}

.Evaluatory {
  background: #38026c;
}

.Actional {
  background: #7c50a6;
}

.Unknown {
  background: #7c50a6;
}

.boksi {
  border-radius: 10px;
  margin-bottom: 12px;
  min-height: 100px;
  width: 24%;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CardWithIcon",
  props: {
    hero: Number,
    title: String,
    description: String,
    imgName: String,
    positionNumber: Number,
  },
  data: () => ({
    highlight: false,
    expanded: [] as string[],
    publicPath: process.env.BASE_URL,
  }),
  methods: {
    sentenceCase(text: string) {
      const words = text.split("_");
      const capitalizedWords = [];
      for (const word of words) {
        const characters = word.split("") || [];
        capitalizedWords.push(`${characters.shift()?.toUpperCase()}${characters.join("")}`);
      }
      return capitalizedWords.join(" ");
    },
    positionText() {
      if (this?.positionNumber) {
        const position = this.positionNumber?.toString();
        const lastDigit = position[position.length - 1];
        if ((this.positionNumber < 10 || this.positionNumber > 20) && lastDigit == "1") {
          return `${position}st`;
        } else if ((this.positionNumber < 10 || this.positionNumber > 20) && lastDigit == "2") {
          return `${position}nd`;
        } else if ((this.positionNumber < 10 || this.positionNumber > 20) && lastDigit == "3") {
          return `${position}rd`;
        } else {
          return `${position}th`;
        }
      } else {
        return "";
      }
    },
  },
});
</script>
