<template>
  <div :class="`container ${alternative ? 'alternative' : ''} ${category}`">
    <div class="content" :style="buttonType !== 'compact' ? { '--padding': '10px' } : { '--padding': '0px' }">
      <v-row>
        <v-col cols="12">
          <h3 class="m" v-if="title">{{ title }}</h3>
          <v-row>
            <v-col :cols="imageUrl ? '9' : '12'">
              <p v-if="description">{{ description }}</p>
              <MaireButton :text="buttonText" :link="link" :disabled="!!disabled" />
            </v-col>
            <v-col v-if="imageUrl" cols="3"><img class="card-image" :src="imageUrl" /></v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style scoped>
.container {
  background-color: #38026c;
  position: relative;
  width: 100% !important;
  padding: 12px;
  border-radius: 10px;
}

.content {
  padding: var(--padding);
}

p,
h3 {
  color: white;
  padding-left: 0px;
}
h3 {
  margin-top: -12px;
}

p {
  padding-bottom: 30px;
}

.card-image {
  width: 100%;
}

.alternative {
  background-color: #a344ff;
}
</style>
<script lang="ts">
import MaireButton from "./MaireButton.vue";

export default {
  name: "ButtonBlock",
  props: {
    title: String,
    description: String,
    buttonText: String,
    imageUrl: String,
    link: String,
    disabled: Boolean,
    alternative: Boolean,
    buttonType: String,
    category: String,
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  components: { MaireButton },
};
</script>
