<template>
  <v-row cols="12">
    <v-col cols="12">
      <div class="result_table">
        <ResultRow
          :expandable="isExpandable(result)"
          :category="result.category"
          :text="result.title"
          :prefix="result.type == 'organic' ? result.position.toString() : sentenceCase(result.type)"
          :description="result.url"
          :extra="result.extra"
          :key="result.url"
          :score="result.backlink_score"
          v-for="result in organicResults"
        >
          <v-row cols="12" v-if="result.videos">
            <v-col class="result-row-col" cols="3">
              <p class="wrap">
                Videos are high on the search page suggesting you would rank better if you included video
                content in your piece.
              </p>
              <!-- <h4>1st video published in XXXX</h4>
                            <p class="wrap">It's been XX years since the videos were made. They might contain irrelevant
                                and dated information when they are this old.</p> -->
            </v-col>
            <v-col cols="9" class="boksit d-flex flex-wrap result-row-col">
              <v-card
                class="pa-2 ma-1 flex-grow-1 boksi section"
                :key="video.url"
                v-for="video in result.videos"
              >
                <VideoCard
                  :source="video.url"
                  :title="video.title"
                  :channel="video.source"
                  :timestamp="video.timestamp"
                />
              </v-card>
            </v-col>
          </v-row>
          <v-row cols="12" v-if="result.domain?.includes('www.youtube.com')">
            <v-card class="boksi section">
              <VideoCard
                :source="result.url"
                :title="result.title"
                :channel="result.domain"
                :timestamp="result.timestamp"
              />
            </v-card>
          </v-row>
          <v-row class="result-row-col" cols="12" v-if="result.images">
            <v-col class="result-row-col" cols="3">
              <p class="wrap">
                Images are high on the search page suggesting you would rank better if you included video
                content in your piece.
              </p>
              <!-- <h4>1st image from in XXXX</h4>
                            <p class="wrap">It's been XX years since the videos were made. They might contain irrelevant
                                and dated information when they are this old.</p> -->
            </v-col>
            <v-col cols="9" class="boksit d-flex flex-wrap">
              <div
                class="pa-2 ma-1 flex-grow-1 boksi section thumbnail"
                :key="image.url"
                v-for="image in result.images"
              >
                <ImageCard :image_url="image.image_url" :url="image.url" :alt="image.alt" />
              </div>
            </v-col>
          </v-row>
        </ResultRow>
        <ResultRow
          v-if="relatedSearchesSections?.length > 0"
          classes="related_results"
          prefix="Related searches"
          :expandable="true"
        >
          <v-row class="result-row-col" cols="12">
            <v-col class="result-row-col" cols="4">
              <p class="wrap">This will give you insight into related searches in the same topic.</p>
            </v-col>
            <v-col cols="8">
              <p
                class="related_searches"
                :key="search"
                v-for="search in relatedSearchesSections[0].related_searches"
              >
                <strong>{{ search.link }}</strong
                ><br />
              </p>
            </v-col>
          </v-row>
        </ResultRow>
        <ResultRow
          v-if="peopleAlsoSearch?.length > 0"
          classes="people_also_search"
          prefix="People Also Search"
          icon="mdi:mdi-chevron-down"
          :expandable="true"
        >
          <v-row class="result-row-col">
            <p>People are also searching information in the following topics</p>
            <br />
          </v-row>
          <v-row class="result-row-col" cols="12">
            <v-col
              class="result-row-col"
              :cols="peopleAlsoSearch.length == 1 ? 12 : 6"
              :key="section"
              v-for="section in peopleAlsoSearch"
            >
              <i>
                <h5 class="related_searches_titles">{{ section.title }}</h5>
              </i>
              <p class="related_searches" :key="search" v-for="search in section.items">
                <strong>{{ search.text }}</strong
                ><br />
              </p>
            </v-col>
          </v-row>
        </ResultRow>
      </div>
    </v-col>
  </v-row>
</template>
<style scoped>
.wrap {
  white-space: normal !important;
}

.result-row-col {
  padding-left: 0px;
}

.type_img {
  float: right;
}

.expand_drawer {
  position: relative;
  float: right;
}

.related_searches strong {
  color: white !important;
}

.visible {
  overflow: hidden;
  visibility: visible;
  max-height: 1200px;
}

.hidden {
  overflow: hidden;
  visibility: hidden;
  max-height: 0px;
}

.related_results {
  background: #14ccc9;
}

.people_also_search {
  background-color: #14ccc9;
}

.people_also_search h5 {
  color: #3e54d3;
}

.expand_drawer:hover {
  cursor: pointer;
}

.category {
  position: relative;
  float: right;
  color: white;
  font-size: 10px;
  border: 1px white solid;
  box-shadow: none;
  border-radius: 20px;
  padding: 3px 8px 3px 8px;
}

.website_url {
  height: 100%;
  color: #4fe0b5 !important;
  font-size: 12px;
  line-height: 8px;
  font-family: "Nunito Sans";
}

.result_title_url {
  position: absolute;
  margin-top: -3px;
}

.result_title_url p {
  margin-left: 10px !important;
}

.page_structure_title {
  color: #8200ff;
  margin-top: 20px;
  padding-left: 16px;
}

.result_title strong {
  color: white;
}

.result_table p {
  white-space: nowrap;
  overflow: hidden;
  color: white;
}

.result_table {
  width: 100%;
  padding: 16px;
}

p {
  font-size: 12px;
  color: white;
}

h1,
h4 {
  color: white !important;
}

.inline {
  display: inline;
}

/* 
.Evaluatory {
    background: #38026C
}

.Actional {
    background: #7C50A6;
}

.Illustrative {
    background: #6900CE;
}

.Explanatory {
    background: #8200FF;
}


.Reasoning {
    background: #9323FF;
}

.Statistical {
    background: #A344FF;
}

.Product\/Service {
    background: #BC77FF;
} */

.featured_snippet {
  background: #14ccc9 !important;
}

.boksit {
  flex-direction: row;
}

.boksi {
  border-radius: 0px;
  margin-bottom: 12px;
  min-height: 100px;
  width: 24%;
}

.result {
  margin-bottom: 20px;
}

h6 {
  color: white;
}

.related_searches_titles {
  color: #14ccc9;
  text-decoration: underline;
}

.result_related_searches {
  background: #14ccc9 !important;
}

.video_section {
  background: #3e54d3 !important;
}

.image_section {
  background: #3e54d3 !important;
}

.section {
  background-color: rgba(62, 84, 211, 0.1);
}

.section_purple {
  background-color: rgba(130, 0, 255, 0.1);
}

.thumbnail {
  margin: 0px !important;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";

import ResultRow from "./ResultRow.vue";
import VideoCard from "./VideoCard.vue";
import ImageCard from "./ImageCard.vue";
import { SerpPosition } from "@/types";
import { useStore } from "@/store"

export default defineComponent({
  name: "SerpTable",
  data: () => ({
    highlight: false,
    expanded: [] as string[],
    publicPath: process.env.BASE_URL,
  }),
  setup() {
    const store = useStore();
    return { store };
  },

  methods: {
    sentenceCase(text: string) {
      const words = text.split("_");
      const capitalizedWords = [];
      for (const word of words) {
        const characters = word.split("") || [];
        capitalizedWords.push(`${characters.shift()?.toUpperCase()}${characters.join("")}`);
      }
      return capitalizedWords.join(" ");
    },
    positionText(positionNumber: number) {
      const position = positionNumber.toString();
      const lastDigit = position[position.length - 1];
      if ((positionNumber < 10 || positionNumber > 20) && lastDigit == "1") {
        return `${position}st`;
      } else if ((positionNumber < 10 || positionNumber > 20) && lastDigit == "2") {
        return `${position}nd`;
      } else if ((positionNumber < 10 || positionNumber > 20) && lastDigit == "3") {
        return `${position}rd`;
      } else {
        return `${position}th`;
      }
    },
    expand(section: string) {
      if (this.expanded.includes(section)) {
        this.expanded = this.expanded.filter((val) => val != section);
      } else {
        this.expanded.push(section);
      }
    },
    isExpandable(x: any): boolean {
      return !!["video_section", "image_section"].includes(x.category) || x?.domain?.includes("youtube.com");
    },
  },
  computed: {
    organicResults() {
      const organicResults = this.store.state.keywords.keywordWithDetails?.serp?.organic_results;
      return organicResults?.sort(
        (a: SerpPosition, b: SerpPosition) => (a.position_absolute || 100) - (b.position_absolute || 100)
      );
    },
    otherResults() {
      return this.store.state.keywords.keywordWithDetails?.serp?.other_results?.filter(
        (x: any) => x.type !== "people_also_search" && x.position_absolute <= 10
      );
    },
    imageSections() {
      return this.store.state.keywords.keywordWithDetails?.serp?.image_sections;
    },
    videoSections() {
      return this.store.state.keywords.keywordWithDetails?.serp?.video_sections;
    },
    relatedQuestionsSections() {
      return this.store.state.keywords.keywordWithDetails?.serp?.related_questions_sections;
    },
    relatedSearchesSections() {
      return this.store.state.keywords.keywordWithDetails?.serp?.related_searches_sections;
    },
    peopleAlsoSearch() {
      return this.store.state.keywords.keywordWithDetails?.serp?.other_results?.filter(
        (x: any) => x.type == "people_also_search"
      );
    },
  },
  components: { ResultRow, VideoCard, ImageCard },
});
</script>
