import { Keyword } from "@/store/modules/keywords/types";


export const CONTENT_CATEGORIES: Category[] = [
  {
    category: "Evaluatory",
    cta: "Create a Review",
    shortDescription:
      "Review helps customer to understand the strengths and weaknesses on the option available.",
    description:
      "Maire has reviewed the search result page and we believe that evaluatory content is one of the most effective ways to engage customers who are seeking information with the chosen search term. Evaluatory content helps customer to understand the strengths and weaknesses on the option available.",
    contentType: "Review",
  },
  {
    category: "Illustrative",
    cta: "Create a guide",
    shortDescription:
      "Guide helps customer implement an action, and often responds to “how to?” type of questions",
    description:
      "Maire has reviewed the search result page and we believe that illustrative content is one of the most effective ways to activate customers who are seeking information with the chosen keyword. Illustrative content helps customer implement an action, and often responds to “how to?” type of questions.",
    contentType: "Guide",
  },
  {
    category: "Explanatory",
    cta: "Create informational content",
    shortDescription:
      "Explanatory content helps customer understand the topic they are searching, and often responds to “what is?” type of questions.",
    description:
      "Maire has reviewed the search result page and we believe that explanatory content is one of the most effective ways to engage customers who are seeking information with the chosen keyword. Explanatory content helps customer understand the topic they are searching, and often responds to “what is?” type of questions.",
    contentType: "Explanation",
  },
  {
    category: "Reasoning",
    cta: "Create reasoning content",
    shortDescription:
      "Reasoning content type is used when a customer wants to understand why some topic or function is important.",
    description:
      "Maire has reviewed the search result page and we believe that reasoning is one of the most effective ways to activate customers who are seeking information with the chosen keyword. This content type is used when a customer wants to understand why some topic or function is important.",
    contentType: "Reasoning",
  },
  {
    category: "Statistical",
    cta: "Create statistical content",
    shortDescription:
      "Statistical content type is used when a customer is looking for numerical data or statistics related to the topic.",
    description:
      "Maire has reviewed the search result page and we believe that statistical content is one of the most effective ways to engage customers who are seeking information with the chosen keyword. Statistical content type is used when a customer is looking for numerical data or statistics related to the topic.",
    contentType: "Statistics",
  },
  {
    category: "Product/Service",
    cta: "Create a Product / Service -page",
    shortDescription:
      "This content type is often used when the consumer already knows that they want to buy something. Product/Service page describes the product/service that's being sold.",
    description:
      "Maire has reviewed the search result page and we believe that product/service page is one of the most effective ways to activate customers who are seeking information with the chosen keyword. This content type is often used when the consumer already knows that they want to buy something. Product/Service page describes the product/service that's being sold.",
    contentType: "Product / Service -page",
  },
];

export interface Category {
  category: string;
  cta: string;
  description: string;
  shortDescription: string;
  contentType: string;
  count?: number;
}

export const recommendedCategories = (kw?: Keyword) => {
  if (!kw || !kw.serp) {
    return [];
  }
  const results = kw.serp?.organic_results.filter(
    (result: any) => result.type !== "paid"
  );
  if (results) {
    CONTENT_CATEGORIES.forEach((category: Category) => {
      category.count =
        results.filter(
          (x: { category: string }) =>
            x.category?.split(" ")[0] === category.category?.split(" ")[0]
        ).length * 10;
    });
    return CONTENT_CATEGORIES.filter((ctgr) => ctgr.count && ctgr.count > 0)
      .sort((a, b) => (a.count || 0) - (b.count || 0))
      .reverse();
  } else {
    return [];
  }
};
