import { ActionTree, Module, MutationTree } from "vuex";
import { State as RootState } from "@/store/index";
import { Keyword, KeywordsState } from "./types";
import * as api from "@/api";
import axios from "axios";
import { KeywordLabel, ViewLabel } from "../labels/types";
import { BACKEND_PATH } from "@/helpers";

const KEYWORD_FETCH_ERROR_MSG =
  "Apologies! Something went wrong. Please try to reload the page and try again.";

const state = {
  discoveryKeywords: [],
  keywordWithDetails: undefined,
  triggerRecalculation: false,
};

// Mutations
const mutations: MutationTree<KeywordsState> = {
  setDiscoveryKeywords: (state, keywords: Keyword[]) => {
    state.discoveryKeywords = keywords;
  },
  setTriggerRecalculation: (state, bool: Boolean) => {
    state.triggerRecalculation = bool;
  },
  updateKeywordWithDetailedKeyword: (state, detailedKw: any) => {
    state.discoveryKeywords = state.discoveryKeywords.map((kw) => {
      if (kw.keyword === detailedKw.keyword) {
        return {
          ...kw,
          position: detailedKw.position.length > 0 ? detailedKw.position[0].position : 101,
        };
      } else {
        return kw;
      }
    });
  },
  loadingKeyword: (state, { kw_id, loading }: { kw_id: number; loading: boolean }) => {
    state.discoveryKeywords.forEach((kw) => {
      if (kw.id === kw_id) {
        kw.loading = loading;
      }
    });
  },
  removeLabelFromDiscoveryKeywords: (state, data: { [kwId: string]: number[] }) => {
    state.discoveryKeywords = state.discoveryKeywords.map((kw) => {
      const labelIds = data[kw.id];
      if (labelIds) {
        return {
          ...kw,
          // @ts-ignore
          labels: kw.labels.filter((id: number) => id && !labelIds.includes(id)),
        };
      } else {
        return kw;
      }
    });
  },

  filterKeywords: (state, keywords: string[]) =>
    (state.discoveryKeywords = state.discoveryKeywords.filter((keyword) =>
      keywords.includes(keyword.keyword)
    )),
  setKeywordWithDetails: (state, kw: any) => (state.keywordWithDetails = kw),
  setPrediction: (state, prediction: object[]) => {
    if (state.keywordWithDetails) {
      state.keywordWithDetails.prediction = prediction;
    }
  },
};
// Actions
const actions: ActionTree<KeywordsState, RootState> = {
  async setKeywordLabels(
    { commit },
    data: { id: number; labels: { id: number; text: string; level: number }[] }[]
  ) {
    commit("setKeywordLabels", data);
  },
  async fetchRelatedKeywords({ commit, rootState }) {
    try {
      commit("loading/setLoadingKeywords", true, { root: true });
      const r = await axios.post(`${BACKEND_PATH}/discovery/search_google_keywords`, rootState.userSearch);
      if (r.status === 200) {
        commit("setErrorText", "", { root: true });
        commit("analysis/resetLocalizationFilters", r.data, { root: true });
        commit("setDiscoveryKeywords", r.data);
      }
      commit("loading/setLoadingKeywords", false, { root: true });
    } catch (e) {
      commit("loading/setLoadingKeywords", false, { root: true });
      commit("setErrorText", KEYWORD_FETCH_ERROR_MSG, { root: true });
    }
  },
  async clearKeywordWithDetails({ commit }) {
    commit("setKeywordWithDetails", undefined);
  },
  async getOrCreateKeyword(
    { commit, dispatch },
    params: { keyword: string; location: string; language: string }
  ) {
    commit("isCreatingKeyword", true, { root: true });
    dispatch("clearKeywordWithDetails");
    try {
      const { keyword, location, language } = params;
      const requestParams = {
        keyword,
        location,
        language,
      };
      const keywordResponse = await axios.get(`${BACKEND_PATH}/get_keyword_details`, {
        params: requestParams,
      });
      commit("isCreatingKeyword", false, { root: true });
      if (keywordResponse.status === 200) {
        commit("loading/setLoadingSerp", true, { root: true });
        dispatch("getKeywordDetailsOnly", {
          kw_id: keywordResponse.data[0].id,
          trends: false,
          historical_volume: false,
        });
        commit("setErrorText", "", { root: true });
        dispatch("sendSuccessMessage", "Keyword created successfully!", { root: true });
        if (keywordResponse.data.length > 0) {
          commit("setKeywordWithDetails", keywordResponse.data[0]);
        }
      }
    } catch (e) {
      commit("isCreatingKeyword", false);
      commit("loading/setLoadingKeywords", false, { root: true });
      dispatch("sendErrorMessage", "Keyword creation failed. Please try again later.");
    }
  },
  async getKeywordPredictions({ commit }, { kw_id }: { kw_id: number }) {
    commit("loading/setLoadingPrediction", true, { root: true });
    try {
      const r = await axios.get(`${BACKEND_PATH}/keywords/${kw_id}/predictions`);
      if (r.status === 200) {
        commit("setPrediction", r.data);
      }
    } catch (e: any) {
      commit("setErrorText", "Failed to fetch keyword predictions.", {
        root: true,
      });
    }
    commit("loading/setLoadingPrediction", false, { root: true });
  },
  async getKeywordDetails(
    { commit, dispatch },
    {
      kw_id,
      loadedInTable,
      predictions = false,
      trends = false,
      historical_volume = false,
    }: {
      kw_id: number;
      loadedInTable: boolean;
      predictions: boolean;
      trends: boolean;
      historical_volume: boolean;
    }
  ) {
    return new Promise((resolve, reject) => {
      if (loadedInTable) {
        commit("loadingKeyword", { kw_id, loading: true });
      } else {
        commit("loading/setLoadingKeywords", true, { root: true });
      }
      dispatch("getKeywordDetailsOnly", {
        kw_id,
        trends,
        historical_volume,
      })
        .then((data) => {
          if (predictions) {
            dispatch("getKeywordPredictions", { kw_id });
          }
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  },

  async getKeywordDetailsOnly(
    { commit },
    params: {
      kw_id: number;
      trends: boolean;
      historical_volume: boolean;
    }
  ) {
    try {
      const response = await axios.get(`${BACKEND_PATH}/keywords/${params.kw_id}`, {
        params: {
          trends: params.trends !== undefined ? params.trends : true,
          historical_volume: params.trends !== undefined ? params.trends : true,
        },
      });
      if (response.status === 200) {
        commit("setKeywordWithDetails", response.data);
        commit("updateKeywordWithDetailedKeyword", response.data);
      }
      commit("loadingKeyword", { kw_id: params.kw_id, loading: false });
    } catch (e: any) {
      commit("loadingKeyword", { kw_id: params.kw_id, loading: false });
      commit("setErrorText", "Failed to fetch keyword details. Please try again later.", { root: true });
    }
    commit("loading/setLoadingKeywords", false, { root: true });
    commit("loading/setLoadingSerp", false, { root: true });
  },
  async fetchKwVolumes(_, kwId: number) {
    if (kwId) {
      const r = await api.fetchKwVolumes(kwId);
      return r.data;
    } else {
      return undefined;
    }
  },
  async addLabelToKeywords(
    { commit, rootState },
    params: { kwIds: number[]; label: string; viewId?: number }
  ) {
    const { kwIds, label, viewId } = params;
    if (!kwIds || !label) {
      return;
    }
    const response = await api.addLabelsToKeywords(kwIds, [label]);
    if (response.status === 200) {
      if (viewId) {
        const view = rootState.analysis.views?.[viewId];
        const viewLabels = rootState.labels.viewLabels?.[viewId];
        if (viewLabels) {
          const labelInStructure = viewLabels.find((vl) => vl.text === label);
          if (labelInStructure) {
            commit("setTriggerRecalculation", true);
          }
        }
      }
      commit("setSuccessText", `Added label ${label} to ${kwIds.length} keywords.`, { root: true });
    } else {
      commit("setErrorText", "Failed to add label to keywords.", { root: true });
    }
  },
  async removeLabelFromKeywords({ commit, rootState }, data: { labels: ViewLabel[]; kwIds?: number[] }) {
    const { labels, kwIds } = data;
    if (!kwIds || !labels) return;
    const response = await api.removeLabelFromKeywords(labels, kwIds);
    const kwLabelsMap = response.data.reduce(
      (
        acc: { [kwId: number]: number[] },
        item: { label_id: number; text: string; localized_keyword_id: number }
      ) => {
        if (!acc[item.localized_keyword_id]) {
          acc[item.localized_keyword_id] = [];
        }
        acc[item.localized_keyword_id].push(item.label_id);
        return acc;
      },
      {}
    );
    if (rootState.analysis.currentAnalysis?.id) {
      commit("setTriggerRecalculation", true);
    } else {
      commit("removeLabelFromDiscoveryKeywords", kwLabelsMap);
    }
  },
};

const keywordsModule: Module<KeywordsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default keywordsModule;
