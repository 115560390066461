<template>
  <div id="login_modal" class="login_modal">
    <div class="logo"><img class="maire_logo" :src="`${publicPath}images/maire_logo.png`" /></div>
    <h2>Nice to see you!</h2>
    <p>Login to access all the weird and wonderful things Maire has to offer.</p>
    <v-text-field
      autocomplete="email"
      hide-details="auto"
      v-model="email"
      :error="!!emailError"
      :error-messages="emailError"
      :class="`maire-teal ${!!emailError ? '' : 'bottom-padding-20'}`"
      label="Email"
      density="comfortable"
      variant="outlined"
    />
    <v-text-field
      autocomplete="current-password"
      type="password"
      v-model="password"
      :error="!!passwordError"
      :error-messages="passwordError"
      label="Password"
      density="comfortable"
      variant="outlined"
    />
    <div class="nomargin">
      <v-checkbox v-model="stayLoggedIn" density="compact" color="#8200FF" label="keep me logged in" />
    </div>
    <MaireButton :on-click="validateAndSubmit" class="btn" text="LOGIN" />
    <div class="reset_password_link">
      <a class="maire-link" @click="goToPasswordResetView">Forgot your password?</a>
    </div>
  </div>
</template>

<style scoped>
.reset_password_link {
  text-align: center;
  padding-top: 10px;
}
.maire-link .remove-margin {
  margin-left: -295px !important;
}

.btn {
  width: 100%;
}

.image {
  background-image: v-bind(imageUrl);
  background-size: cover;
  position: fixed;
  right: 0px;
  height: 102vh;
  max-width: 1000px;
  z-index: -1000 !important;
}

.login_modal {
  width: 350px;
  background: white;
  margin: auto;
  margin-top: 20%;
  padding: 40px;
  box-shadow: 0px 0px 20px rgba(130, 0, 255, 0.2);
}

.login_modal h2 {
  font-size: 28px;
  padding-left: 0px;
}

.login_modal p {
  padding: 0px 0px 20px 0px;
}

.login_modal .maire_logo {
  width: 40%;
  padding: 10px 20px;
}

.logo {
  text-align: center;
}

.nomargin {
  margin-left: -4px !important;
  margin-bottom: -10px !important;
}
</style>
<script lang="ts">
import { defineComponent, ref } from "vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  el: "#login_modal",
  name: "LoginForm",
  data: () => ({
    selectedIntent: "Your Best Opportunities",
    publicPath: process.env.BASE_URL,
    stayLoggedIn: false,
    email: "",
    password: "",
    emailError: "",
    passwordError: "",
    imageUrl: ref(`url(${process.env.BASE_URL}images/content_marketing.png)`),
  }),
  mounted() {
    this.$el.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.code === "Enter") {
        this.validateAndSubmit();
      }
    });
  },
  unmounted() {
    this.$el.removeEventListener("keydown", KeyboardEvent);
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  methods: {
    validateAndSubmit() {
      const emailRegex = /.*@[a-zA-Z0-9-]*\.[a-zA-Z]*/g;
      let hasErrors = false;
      if (!this.email) {
        this.emailError = "Please fill out your email";
        hasErrors = true;
      } else if (!this.email.match(emailRegex)) {
        this.emailError = "Not a valid email";
        hasErrors = true;
      } else {
        this.emailError = "";
      }
      if (!this.password) {
        this.passwordError = "Please fill out your password";
        hasErrors = true;
      } else {
        this.passwordError = "";
      }
      if (!hasErrors) {
        this.store.dispatch("login", {
          email: this.email,
          password: this.password,
          stayLoggedIn: this.stayLoggedIn,
        });
      }
    },
    goToPasswordResetView() {
      this.router.push("/reset_password/trigger");
    },
  },
  components: { MaireButton },
});
</script>
