<template>
  <div class="hover-white">
    <span class="dot" :class="fieldValue && toDifficultyString(fieldValue)"></span>
    <p class="inline">
      {{ fieldValue ? toDifficultyString(fieldValue) : "-" }}
      {{ `${fieldValue ? "(" + Math.floor(fieldValue) + ")" : ""}` }}
    </p>
  </div>
</template>

<style scoped>
/* .difficulty_cell {
    font-size: 12px;
    line-height: 12px;
} */
.dot {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: gray;
  display: inline-block;
}

.high {
  background-color: rgb(255, 100, 100);
}

.med {
  background-color: rgb(255, 220, 0);
  white-space: nowrap;
}

.low {
  background-color: rgb(0, 230, 0);
}

p {
  font-size: 10px;
  position: absolute;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

const toDifficultyString = (value: number): string => {
  let difficulty: string;
  if (value > 66) {
    difficulty = "high";
  } else if (value > 33) {
    difficulty = "med";
  } else if (value > 0) {
    difficulty = "low";
  } else {
    difficulty = "-";
  }
  return difficulty;
};

export default defineComponent({
  name: "ActionIcon",
  props: {
    fieldValue: Number,
    field: Object,
  },
  methods: {
    toDifficultyString,
  },
});
</script>
