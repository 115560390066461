
import { defineComponent, ref } from "vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import { useStore } from "@/store"
import { useRoute, useRouter } from "vue-router"


const __default__ = defineComponent({
  el: "#login_modal",
  name: "LoginForm",
  data: () => ({
    publicPath: process.env.BASE_URL,
    stayLoggedIn: false,
    email: "",
    password: "",
    passwordConfirm: "",
    emailError: "",
    passwordError: "",
    passwordConfirmError: "",
    imageUrl: ref(`url(${process.env.BASE_URL}images/content_marketing.png)`),
  }),
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  mounted() {
    this.$el.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.code === "Enter") {
        this.validateAndSubmit();
      }
    });
  },
  unmounted() {
    this.$el.removeEventListener("keydown", KeyboardEvent);
  },
  methods: {
    validateAndSubmit() {
      let hasErrors = false;
      if (!this.password) {
        this.passwordError = "Please fill out your password";
        hasErrors = true;
      } else {
        this.passwordError = "";
      }
      if (this.password !== this.passwordConfirm) {
        hasErrors = true;
        this.passwordConfirmError = "Passwords don't match";
      } else {
        this.passwordConfirmError = "";
      }
      if (!hasErrors) {
        // @ts-ignore
        this.store.dispatch("change_password", {
          token: this.route.params.token,
          new_password: this.password,
        });
      }
    },
  },
  components: { MaireButton },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "ac401d68": (_ctx.imageUrl)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__