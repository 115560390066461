<template>
  <div class="container">
    <div class="animation" v-if="animationData">
      <Vue3Lottie
        :animation-data="animationData"
        :auto-play="true"
        :loop="true"
        :speed="1"
        ref="anim"
        :width="props.width"
      />
    </div>
    <p>{{ message ? message : "We are getting your keywords ready" }}</p>
  </div>
</template>
<style scoped>
.container {
  width: 140px;
  height: 100%;
  margin: auto;
}

.animation {
  padding: 20px;
}
svg {
  width: 50px;
  padding: 20px;
}

p {
  text-align: center;
}
</style>

<script setup lang="ts">
import { defineProps } from "vue";
import waitRegular from "@/assets/wait.json";
import waitSingleColor from "@/assets/wait2.json";
const props = defineProps({
  singleColor: Boolean,
  message: String,
  width: { type: Number, required: false, default: 90 },
  height: { type: Number, required: false, default: 90 },
});
const animationData = props.singleColor ? waitSingleColor : waitRegular;
</script>
