<template>
  <span
    :class="`trending ${trending > 66 ? `high` : trending > 33 ? `medium` : trending > 0 ? `low` : ''}`"
    >{{ trending }}</span
  >
</template>
<style scoped>
.trending::after {
  content: "%";
}

.high::before {
  content: "🚀 ";
}

.medium::before {
  content: "🔥 ";
}

.low::before {
  content: "📈 ";
}

.dot {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: gray;
  display: inline-block;
}

.hard {
  background-color: rgb(255, 100, 100);
}

.moderate {
  background-color: rgb(255, 220, 0);
  white-space: nowrap;
}

.easy {
  background-color: rgb(0, 230, 0);
}

p {
  font-size: 10px;
  position: absolute;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TrendingCell",
  props: {
    fieldValue: Number,
    field: Object,
  },
  computed: {
    trending() {
      return this.fieldValue || 0;
    },
  },
});
</script>
