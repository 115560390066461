<template>
  <div class="label_cell inputs">
    <div
      v-for="analysis in analyses.slice(0, noOfVisible)"
      :key="analysis.name"
      class="category-input-field inline"
    >
      <!-- <li @click="handleAnalysisClick(analysis.id)" class="pointer label link">{{ analysis.name }}</li> -->
      <v-chip
        :style="{ 'background-color': 'rgb(var(--v-theme-mairePurpleSecondary)', color: 'white' }"
        v-if="true"
        class="label"
      >
        <MaireTooltip>
          <template #trigger>
            <span
              class="text-truncate label"
              style="max-width: 50px; display: block"
              @mouseenter="tooltipVisible = analysis.id"
              @mouseleave="tooltipVisible = undefined"
              @click="handleAnalysisClick(analysis.id)"
            >
              {{ analysis.name }}
            </span>
          </template>
          <template #content>
            <strong>{{ analysis.name }}</strong>
            <p>{{ analysis.description }}</p>
          </template>
        </MaireTooltip>
      </v-chip>
    </div>
    <div v-if="analyses.length > noOfVisible" style="display: inline-block; margin-left: 12px">
      <MaireTooltip :follow="false">
        <template #trigger>
          <span class="xs"> + {{ analyses.length - noOfVisible }} </span>
        </template>
        <template #content>
          <li
            v-for="analysis in analyses.slice(noOfVisible, analyses.length)"
            :key="analysis.id"
            @click="handleAnalysisClick(analysis.id)"
            class="pointer label link"
          >
            {{ analysis.name }}
          </li>
        </template>
      </MaireTooltip>
    </div>
  </div>
</template>
<style scoped>
.link {
  user-select: none;
  overflow: hidden;
}
.plusIcon {
  color: rgba(var(--v-theme-mairePurple)) !important;
  border-radius: 25px;
  margin: 5px;
  padding: 0px;
  text-align: center;
  outline: 2px solid rgba(var(--v-theme-mairePurple));
}
.labelInput {
  margin-top: 10px;
}

.label span {
  max-width: 50px;
}
.label {
  cursor: pointer;
  position: relative;
}
.danger {
  background-color: red !important;
  color: white !important;
  border-style: none;
}
.inverse {
  background-color: rgba(var(--v-theme-mairePurpleSecondary)) !important;
}

.danger:hover {
  border-style: none;
  color: white !important;
}

.label {
  margin: 2px;
  /* white-space: wrap;
  max-width: 5; */
}

p {
  color: white;
  font-size: 12px;
}
</style>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { useStore } from "@/store";
import { useLabelColor } from "@/composables/useLabelColor";
import MaireTooltip from "@/components/MaireTooltip/MaireTooltip.vue";
import { Analysis } from "@/store/modules/analysis/types";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "LabelCell",
  data: () => ({
    modalOpen: false,
    tooltipVisible: undefined as number | undefined,
    noOfVisible: 3,
    creatingNew: false,
    newLabel: "",
  }),
  setup() {
    const store = useStore();
    const { getLabelColor } = useLabelColor();
    const router = useRouter();
    return { store, getLabelColor, router };
  },
  props: {
    fieldValue: Array as PropType<Analysis[]>,
    field: Object,
    item: Object,
    viewId: { type: Number, required: false },
  },

  computed: {
    currentAnalysis() {
      return this.store.state.analysis.currentAnalysis;
    },
    companyAnalyses() {
      return Object.fromEntries(this.store.state.analysis.analyses.map((af) => [af.id, af]));
    },
    companyUsers() {
      return this.store.state.company.active_users ?? [];
    },
    analyses() {
      const analyses = this.fieldValue?.map((lbl: Analysis) => {
        if (typeof lbl === "number") {
          return this.companyAnalyses?.[lbl as unknown as number];
        } else {
          return lbl;
        }
      });
      // @ts-ignore
      return analyses?.filter((af) => af?.name) ?? [];
    },
  },
  methods: {
    handleAnalysisClick(id: number) {
      this.router.push(`/analysis/${id}`);
    },
  },
  components: {
    MaireTooltip,
  },
});
</script>
