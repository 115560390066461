import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!

  return (_openBlock(), _createBlock(_component_v_progress_linear, {
    height: "25",
    "rounded-bar": "",
    color: "#14CCC9",
    "model-value": _ctx.fieldValue > 1 ? 1 : _ctx.fieldValue,
    max: "1"
  }, null, 8, ["model-value"]))
}