<template>
  <div>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link href="https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Nunito Sans:wght@400;800" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Plus Jakarta Sans" rel="stylesheet" />
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
    <v-card>
      <v-layout>
        <v-navigation-drawer
          railWidth="72"
          v-if="user.email"
          id="navbar"
          theme="drawerTheme"
          permanent
          :rail="rail"
        >
          <div
            :class="`drawer-open-button  ${rail ? 'nav-btn-open' : 'nav-btn-close'}`"
            @click.stop="handleNavRail"
          >
            <div style="height: 100%" icon>
              <v-icon style="height: 100%">{{
                !rail ? "mdi:mdi-chevron-left" : "mdi:mdi-chevron-right"
              }}</v-icon>
            </div>
          </div>
          <v-list-item
            id="user-avatar"
            nav
            :prepend-avatar="user.avatar"
            :title="`${user.firstName} ${user.lastName}`"
          />
          <v-list>
            <v-list-item v-if="user.impersonator" density="compact">
              <p style="font-size: 8px"><v-icon>mdi:mdi-incognito</v-icon>{{ user.impersonator }}</p>
            </v-list-item>
          </v-list>
          <v-list density="comfortable" nav id="main-navbar">
            <span :key="name" v-for="{ name, icon, link, analyses } in links">
              <v-list-item
                v-if="!analyses"
                :prepend-icon="icon"
                :title="name"
                :to="link"
                :key="name"
                density="compact"
              ></v-list-item>
              <div
                v-else
                id="analysis-section"
                :class="route.path.includes('/analysis') || analysisExpanded ? 'light-purple-background' : ''"
              >
                <v-list-item
                  :active="route.path.includes('/analysis')"
                  :prepend-icon="icon"
                  :title="name"
                  @click="toggleAnalysisSection"
                  :key="name"
                ></v-list-item>
                <v-list v-if="analysisExpanded" class="list-no-margin">
                  <v-list-item
                    v-if="userIsNotViewer"
                    title="Discovery"
                    to="/keywords"
                    density="compact"
                    class="smaller-font"
                  />
                  <v-list-item @click="handleMySavedAnalysisClick" density="compact" class="smaller-font">
                    <v-list-item-title>My Saved Analysis</v-list-item-title>
                    <template v-slot:append>
                      <v-icon class="analysis-view-toggle-icon">{{
                        showAnalysisList ? "fa-regular fa-caret-up" : "fa-regular fa-caret-down"
                      }}</v-icon>
                    </template>
                  </v-list-item>
                  <v-list v-if="analysisExpanded && showAnalysisList" class="analysis-nav-list">
                    <div v-for="{ name, link, views } in analyses" :key="name" density="compact">
                      <v-list-item
                        v-if="!views?.length"
                        :to="link"
                        :title="name"
                        density="compact"
                        class="smaller-font"
                      />
                      <div v-else class="item">
                        <v-list-item
                          @click="() => toggleAnalysis(name)"
                          density="compact"
                          :active="route.fullPath.includes(link)"
                          :class="`smaller-font ${expandedAnalysis == name ? 'expanded' : ''}`"
                        >
                          <v-list-item-title>{{ name }}</v-list-item-title>
                          <template v-slot:append>
                            <v-icon class="analysis-view-toggle-icon inline">{{
                              expandedAnalysis == name ? "fa-regular fa-caret-up" : "fa-regular fa-caret-down"
                            }}</v-icon>
                          </template>
                        </v-list-item>
                        <v-list
                          id="viewlist"
                          :class="`list-no-margin ${expandedAnalysis == name ? 'expanded' : ''}`"
                          v-if="expandedAnalysis === name"
                        >
                          <v-list-item
                            :title="`• ${name}`"
                            v-for="{ name, link } in views"
                            :to="link"
                            :key="name"
                            class="smaller-font"
                            :class="{ 'active-item': isActive(link) }"
                            density="compact"
                          ></v-list-item>
                        </v-list>
                      </div>
                    </div>
                  </v-list>
                  <v-list-item
                    v-if="userIsAdmin"
                    title="Label Management"
                    to="/labels"
                    density="compact"
                    class="smaller-font"
                  />
                </v-list>
              </div>
            </span>
          </v-list>
          <v-list v-if="user.email" density="comfortable" nav id="logout">
            <v-list-item prepend-icon="fa-regular fa-sign-out" title="Log out" @click="logout"></v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-main :class="`main_content ${fullScreenRoute ? '' : 'bg'}`" style="min-height: 100vh">
          <div id="app_wait_animation" v-if="loadingApp">
            <WaitAnimation message="Loading the essentials" />
          </div>
          <Transition name="notification">
            <MaireNotification
              v-if="errorMessage"
              type="error"
              :handle-click="clearError"
              :message="errorMessage"
            />
          </Transition>
          <Transition name="notification">
            <MaireNotification
              v-if="successMessage"
              type="success"
              :handle-click="clearSuccess"
              :message="successMessage"
            />
          </Transition>
          <div
            class="router-view"
            :style="rightNavbarOpen ? { '--padding': '350px' } : { '--padding': '0px' }"
          >
            <ConsentModal v-if="user.email && !user.consent && !user.impersonator" />
            <router-view v-if="(user.email && user.consent) || fullScreenRoute || user.impersonator" />
          </div>
        </v-main>
      </v-layout>
    </v-card>
  </div>
</template>
<style>
.noevents {
  pointer-events: none;
}
#main-navbar {
  overflow: hidden;
}
#logout {
  overflow: hidden;
}
.router-view {
  transition: padding 0.3s ease-in-out;
  padding-right: var(--padding);
}
.chip {
  border-radius: 4px;
  padding: 2px 8px;
  margin-left: 12px;
  display: inline-block;
}
.google-only {
  background-color: rgba(var(--v-theme-info), 1);
  color: white;
}
.shared-by-chip {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 1);
  color: white;
}
.shared-by-chip i {
  margin-right: 0px;
  font-size: 18px;
}
.tableBody .v-input__details {
  display: none;
}
.wait-animation {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  padding-top: 20%;
  width: 100%;
  height: 100%;
}
.strong {
  font-weight: 800;
}
.pointer {
  cursor: pointer;
}
#app_wait_animation {
  background-color: white;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  padding-top: 20%;
  width: 100%;
  height: 100%;
}
#navbar {
  /* font-size: 14px; */
  padding-left: 8px !important;
}

.drawer-open-button {
  position: absolute;
  top: 40%;
  right: -11px;
  width: 11px !important;
  background-color: rgb(var(--v-theme-mairePurpleSecondary));
  color: white;
  height: 60px;
  cursor: pointer;
  transition: border-radius 0.3s ease, right 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-btn-open {
  right: -11px;
  border-radius: 0% 8px 8px 0%;
  z-index: 2;
}

.nav-btn-close {
  right: 0px;
  border-radius: 8px 0% 0% 8px;
  z-index: 2;
}

.v-label {
  font-family: "Nunito Sans", sans-serif !important;
}

.notification-leave-active,
.notification-enter-active {
  transition: all 0.3s ease-in-out;
}

.notification-enter-from,
.notification-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

.bottom-padding-20 {
  padding-bottom: 20px;
}

.maire-link {
  color: #8200ff;
  cursor: pointer;
}

.v-breadcrumbs-item--disabled {
  font-weight: bolder;
  opacity: 1;
  color: rgb(var(--v-theme-maireDarkBlue));
}

.v-field--variant-outlined {
  color: rgba(41, 54, 87, 0.5);
}

.inputs .v-field--variant-outlined input {
  color: white;
}

.inputs.light .v-field--variant-outlined input,
.inputs .v-autocomplete__selection-text {
  color: rgb(var(--v-theme-maireDarkBlue));
}
.inputs .v-autocomplete input {
  color: black !important;
}
.inputs .v-field__prepend-inner {
  margin-right: 8px;
}
.inputs .v-autocomplete__selection {
  color: white;
}
.inputs.label-name-input input {
  color: rgb(var(--v-theme-maireDarkBlue));
}
.inputs.dark .v-autocomplete__selection,
.inputs.dark .v-field--variant-outlined input {
  color: black;
}

.inputs .v-field-label {
  color: rgb(var(--v-theme-maireTeal));
}

.inputs i {
  color: rgb(var(--v-theme-maireTeal));
  opacity: 0.5;
}

#action-bar .add-level-button i {
  color: rgba(var(--v-theme-mairePurpleSecondary), 1);
  opacity: 1;
}

.inputs i.fa-regular {
  color: rgb(var(--v-theme-maireTeal));
  opacity: 0.5;
}

.inputs .v-field__prepend-inner > .v-icon {
  opacity: 1;
}
.inputs:not(.nopadding) .v-field {
  padding: 4px 2px 4px 12px;
}

.inputs .v-field__input,
.inputs .v-field-label {
  font-size: 0.8em;
}

.inputs input {
  font-size: 14px;
}
.rule-chip .v-selection-control--density-default {
  --v-selection-control-size: inherit;
  margin-right: 4px;
  font-size: 10px;
}
#label-view input.v-field__input {
  background-color: white;
  border-radius: 10px;
}
#action-bar label,
#action-bar input {
  color: rgb(var(--v-theme-grey));
}

#action-bar .v-autocomplete__selection {
  display: none;
}

.centeredIcon {
  border-radius: 25px;
  margin: 5px;
  padding: 0px;
  text-align: center;
  outline-offset: 0.1em;
}

.inputs .v-field--variant-outlined .v-field__outline__start {
  border-radius: 10px 0px 0px 10px;
}
.inputs .v-field--variant-outlined .v-field__outline__end {
  border-radius: 0px 10px 10px 0px;
}
.search_container .v-field--variant-outlined .v-field__outline__start {
  border-radius: 8px 0px 0px 8px;
}
.search_container .v-field--variant-outlined .v-field__outline__end {
  border-radius: 0px 8px 8px 0px;
}

.search_container .v-input__control {
  border-radius: 8px;
  background-color: white;
}

.search_bar .v-input__control input {
  color: rgb(var(--v-theme-maireDarkBlue));
}
.search_bar .v-field__clearable i.fa-times-circle {
  color: rgb(var(--v-theme-maireDarkBlue)) !important;
}

.performance_table .cell-keyword {
  max-width: 150px;
}
.cell-labels p {
  color: rgb(var(--v-theme-maireDarkBlue)) !important;
}
.v-field--variant-outlined .v-field__outline__start,
.v-field--variant-outlined .v-field__outline__notch::before,
.v-field--variant-outlined .v-field__outline__notch::after,
.v-field--variant-outlined .v-field__outline__end {
  /* border: 2px #14CCC9 solid !important; */
  color: #14ccc9;
}

.slider .v-field--variant-outlined .v-field__outline__start {
  display: none;
}
.slider .v-field--variant-outlined .v-field__outline__end {
  border-style: none;
  border-radius: 10px;
  border: 1px solid rgb(var(--v-theme-maireTeal));
}
/**show underline */
.slider input {
  color: black;
  text-align: center;
  font-size: 12px;
}
.slider .v-field .padding-start,
.slider .v-field .padding-end,
.slider .v-field .padding-top,
.slider .v-field .padding-bottom {
  padding: 0px !important;
}
.slider .v-input__details {
  display: none !important;
}

/* hide input number arrows on chrome */
.slider input::-webkit-outer-spin-button,
.slider input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.slider input[type="number"] {
  -moz-appearance: textfield;
}
.slider .v-slider.v-input--horizontal {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.v-chip__underlay {
  background: rgba(0, 0, 0, 0) !important;
}

.v-chip__close i {
  color: rgba(255, 255, 255, 0.7) !important;
}

.paper-background {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.paper-background-no-bg {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.padding-sm {
  padding: 12px;
}

.padding-med {
  padding: 24px;
}

.padding-lg {
  padding: 36px;
}

.maire-drop-lg {
  padding: 36px;
}

.maire-drop-shadow {
  box-shadow: 0px 0px 20px rgba(var(--v-theme-mairePurpleSecondary), 0.07);
}

.maire-drop-shadow-intense {
  box-shadow: 0em 0em 1em rgba(var(--v-theme-mairePurpleSecondary), 0.3);
}

.nopadding {
  padding: 0px !important;
}
.mairePurpleSecondary {
  color: rgb(var(--v-theme-mairePurpleSecondary));
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

.float-right {
  position: relative;
  float: right;
  right: 0px !important;
}

.float-right button {
  float: right;
}

.maire-violet {
  color: #b500ff;
  opacity: 35%;
}

.maire-purple {
  color: #8200ff;
}

.maire-dark-blue {
  color: #293657;
}

.maire-blue {
  color: rgb(var(--v-theme-maireDarkBlue));
}

.maire-teal {
  color: #14ccc9;
}

.maire-pink {
  color: #ff73ff;
}

.maire-secondary-yellow {
  color: #ffd100;
}

.maire-secondary-ocean {
  color: #4fe0b5;
}

.maire-secondary-pink {
  color: #e500ff;
}

.maire-secondary-violet {
  color: #b500ff;
}

.maire-background-light {
  background-color: #ffffff;
}

.maire-background-light-2 {
  background-color: #f4f5f6;
}

.maire-background-dark {
  background-color: #293657;
}

.maire-background-primary-purple {
  background-color: #f8f2ff;
}

.maire-background-primary-pink {
  background-color: #fff8ff;
}

.maire-text-light {
  color: #ffffff;
}

.maire-text-dark {
  color: #232323;
}

.maire-text-highlight {
  color: #8200ff;
}

.maire-text-link {
  color: #e500ff;
}

.plain-title {
  color: #8200ff;
  font-size: 14px;
  line-height: 24px;
}

.plain-btn {
  border: 1px #b500ff solid;
  color: #b500ff !important;
  cursor: pointer;
  border-radius: 0px;
  margin-bottom: 20px;
  margin-left: 12px;
}

.plain-btn:hover {
  color: #8200ff !important;
  border: 1px #8200ff solid;
}

.plain-btn.inverse {
  color: white !important;
}

.plain-btn img {
  margin-bottom: -2px;
  padding-right: 5px;
}

.Evaluatory {
  background: #38026c !important;
}

.Actional {
  background: rgb(124, 80, 166) !important;
}

.Illustrative {
  background: rgb(105, 0, 206) !important;
}

.Explanatory {
  background: rgb(130, 0, 255) !important;
}

.Reasoning {
  background: rgb(147, 35, 255) !important;
}

.Statistical {
  background: rgb(163, 68, 255) !important;
}

.Product\/Service {
  background: rgb(188, 119, 255) !important;
}

.Product {
  background: rgb(188, 119, 255) !important;
}

.inline {
  display: inline;
}

h1,
h2,
h3 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: regular;
}

h3 {
  font-family: "Nunito Sans", sans-serif;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0px;
  text-align: left;
}

strong {
  color: rgb(41, 54, 87);
}

p {
  /* styleName: Maire/body; */
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: regular;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgb(41, 54, 87);
}
h1 {
  font-size: 38px;
  color: rgb(41, 54, 87);
}
h2 {
  font-size: 32px;
  font-family: "Nunito Sans", sans-serif;
  color: rgb(41, 54, 87);
  line-height: 34px;
  font-weight: 800;
  padding: 12px;
}
input {
  font-family: "Nunito Sans" !important;
}
.l {
  font-size: 36px;
  font-family: "Nunito Sans", extrabold;
}

.m {
  font-size: 20px;
  font-family: "Nunito Sans", extrabold;
}

.s {
  font-size: 16px;
  font-family: "Nunito Sans", extrabold;
}

.xs {
  font-size: 14px;
  font-family: "Nunito Sans", bold;
}

.xxs {
  font-size: 10px;
  font-family: "Nunito Sans", bold;
}

.bg {
  background-color: #f7f7f7;
}

.v-list-item {
  margin-top: 10px !important;
  padding: 12px;
  min-height: 0px I !important;
}
.v-list-item--density-compact.v-list-item--one-line {
  min-height: 32px;
}
.v-list-item--one-line {
  min-height: 32px;
}
.v-list-item--density-comfortable.v-list-item--one-line {
  min-height: 32px;
}

/* 
.v-list-item i {
  margin-right: 10px;
} */

.v-list-item--nav .v-list-item-title {
  font-weight: 400;
  line-height: 12px;
}

.v-list-item--nav .v-list-item--density-compact.v-list-item--one-line {
  min-height: 10px !important;
}

.smaller-font .v-list-item-title {
  font-size: 12px !important;
  line-height: 12px !important;
}
.v-list-item--nav i {
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
}
.analysis-nav-list {
  position: relative;
  max-height: 40vh;
  overflow: scroll;
  scrollbar-width: none;
}
.analysis-view-toggle-icon {
  position: relative;
  display: inline;
  float: right;
  margin: auto;
  margin-right: -12px;
  padding: 10px 16px;
  border-radius: 36px;
  font-size: 12px;
  transition: background-color 0.3s ease-in-out;
}
.analysis-view-toggle-icon:hover {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.2);
}
i.analysis-view-toggle-icon {
  text-align: center;
  display: table;
}
.v-list-item--active {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.05);
  color: rgb(var(--v-theme-mairePurpleSecondary));
  --v-theme-overlay-multiplier: 0;
}

.light-purple-background {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.05) !important;
}
#navbar .v-list-item--active {
  /* Neutralize default active styles */
  background-color: transparent !important; /* Or any other style reset */
  box-shadow: none !important; /* Or any other style reset */
  color: inherit !important; /* Or any other style reset */
}

#navbar .v-list {
  background-color: transparent;
}

#navbar .active-item {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.05);
  color: rgb(var(--v-theme-mairePurpleSecondary)) !important;
}

#viewlist .v-list-item--active div {
  color: inherit !important;
}

#navbar .list-no-margin {
  margin-left: 12px;
  margin-top: -12px;
}

#navbar .item:has(.expanded) {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.05);
}

.v-list-item--active div,
.v-list-item--active i {
  color: rgb(var(--v-theme-mairePurpleSecondary));
}

.v-list-item:hover {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.05);
  --v-theme-overlay-multiplier: 0;
}

#user-avatar.v-list-item:hover {
  background-color: rgba(0, 0, 0, 0);
  --v-theme-overlay-multiplier: 0;
}

.v-label {
  opacity: 100 !important;
}

.mdi:mdi-radiobox-blank::before {
  content: "\F043D";
  color: rgb(var(--v-theme-mairePurpleSecondary));
}

.mdi:mdi-radiobox-marked::before {
  content: "\F043E";
  color: rgb(var(--v-theme-mairePurpleSecondary));
}

/* .v-navigation-drawer__content {
  padding: 20px !important;
} */

.v-navigation-drawer {
  border: none;
  box-shadow: inset 0px 0px 40px rgba(130, 0, 255, 0.05);
  padding-top: 50px !important;
}
.v-navigation-drawer .v-list {
  overflow: auto;
}

textarea#title-ghost {
  padding: 12px 12px 12px 0px !important;
  overflow: visible;
  padding-bottom: 34px !important;
  margin-bottom: -34px;
}

#title-ghost-h2 {
  font-size: 24px;
  font-family: "Nunito Sans", sans-serif;
  color: rgb(41, 54, 87);
  line-height: 24px;
  font-weight: 800;
  padding: 12px;
  padding-left: 0px !important;
  margin-bottom: -54px;
  padding-bottom: -20px !important;
}

#title-ghost-h2 .v-input__details {
  display: none;
}

#maire-tab .content .v-input__details {
  display: none;
}

#titleselector-radio-group-container .v-input__details {
  display: none;
}

/* #titleselector-radio-group-container .v-icon--size-default {
  font-size: calc(var(--v-icon-size-multiplier) * 0.75em);
}

#titleselector-radio-group-container .v-label {
  font-size: 0.75rem;
} */
#title-ghost-h3 {
  font-size: 18px;
  font-family: "Nunito Sans", sans-serif;
  color: rgb(41, 54, 87);
  line-height: 20px;
  font-weight: 600;
  padding: 12px 0px 0px 0px !important;
  width: 100%;
  outline: none;
  resize: none;
  /* padding: 12px 30px -20px 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: -50px !important; */
}

.structure {
  font-family: "Nunito Sans", sans-serif;
  color: rgb(41, 54, 87);
  line-height: 34px;
  padding: 12px;
}

.title-h2 {
  font-size: 32px;
  line-height: 34px;
  font-weight: 800;
}

.title-h3 {
  font-size: 24px !important;
  line-height: 54px !important;
  letter-spacing: 0px !important;
}

.abstract textarea {
  margin-bottom: -20px !important;
}

.v-container {
  max-width: 100%;
}

.debug_buttons {
  position: absolute;
  right: 0px;
  bottom: 30px;
}

#split-view-container textarea,
#split-view-container h3 {
  padding-right: 40px;
}

.structure textarea {
  padding-top: 10px !important;
  padding-bottom: 30px !important;
  font-family: Nunito Sans;
  color: rgb(41, 54, 87);
  letter-spacing: 0.01em;
  font-size: 14px;
}

.structure .v-input__details {
  display: none;
}

.abstract textarea {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-family: Nunito Sans;
  color: rgb(41, 54, 87);
  letter-spacing: 0.01em;
  font-size: 14px;
}

.logout-button {
  font-size: 12px;
  height: 20px !important;
  padding-top: 30px;
  padding-left: 24px;
  cursor: pointer;
}

.apexcharts-zoom-icon.apexcharts-selected svg {
  fill: #293657 !important;
}

.apexcharts-pan-icon.apexcharts-selected svg {
  stroke: #293657 !important;
}

.apexcharts-tooltip-title {
  background-color: white !important;
}

.apexcharts-xaxistooltip {
  visibility: hidden;
}

/* these are to make the legends for apex charts inline. Default is one per row (ugly) */
.category-pie .apexcharts-legend.apx-legend-position-left {
  flex-direction: row !important;
  position: relative !important;
  flex-wrap: wrap !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.category-pie .apexcharts-legend-series {
  display: inline-block !important;
  min-width: 0px !important;
  margin-top: 12px !important;
  margin-right: 12px !important;
}

.apexcharts-legend {
  max-height: 100%; /* adjust accordingly to your requirements */
  overflow-y: auto; /* to make it scrollable */
}
div[seriesname="Impressions"] span {
  font-size: 12px !important;
  padding: 8px 15px !important;
  color: white !important;
  border-radius: 5px;
  text-align: center;
  background-color: rgba(var(--v-theme-maireTeal), 0.6) !important;
}
div[seriesname="Impressions"] span {
  color: white !important;
}

div[seriesname="Impressions"] .apexcharts-legend-marker,
div[seriesname="Clicks"] .apexcharts-legend-marker {
  display: none !important;
}
div[seriesname="Clicks"] span {
  font-size: 12px !important;
  padding: 8px 15px;
  margin-left: 0px;
  color: white !important;
  border-radius: 5px;
  text-align: center;
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.6) !important;
}
div[seriesname="Clicks"] span {
  color: white !important;
}

.assistant p,
.assistant h1,
.assistant h2,
.assistant h3,
.assistant h4 {
  color: white;
  padding-left: 0px;
}

.assistant .message-content {
  padding-left: 0px !important;
}

.assistant .message-content img {
  max-width: 30%;
}

.assistant .message-content h1 {
  font-size: 1.2rem;
  margin-bottom: -20px;
}

.assistant .message-content h2 {
  font-size: 1rem;
  margin-bottom: -20px;
}

.assistant .message-content h3 {
  font-size: 1rem;
}

.assistant .message-content h4 {
  font-size: 0.8rem;
  font-weight: 800;
}

.assistant .message-content ul,
.assistant ol {
  line-height: 100% !important;
}

.message-content {
  margin-bottom: -20px;
  font-family: "Nunito Sans", sans-serif;
}

.message-content pre > code {
  white-space: pre-wrap;
}
#search-widget .apexcharts-bar-area:hover {
  fill: rgba(var(--v-theme-maireTeal), 0.5);
}

.item-row:hover strong,
.item-row:hover i,
.item-row:hover p {
  color: white;
}
.item-row:hover .variant-tooltip {
  display: block !important;
}
.trending-checkboxes .v-input__control {
  height: 40px !important;
  font-size: 14px;
}
.trending-checkboxes label {
  font-size: 14px;
}
.maire-scrollbar {
  max-height: 100%;
  padding: 12px;
  overflow: scroll;
  scrollbar-width: thin; /* 'auto' or 'thin' */
  scrollbar-color: rgb(var(--v-theme-mairePurple)) transparent; /* color of the thumb and track */
}

/* For Webkit (Chrome, Safari, Edge) */
.maire-scrollbar::-webkit-scrollbar {
  width: 10px; /* Adjust the width of the scrollbar */
  border-radius: 5px; /* Round the corners of the scrollbar */
}

.maire-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Track background */
  border-radius: 5px;
}

.maire-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(var(--v-theme-mairePurple)); /* Your desired shade of purple */
  border-radius: 5px; /* Round the corners of the scrollbar thumb */
}

.maire-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgb(var(--v-theme-mairePurple)); /* Your desired shade of purple */
}
.v-color-picker-edit__input span {
  color: rgb(var(--v-theme-maireDarkBlue));
}
.v-color-picker-edit button {
  color: rgb(var(--v-theme-maireDarkBlue));
}
@media (max-width: 1400px) {
  p,
  span,
  div {
    font-size: 10px;
  }
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  .l {
    font-size: 32px;
    font-family: "Nunito Sans", extrabold;
  }

  .m {
    font-size: 16px;
    font-family: "Nunito Sans", extrabold;
  }

  .s {
    font-size: 12px;
    font-family: "Nunito Sans", extrabold;
  }

  .xs {
    font-size: 8px;
    font-family: "Nunito Sans", bold;
  }

  .xxs {
    font-size: 6px;
    font-family: "Nunito Sans", bold;
  }
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import MaireNotification from "./components/MaireNotification/MaireNotification.vue";
import ConsentModal from "./components/ConsentModal/ConsentModal.vue";
import WaitAnimation from "./components/WaitAnimation/WaitAnimation.vue";
import { useStore } from "@/store";
import { useRoute, useRouter } from "vue-router";

type LinkData = {
  name: string;
  icon: string;
  link: string;
  analyses?: Array<any>;
};

export default defineComponent({
  data: () => ({
    rail: true,
    analysisExpanded: false,
    expandedAnalysis: "",
    showAnalysisList: false,
  }),
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  mounted() {
    if (this.route?.fullPath !== "/") {
      this.store.dispatch("setup");
    }
  },
  computed: {
    user() {
      return this.store.state.user;
    },
    userIsNotViewer() {
      return this.user?.type !== "viewer";
    },
    userIsAdmin() {
      return this.user?.type.includes("admin");
    },
    views() {
      return this.store.state.analysis.views;
    },
    savedFilters() {
      return this.store.state.analysis.analyses;
    },
    loadingApp() {
      return this.store.state.loading.loadingApp;
    },
    links() {
      const links: Array<LinkData> = [
        {
          name: "Analyses",
          icon: "fa-regular fa-key-skeleton",
          link: "/analysis",
          analyses: [...this.savedFilters]
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((filter) => ({
              name: filter.name,
              link: `/analysis/${filter.id}`,
              views: [...filter.views]
                .sort((a, b) => a.name.localeCompare(b.name))
                ?.map((view) => {
                  return {
                    name: view?.name,
                    link: `/analysis/${filter.id}?view=${view.id}`,
                  };
                }),
            })),
        },
      ];

      if (this.userIsNotViewer) {
        links.push(
          ...[
            {
              name: "Performance",
              icon: "fa-regular fa-gauge-max",
              link: "/performance",
            },
            {
              name: "Settings",
              icon: "fa-regular fa-gear",
              link: "/settings",
            },
          ]
        );
      }

      return links;
    },
    rightNavbarOpen() {
      return this.store.state.rightNavbarOpen && this.route.path.includes("analysis/");
    },
    company() {
      return this.store.state.company;
    },
    errorMessage() {
      return this.store.state.errorText;
    },
    successMessage() {
      return this.store.state.successText;
    },
    fullScreenRoute() {
      return this.route.fullPath?.includes("login") || this.route.fullPath?.includes("reset_password");
    },
  },
  methods: {
    clearError() {
      this.store.dispatch("clearErrorText");
    },
    getViewById(id: number) {
      return this.views?.[id];
    },
    handleNavRail() {
      this.rail = !this.rail;
      if (this.rail) {
        this.analysisExpanded = false;
      } else {
        this.analysisExpanded = true;
      }
    },
    handleMySavedAnalysisClick(e: any) {
      if (e?.target.className.includes("fa-")) {
        this.showAnalysisList = !this.showAnalysisList;
      } else {
        this.router.push("/analysis");
      }
    },
    isActive(link: string) {
      return this.route.fullPath.includes(link);
    },
    clearSuccess() {
      this.store.dispatch("clearSuccessText");
    },
    logout() {
      this.store.dispatch("logout");
    },
    toggleAnalysis(name: string) {
      this.expandedAnalysis = this.expandedAnalysis === name ? "" : name;
    },
    toggleAnalysisSection() {
      this.analysisExpanded = !this.analysisExpanded;
      if (this.analysisExpanded) {
        this.rail = false;
      } else {
        this.rail = true;
      }
    },
  },
  components: { MaireNotification, WaitAnimation, ConsentModal },
});
</script>
