export const SUPPORTED_COUNTRIES = [
  "United States",
  //   "Afghanistan",
  "Albania",
  //   "Antarctica",
  //   "Algeria",
  //   "American Samoa",
  //   "Andorra",
  //   "Angola",
  //   "Antigua and Barbuda",
  //   "Azerbaijan",
  //   "Argentina",
  "Australia",
  "Austria",
  //   "The Bahamas",
  //   "Bahrain",
  //   "Bangladesh",
  //   "Armenia",
  //   "Barbados",
  //   "Belgium",
  //   "Bhutan",
  //   "Bolivia",
  //   "Bosnia and Herzegovina",
  //   "Botswana",
  //   "Brazil",
  //   "Belize",
  //   "Solomon Islands",
  //   "Brunei",
  //   "Bulgaria",
  //   "Myanmar (Burma)",
  //   "Burundi",
  //   "Belarus",
  //   "Cambodia",
  //   "Cameroon",
  "Canada",
  //   "Cape Verde",
  //   "Central African Republic",
  //   "Sri Lanka",
  //   "Chad",
  //   "Chile",
  //   "China",
  //   "Christmas Island",
  //   "Cocos (Keeling) Islands",
  //   "Colombia",
  //   "Comoros",
  //   "Republic of the Congo",
  //   "Democratic Republic of the Congo",
  //   "Cook Islands",
  //   "Costa Rica",
  //   "Croatia",
  //   "Cyprus",
  "Czechia",
  //   "Benin",
  "Denmark",
  //   "Dominica",
  //   "Dominican Republic",
  //   "Ecuador",
  //   "El Salvador",
  //   "Equatorial Guinea",
  //   "Ethiopia",
  //   "Eritrea",
  "Estonia",
  //   "South Georgia and the South Sandwich Islands",
  //   "Fiji",
  "Finland",
  "France",
  //   "French Polynesia",
  //   "French Southern and Antarctic Lands",
  //   "Djibouti",
  //   "Gabon",
  //   "Georgia",
  //   "The Gambia",
  "Germany",
  //   "Ghana",
  //   "Kiribati",
  "Greece",
  //   "Grenada",
  //   "Guam",
  //   "Guatemala",
  //   "Guinea",
  //   "Guyana",
  //   "Haiti",
  //   "Heard Island and McDonald Islands",
  //   "Vatican City",
  //   "Honduras",
  "Hong Kong",
  "Hungary",
  //   "Iceland",
  "India",
  "Indonesia",
  //   "Iraq",
  //   "Ireland",
  //   "Israel",
  "Italy",
  //   "Cote d'Ivoire",
  //   "Jamaica",
  "Japan",
  //   "Kazakhstan",
  //   "Jordan",
  //   "Kenya",
  "South Korea",
  //   "Kuwait",
  //   "Kyrgyzstan",
  //   "Laos",
  //   "Lebanon",
  //   "Lesotho",
  "Latvia",
  //   "Liberia",
  //   "Libya",
  //   "Liechtenstein",
  "Lithuania",
  //   "Luxembourg",
  //   "Madagascar",
  //   "Malawi",
  //   "Malaysia",
  //   "Maldives",
  //   "Mali",
  //   "Malta",
  //   "Mauritania",
  //   "Mauritius",
  //   "Mexico",
  //   "Monaco",
  //   "Mongolia",
  //   "Moldova",
  //   "Montenegro",
  //   "Morocco",
  //   "Mozambique",
  //   "Oman",
  //   "Namibia",
  //   "Nauru",
  //   "Nepal",
  "Netherlands",
  //   "Curacao",
  //   "Sint Maarten",
  //   "Caribbean Netherlands",
  //   "New Caledonia",
  //   "Vanuatu",
  //   "New Zealand",
  //   "Nicaragua",
  //   "Niger",
  //   "Nigeria",
  //   "Niue",
  //   "Norfolk Island",
  "Norway",
  //   "Northern Mariana Islands",
  //   "United States Minor Outlying Islands",
  //   "Federated States of Micronesia",
  //   "Marshall Islands",
  //   "Palau",
  //   "Pakistan",
  //   "Panama",
  //   "Papua New Guinea",
  //   "Paraguay",
  //   "Peru",
  //   "Philippines",
  //   "Pitcairn Islands",
  "Poland",
  "Portugal",
  //   "Guinea-Bissau",
  //   "Timor-Leste",
  //   "Qatar",
  //   "Romania",
  //   "Russia",
  //   "Rwanda",
  //   "Saint Barthelemy",
  //   "Saint Helena, Ascension and Tristan da Cunha",
  //   "Saint Kitts and Nevis",
  //   "Saint Lucia",
  //   "Saint Martin",
  //   "Saint Pierre and Miquelon",
  //   "Saint Vincent and the Grenadines",
  //   "San Marino",
  //   "Sao Tome and Principe",
  //   "Saudi Arabia",
  //   "Senegal",
  //   "Serbia",
  //   "Seychelles",
  //   "Sierra Leone",
  //   "Singapore",
  //   "Slovakia",
  //   "Vietnam",
  //   "Slovenia",
  //   "Somalia",
  //   "South Africa",
  //   "Zimbabwe",
  "Spain",
  //   "Sudan",
  //   "Suriname",
  //   "Eswatini",
  "Sweden",
  "Switzerland",
  //   "Tajikistan",
  "Thailand",
  //   "Togo",
  //   "Tokelau",
  //   "Tonga",
  //   "Trinidad and Tobago",
  //   "United Arab Emirates",
  //   "Tunisia",
  //   "Turkey",
  //   "Turkmenistan",
  //   "Tuvalu",
  //   "Uganda",
  //   "Ukraine",
  //   "North Macedonia",
  //   "Egypt",
  "United Kingdom",
  //   "Guernsey",
  //   "Jersey",
  //   "Tanzania",
  //   "Burkina Faso",
  //   "Uruguay",
  //   "Uzbekistan",
  //   "Venezuela",
  //   "Wallis and Futuna",
  //   "Samoa",
  //   "Yemen",
  //   "Zambia",
];

export const ENGLISH_SPEAKING_COUNTRIES = [
  "United States",
  "United Kingdom",
  "Australia",
  "New Zealand",
  "Canada",
];

export const SUPPORTED_LANGUAGES = [
  "English",
  //   "Arabic",
  //   "Bengali",
  //   "Bulgarian",
  //   "Catalan",
  "Chinese (Simplified)",
  "Chinese (Traditional)",
  //   "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "Estonian",
  //   "Filipino",
  "Finnish",
  "French",
  "German",
  "Greek",
  //   "Gujarati",
  //   "Hebrew",
  "Hindi",
  "Hungarian",
  //   "Icelandic",
  "Indonesian",
  "Italian",
  "Japanese",
  //   "Kannada",
  "Korean",
  "Latvian",
  "Lithuanian",
  //   "Malay",
  //   "Malayalam",
  //   "Marathi",
  "Norwegian",
  //   "Persian",
  "Polish",
  "Portuguese",
  //   "Romanian",
  "Russian",
  //   "Serbian",
  //   "Slovak",
  //   "Slovenian",
  "Spanish",
  "Swedish",
  //   "Tamil",
  //   "Telugu",
  "Thai",
  //   "Turkish",
  //   "Ukrainian",
  //   "Urdu",
  //   "Vietnamese",
];
