class MinHeap<T> {
  private heap: T[] = [];

  constructor(private compare: (a: T, b: T) => number) {}

  private parent(i: number): number {
    return Math.floor((i - 1) / 2);
  }

  private left(i: number): number {
    return 2 * i + 1;
  }

  private right(i: number): number {
    return 2 * i + 2;
  }

  public push(item: T): void {
    this.heap.push(item);
    this.bubbleUp(this.heap.length - 1);
  }

  public pop(): T | undefined {
    if (this.heap.length === 0) return undefined;
    const root = this.heap[0];
    const end = this.heap.pop();
    if (this.heap.length > 0 && end !== undefined) {
      this.heap[0] = end;
      this.sinkDown(0);
    }
    return root;
  }

  public peek(): T | undefined {
    return this.heap.length > 0 ? this.heap[0] : undefined;
  }

  public size(): number {
    return this.heap.length;
  }

  private bubbleUp(index: number): void {
    let currentIndex = index;
    const element = this.heap[currentIndex];

    while (currentIndex > 0) {
      const parentIndex = this.parent(currentIndex);
      const parent = this.heap[parentIndex];
      if (this.compare(element, parent) >= 0) break;
      this.heap[currentIndex] = parent;
      currentIndex = parentIndex;
    }
    this.heap[currentIndex] = element;
  }

  private sinkDown(index: number): void {
    let currentIndex = index;
    const length = this.heap.length;
    const element = this.heap[currentIndex];

    while (true) { // eslint-disable-line no-constant-condition
      const leftIndex = this.left(currentIndex);
      const rightIndex = this.right(currentIndex);
      let swapIndex = -1;

      if (leftIndex < length) {
        const left = this.heap[leftIndex];
        if (this.compare(left, element) < 0) {
          swapIndex = leftIndex;
        }
      }

      if (rightIndex < length) {
        const right = this.heap[rightIndex];
        if (
          (swapIndex === -1 && this.compare(right, element) < 0) ||
          (swapIndex !== -1 && this.compare(right, this.heap[swapIndex]) < 0)
        ) {
          swapIndex = rightIndex;
        }
      }

      if (swapIndex === -1) break;
      this.heap[currentIndex] = this.heap[swapIndex];
      currentIndex = swapIndex;
    }

    this.heap[currentIndex] = element;
  }
}

export function topKElements<T>(array: Array<T>, k: number, field: keyof T): Array<T> {
  if (k <= 0) return [];

  // Initialize the min heap and determine the comparison function
  const minHeap = new MinHeap<any>((a, b) => a[field] - b[field]);

  // Add the first k elements to the min heap directly
  for (const item of array) {
    if (minHeap.size() < k) {
      minHeap.push(item);
    } else if (item[field] > minHeap.peek()[field]) {
      // If the current element is larger than the smallest (top) element of the min heap,
      // sink down the former and bubble up the new element
      minHeap.pop();
      minHeap.push(item);
    }
  }

  const result = [];
  while (minHeap.size() > 0) {
    result.push(minHeap.pop());
  }

  // No need to reverse as we are directly building the required result
  return result.reverse();
}
