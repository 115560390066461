<template>
  <WaitAnimation v-if="isLoading" />
  <v-row class="remove-margin" cols="12">
    <v-col cols="5">
      <LoginForm v-if="!isPasswordResetView && !isPasswordResetTrigger" />
      <PasswordResetTriggerForm v-if="isPasswordResetTrigger" />
      <PasswordResetForm v-if="isPasswordResetView" />
    </v-col>
    <v-col class="image" cols="7"> </v-col>
  </v-row>
</template>

<style scoped>
.image {
  background-color: transparent !important;
  background-image: v-bind(imageUrl) !important;
  background-size: cover;
  position: fixed;
  right: 0px;
  height: 102vh;
  max-width: 1000px;
  z-index: -1000 !important;
}
</style>
<script lang="ts">
import { defineComponent, ref } from "vue";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import LoginForm from "@/components/LoginForm/LoginForm.vue";
import PasswordResetTriggerForm from "@/components/PasswordResetTriggerForm/PasswordResetTriggerForm.vue";
import PasswordResetForm from "@/components/PasswordResetForm/PasswordResetForm.vue";
import { useStore } from "@/store"
import { useRoute, useRouter } from "vue-router"

export default defineComponent({
  data: () => ({
    publicPath: process.env.BASE_URL,
    imageUrl: ref(`url(${process.env.BASE_URL}images/content_marketing.png)`),
  }),
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  components: { WaitAnimation, LoginForm, PasswordResetForm, PasswordResetTriggerForm },
  computed: {
    isLoading() {
      return this.store.state.loading.loadingKeywords;
    },
    isPasswordResetView() {
      return this.route.params.token && this.route.params.token !== "trigger";
    },
    isPasswordResetTrigger() {
      return this.route.params.token === "trigger";
    },
  },
});
</script>
