<template>
  <div id="login_modal" class="login_modal">
    <div class="logo"><img class="maire_logo" :src="`${publicPath}images/maire_logo.png`" /></div>
    <h2>Change your password</h2>
    <p>Login to access all the weird and wonderful things Maire has to offer.</p>
    <v-text-field
      autocomplete="new-password"
      hide-details="auto"
      type="password"
      v-model="password"
      :error="!!passwordError"
      :error-messages="passwordError"
      label="New Password"
      density="comfortable"
      variant="outlined"
      :class="`maire-teal ${!!passwordError ? '' : 'bottom-padding-20'}`"
    />
    <v-text-field
      autocomplete="new-password"
      hide-details="auto"
      type="password"
      v-model="passwordConfirm"
      :error="!!passwordConfirmError"
      :error-messages="passwordConfirmError"
      label="New password again"
      density="comfortable"
      variant="outlined"
      :class="`maire-teal ${!!passwordConfirmError ? '' : 'bottom-padding-20'}`"
    />
    <MaireButton :on-click="validateAndSubmit" text="LOGIN" />
  </div>
</template>

<style scoped>
.remove-margin {
  margin-left: -295px !important;
}

.btn {
  width: 100%;
}

.image {
  background-image: v-bind(imageUrl);
  background-size: cover;
  position: fixed;
  right: 0px;
  height: 102vh;
  max-width: 1000px;
  z-index: -1000 !important;
}

.login_modal {
  width: 350px;
  background: white;
  margin: auto;
  margin-top: 20%;
  padding: 40px;
  box-shadow: 0px 0px 20px rgba(130, 0, 255, 0.2);
}

.login_modal h2 {
  font-size: 28px;
  padding-left: 0px;
}

.login_modal p {
  padding: 0px 0px 20px 0px;
}

.login_modal .maire_logo {
  width: 40%;
  padding: 10px 20px;
}

.logo {
  text-align: center;
}

.nomargin {
  margin-left: -4px !important;
  margin-bottom: -10px !important;
}
</style>
<script lang="ts">
import { defineComponent, ref } from "vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import { useStore } from "@/store"
import { useRoute, useRouter } from "vue-router"


export default defineComponent({
  el: "#login_modal",
  name: "LoginForm",
  data: () => ({
    publicPath: process.env.BASE_URL,
    stayLoggedIn: false,
    email: "",
    password: "",
    passwordConfirm: "",
    emailError: "",
    passwordError: "",
    passwordConfirmError: "",
    imageUrl: ref(`url(${process.env.BASE_URL}images/content_marketing.png)`),
  }),
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    return { store, route, router };
  },
  mounted() {
    this.$el.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.code === "Enter") {
        this.validateAndSubmit();
      }
    });
  },
  unmounted() {
    this.$el.removeEventListener("keydown", KeyboardEvent);
  },
  methods: {
    validateAndSubmit() {
      let hasErrors = false;
      if (!this.password) {
        this.passwordError = "Please fill out your password";
        hasErrors = true;
      } else {
        this.passwordError = "";
      }
      if (this.password !== this.passwordConfirm) {
        hasErrors = true;
        this.passwordConfirmError = "Passwords don't match";
      } else {
        this.passwordConfirmError = "";
      }
      if (!hasErrors) {
        // @ts-ignore
        this.store.dispatch("change_password", {
          token: this.route.params.token,
          new_password: this.password,
        });
      }
    },
  },
  components: { MaireButton },
});
</script>
