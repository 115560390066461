<template>
  <MaireDialog
    :title="getDialogTitle"
    :description="getDialogDescription"
    :open="isOpen"
    :confirmDisabled="!name || !description"
    :hideCancel="false"
    :onClose="onClose"
    :fn="saveAnalysis"
  >
    <div id="create_analysis_modal">
      <p class="strong s">Give {{ type.toLowerCase() }} a name</p>
      <v-text-field
        v-model="name"
        variant="outlined"
        label="Name"
        placeholder="e.g. High volume online shopping analysis"
        required
      />
      <p class="strong s">Make notes about your {{ type.toLowerCase() }}</p>
      <v-text-field
        v-model="description"
        variant="outlined"
        label="Description"
        placeholder="e.g. Analysis of high volume online shopping keywords"
        required
      />
      >
    </div>
  </MaireDialog>
</template>
<style scoped>
#create_analysis_modal .v-label {
  /* margin: 12px !important; */
  word-break: break-word;
  white-space: normal;
  color: black;
}

#create_analysis_modal p {
  margin: 24px 0px;
}

input {
  color: black !important;
}
</style>

<script lang="ts">
import { defineComponent } from "vue";
import { AnalysisDetails } from "@/store/modules/analysis/types";
import MaireDialog from "@/components/MaireDialog/MaireDialog.vue";
import { useStore } from "@/store"

export default defineComponent({
  name: "CreateAnalysisFilerModal ",
  props: {
    kwIds: {
      type: Array as () => number[],
      required: false,
    },
    details: {
      type: Object as () => AnalysisDetails,
      required: false,
    },
    type: {
      type: String,
      default: 'Analysis',
      required: false
    },
    clonedFrom: {
      type: Number,
      required: false,
    },
    open: {
      type: Boolean,
      required: false,
      default: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    name: "",
    description: "",
    isOpen: true,
  }),
  mounted() {
    if (this.details) {
      this.name = this.details.name;
      this.description = this.details.description;
    }
  },
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    saveAnalysis() {
      if (!this.name || !this.description) {
        console.log("Missing name or description!");
        return;
      }
      this.onSave({
        id: this.details?.id,
        name: this.name,
        description: this.description,
        localized_keyword_ids: this.kwIds,
        clonedFrom: this.clonedFrom,
      });
      this.onClose(this.name);
    },
  },
  computed: {
    user() {
      return this.store.state.user;
    },
    getDialogTitle(){
      let text = this.details?.id ? 'Update' : 'Save as new';
      return `${text} ${this.type}`
    },
    getDialogDescription() {
      return this.kwIds?.length
        ? `${this.kwIds.length} keywords are initially included in the analysis. `
        : "";
    },
  },
  components: {
    MaireDialog,
  },
});
</script>
