<template>
  <div class="company_preferences">
    <DiscoverySearch />
  </div>
  <v-container>
    <div v-show="isLoading" class="wait-animation">
      <wait-animation />
    </div>
    <div v-show="!isLoading" id="main-analysis-view">
      <div class="drawer" v-show="!isLoading">
        <div
          class="inline-block btn"
          style="display: inline-block; width: 70%; margin-left: 24px; position: relative"
        ></div>
        <div
          class="drawer-content"
          :class="{ active: drawersOpen.includes('graphs') }"
          v-if="!isLoading && dataDateRange?.latestYear"
        >
          <v-row>
            <v-col
              :cols="widget.col"
              :md="12"
              :sm="12"
              :lg="6"
              :xl="widget.col"
              :key="widget.name"
              v-for="widget in widgets"
            >
              <MaireChart
                :name="widget.name"
                :id="widget.id"
                :volumes="widget.volumes"
                :dataDateRange="dataDateRange"
                :initialType="widget.type"
                :defaultField="widget.defaultField"
                :label="widget.name"
                :loading="!!widget.processing"
                :itemType="widget.itemType"
                :defaultTimeframeText="widget.defaultTimeframeText"
                :discovery="true"
                :kwIds="widget.kwIds"
                :params="widget.params"
                :hideIfEmpty="widget.hideIfEmpty"
              >
                <div id="prediction-button" v-if="widget.name === 'Timeline'">
                  <MaireButton
                    :compact="true"
                    @click="fetchTimelinePredictions"
                    text="Fetch predictions"
                    :isLoading="!!widget.processing"
                    :disabled="!allowPredicting"
                  /></div
              ></MaireChart>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-row v-if="!isLoading">
        <v-col cols="12" class="container-margin">
          <div>
            <div class="title-and-search">
              <div class="mb-3 title-text"></div>
              <div class="search_container">
                <SearchBar />
              </div>
            </div>
            <div v-show="!isLoading">
              <keyword-table :keywords="filteredKeywords" :externalTotals="false" />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped>
#prediction-button {
  width: 150px;
}
#main-analysis-view {
  margin-top: 50px;
}
.center-info {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  text-align: center;
  transform: translate(0%, 50%);
}

.center-info p {
  text-align: center;
}
.information-paper {
  position: relative;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 70%;
  max-width: 600px;
  padding: 24px;
  background-color: white;
  border-radius: 0px !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
.graphs {
  padding-top: 24px;
  padding-right: 24px;
}
.drawer-content {
  display: none;
  margin-top: 24px;
}
.drawer-content.active {
  display: block;
}
.drawer-chevron {
  position: relative;
  cursor: pointer;
  float: right;
}
.drawer-bar {
  width: 100%;
  cursor: pointer;
}
.container-margin {
  margin-top: 12px;
  margin-bottom: 12px;
}

.title-text {
  margin-bottom: 36px;
  padding-bottom: 20px !important;
  width: 70%;
  display: inline-block;
}
.search_container {
  width: 30%;
  float: right;
  position: relative;
  display: block;
}

.loading {
  filter: blur(4px) brightness(100%);
}
</style>
<script lang="ts">
// import KeywordFilters from "@/components/KeywordFilters/KeywordFilters.vue";
import KeywordTable from "@/components/KeywordTable/KeywordTable.vue";
import SearchBar from "@/components/DataTable/SearchBar.vue";
import DiscoverySearch from "@/components/DiscoverySearch/DiscoverySearch.vue";
import MaireButton from "@/components/ButtonBlock/MaireButton.vue";
import MaireChart from "@/components/MaireChart/MaireChart.vue";
import WaitAnimation from "@/components/WaitAnimation/WaitAnimation.vue";
import { filterKeywords } from "@/components/helpers/helpers";
import { WidgetType, useStore } from "@/store";
import { useRouter } from "vue-router";
import { Keyword } from "@/store/modules/keywords/types";
import { Ref, computed, defineComponent, ref, watch, onMounted } from "vue";
import * as api from "@/api";
export default defineComponent({
  setup() {
    const store = useStore();

    if (store.state.user?.type === "viewer") {
      const router = useRouter();
      router.push({ path: "analysis" });
    }
    const allowPredicting = ref(false);
    const loadingTimelineData = ref(false);
    const timelineStats = ref({});
    const fetchTimelinePredictions = async () => {
      if (filteredIds.value.length === 0) {
        return;
      }
      loadingTimelineData.value = true;
      const r = await api.fetchPredictions({ series: timelineStats.value });
      allowPredicting.value = false;
      loadingTimelineData.value = false;
      timelineStats.value = r.data;
    };
    const fetchTimelineStats = async (predictions?: boolean) => {
      if (filteredIds.value.length === 0) {
        return;
      }
      loadingTimelineData.value = true;
      const r = await api.fetchKwTimelineStats(filteredIds.value, predictions);
      allowPredicting.value = true;
      loadingTimelineData.value = false;
      timelineStats.value = r.data;
    };
    const isLoading = computed(() => store.state.loading.loadingKeywords);
    const drawersOpen = ref(["graphs"] as string[]);
    const keywords = computed(() => store.state.keywords.discoveryKeywords);

    const filters = computed(() => store.state.analysis.currentFilters);
    const filteredKeywords = computed(() => {
      // @ts-ignore
      const kws = filterKeywords(keywords.value, filters.value, false) as Array<Keyword>;
      return kws;
    });
    const filteredIds = computed(() => filteredKeywords.value.map((kw) => kw.id));

    const processing: Ref<boolean> = ref(true);
    const widgets = computed(() => {
      return [
        {
          id: 6,
          type: WidgetType.TimelineChart,
          name: "Timeline",
          volumes: timelineStats.value,
          defaultField: "",
          fields: [],
          processing: loadingTimelineData.value,
          col: 4,
        },
        {
          id: 3,
          type: WidgetType.BarChart,
          kwIds: filteredIds.value,
          name: "Trending by keyword",
          defaultTimeframeText: "Latest",
          defaultField: "trending",
          col: 4,
          itemType: "keyword",
        },
        {
          id: 55,
          type: WidgetType.BarChart,
          kwIds: filteredIds.value,
          name: "Volume by keyword",
          defaultTimeframeText: "Last 12 months",
          defaultField: "volume",
          col: 4,
          itemType: "keyword",
        },
        {
          id: 4,
          type: WidgetType.BarChart,
          kwIds: filteredIds.value,
          name: "Growth by keyword",
          defaultTimeframeText: "Last 12 months",
          defaultField: "growth",
          col: 4,
          itemType: "keyword",
        },
        {
          id: 4,
          type: WidgetType.BarChart,
          kwIds: filteredIds.value,
          name: "Absolute growth by keyword",
          defaultTimeframeText: "Last 12 months",
          defaultField: "absoluteGrowth",
          col: 4,
          itemType: "keyword",
        },
        {
          id: 5,
          type: WidgetType.BarChart,
          kwIds: filteredIds.value,
          defaultField: "volume",
          params: { include_only_new: true },
          hideIfEmpty: true,
          name: "New keywords",
          col: 6,
          itemType: "keyword",
        },
      ];
    });

    // let prevProps: any = {};
    const dataDateRange = ref();

    watch(
      filteredKeywords,
      (newValue, oldValue) => {
        const oldIds = new Set(oldValue?.map((kw) => kw.id) || []);
        // set of new ids
        const newIds = new Set(newValue?.map((kw) => kw.id));
        if (!newValue?.[0]?.volumes) {
          timelineStats.value = [];
          return;
        }
        const lastYear = Math.max(...Object.keys(newValue?.[0]?.volumes).map((key) => parseInt(key)));
        const lastMonth = newValue?.[0].volumes?.[lastYear].filter((vol) => vol !== null).length;
        const dt = new Date(`${lastYear}-${lastMonth}-2`);
        if (dt) {
          const untilDate = new Date(dt);
          const fourYearsAgo = new Date(dt);
          fourYearsAgo.setFullYear(untilDate.getFullYear() - 4);
          fourYearsAgo.setMonth(untilDate.getMonth() + 1);
          dataDateRange.value = {
            firstYear: fourYearsAgo.getFullYear(),
            firstMonth: fourYearsAgo.getMonth() + 1,
            latestYear: untilDate.getFullYear(),
            latestMonth: untilDate.getMonth() + 1,
          };
        }
        if (oldIds.size !== newIds.size) {
          fetchTimelineStats();
        } else {
          // same size so we need to check if the ids are the same
          for (const id of oldIds) {
            if (!newIds.has(id)) {
              fetchTimelineStats();
              return;
            }
          }
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      store.dispatch("selectDynamicFilter");
    });
    return {
      isLoading,
      dataDateRange,
      filteredKeywords,
      processing,
      drawersOpen,
      widgets,
      timelineStats,
      allowPredicting,
      fetchTimelinePredictions,
      filterKeywords,
    };
  },
  components: {
    DiscoverySearch,
    KeywordTable,
    MaireButton,
    MaireChart,
    SearchBar,
    WaitAnimation,
  },
});
</script>
