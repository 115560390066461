import { ActionTree, Module, MutationTree } from "vuex";
import { State as RootState, store } from "@/store/index";
import { ArticleState, Image, Subtitle } from "./types";
import axios from "axios";
import { BACKEND_PATH } from "@/helpers";

// State
const state: ArticleState = {
  contentTypes: ["Review", "Guide", "Explanation", "Reasoning", "Statistics", "Product / Service -page"],
  keyword: undefined,
  titleSuggestions: undefined,
  subTitleSuggestions: undefined,
  imagePromptSuggestions: undefined,
  title: undefined,
  subtitles: undefined,
  sections: undefined,
  abstract: undefined,
  contentType: "Guide",
  abstractApproved: false,
  imagesApproved: false,
  loadingTitleSuggestions: false,
  loadingImagePromptSuggestions: false,
  images: [],
  loadingSection: [],
};

// Mutations
const mutations: MutationTree<ArticleState> = {
  setAbstractApproved: (state, approved: boolean) => (state.abstractApproved = approved),
  setImagesApproved: (state, approved: boolean) => (state.imagesApproved = approved),
  removeImageFromArticle(state, image: Image) {
    state.images = state.images?.filter((articleImage) => articleImage !== image);
  },
  addArticleSection(state, params: { section: Subtitle; after: string }) {
    const existingTitles = state.sections?.map((sect) => sect.title);
    if (existingTitles?.includes(params.section.title)) {
      return;
    }
    const newSections = [] as Subtitle[];
    if (params.after === "first") {
      newSections.unshift(params.section);
    }
    state.sections?.forEach((sect) => {
      newSections.push(sect);
      if (sect.title === params.after && params.after !== "first") {
        newSections.push(params.section);
      }
    });
    state.sections = newSections;
  },
  removeArticleSection(state, section: Subtitle) {
    state.sections = state.sections?.filter((sect) => sect !== section);
  },
  replaceParagraphForSubtitle(state, params: { paragraph: string; subtitle: string }) {
    state.sections = state?.sections?.map((section) => {
      if (section.title === params.subtitle) {
        section.text = params.paragraph;
        return section;
      } else {
        return section;
      }
    });
  },
  setLoadingParagraph(state, subtitle: string) {
    state.sections = state?.sections?.map((section) => {
      if (section.title === subtitle) {
        section.loading = !section.loading;
        return section;
      } else {
        return section;
      }
    });
  },
  appendImageToArticle(state, image: Image) {
    if (!state.images) {
      state.images = [image];
    } else {
      state.images.push(image);
    }
  },
  setArticleTitle: (state, title: string) => {
    state.title = title;
  },
  setArticleAbstract: (state, abstract: string) => {
    state.abstract = abstract;
  },
  setArticleSubtitles: (state, subtitles: string[]) => {
    state.subtitles = subtitles;
  },
  setArticleSubtitleSuggestions: (state, subtitles: string[]) => {
    const existingTitles = state.sections?.map((section) => section.title);
    const newSuggestions = subtitles.filter((subtitle) => !existingTitles?.includes(subtitle));
    state.subTitleSuggestions = newSuggestions;
  },
  setSections: (state, article: ArticleState) => {
    state.sections = article.sections;
  },
  setSelectedContentType: (state, contentType: string) => {
    state.contentType = contentType;
  },
  setLoadingTitleSuggestions: (state, loading: boolean) => (state.loadingTitleSuggestions = loading),
  setLoadingImagePromptSuggestions: (state, loading: boolean) =>
    (state.loadingImagePromptSuggestions = loading),
  addLoadingSection: (state, sectionName: string) => {
    if (!state.loadingSection) {
      state.loadingSection = [sectionName];
    } else if (state.loadingSection && !state.loadingSection.includes(sectionName)) {
      state.loadingSection.push(sectionName);
    }
  },
  removeLoadingSection: (state, sectionName: string) => {
    if (state.loadingSection && state.loadingSection.includes(sectionName)) {
      state.loadingSection = state.loadingSection.filter((section) => section !== sectionName);
    }
  },
  setTitleSuggestions: (state, suggestions: String[]) => (state.titleSuggestions = suggestions),
  setImagePromptSuggestions: (state, suggestions: String[]) => (state.imagePromptSuggestions = suggestions),
  setArticleKeyword: (state, keyword: string) => (state.keyword = keyword),
  clearArticle: (state) => {
    state = {
      contentType: state.contentType,
      subTitleSuggestions: state.subTitleSuggestions,
    };
  },
};

// Actions
const actions: ActionTree<ArticleState, RootState> = {
  async generateImage({ commit }, params: { keyword: string }) {
    commit("loading/setLoadingImage", true, { root: true });
    const r = await axios.get(`${BACKEND_PATH}/generate_image/${params.keyword}`);
    if (r.status === 200) {
      commit("appendImageToArticle", {
        imageSrc: r.data,
        prompt: `img_prompt${params.keyword}`,
      });
    } else {
      commit("setErrorText", "Failed to generate title", { root: true });
    }
    commit("loading/setLoadingImage", false, { root: true });
  },
  async generateAbstractForTitle({ commit }, params: { title: string }) {
    commit("loading/setLoadingAbstract", true, { root: true });
    const r = await axios.get(
      `${BACKEND_PATH}/generate_blog_abstract?title=${params.title}&language=${
        store.state.keywords.keywordWithDetails?.language_name
      }&content_type=${state.contentType}`
    );
    if (r.status === 200) {
      commit("setArticleAbstract", r.data);
    } else {
      commit("setErrorText", "Failed to generate title", { root: true });
    }
    commit("loading/setLoadingAbstract", false, { root: true });
  },
  async continueWriting({ commit }, params: { title: string; sectionName: string; text: string }) {
    commit("addLoadingSection", params.sectionName);
    const reqParams = {
      title: params.title,
      language: store.state.keywords.keywordWithDetails?.language_name,
      content_type: state.contentType,
      sectionName: params.sectionName,
      text: params.text,
    };
    const r = await axios.get(`${BACKEND_PATH}/continue_writing`, {
      params: reqParams,
    });
    if (r.status === 200) {
      if (params.sectionName === "abstract") {
        commit("setArticleAbstract", `${params.text} ${r.data}`);
      } else {
        commit("replaceParagraphForSubtitle", {
          paragraph: `${params.text} ${r.data}`,
          subtitle: params.sectionName,
        });
      }
    } else {
      commit("setErrorText", "Failed to generate title", { root: true });
    }
    commit("removeLoadingSection", params.sectionName);
  },
  async generateSubtitlesForTitle({ commit }, params: { title: string }) {
    commit("loading/setLoadingSubtitles", true, { root: true });
    const r = await axios.get(
      `${BACKEND_PATH}/generate_blog_subtitles?title=${params.title}&language=${
        store.state.keywords.keywordWithDetails?.language_name
      }&content_type=${state.contentType}`
    );
    if (r.status === 200) {
      commit("setArticleSubtitles", r.data);
    } else {
      commit("setErrorText", "Failed to generate title", { root: true });
    }
    commit("loading/setLoadingSubtitles", false, { root: true });
  },
  async generateSubTitleSuggestions({ commit }, params: { title: string }) {
    commit("loading/setLoadingSubtitles", true, { root: true });
    let existingTitles;
    if (state.sections) {
      existingTitles = state.sections.map((sect) => sect.title);
    }
    const r = await axios.get(
      `${BACKEND_PATH}/generate_blog_subtitles?title=${params.title}&language=${
        store.state.keywords.keywordWithDetails?.language_name
      }&content_type=${state.contentType}&existing=${existingTitles}`
    );
    if (r.status === 200) {
      commit("setArticleSubtitleSuggestions", r.data);
    } else {
      commit("setErrorText", "Failed to generate title", { root: true });
    }
    commit("loading/setLoadingSubtitles", false, { root: true });
  },
  async generateImagePromptSuggestions({ commit }, title: string) {
    commit("setLoadingImagePromptSuggestions", true);
    let existingTitles;
    if (state.sections) {
      existingTitles = state.sections.map((sect) => sect.title);
    }
    const params = {
      keyword: title,
      language: store.state.keywords.keywordWithDetails?.language_name,
    };
    const r = await axios.get(`${BACKEND_PATH}/image_prompt_suggestions`, {
      params,
    });
    if (r.status === 200) {
      commit("setImagePromptSuggestions", r.data);
    } else {
      commit("setErrorText", "Failed to generate image prompt suggestions", { root: true });
    }
    commit("setLoadingImagePromptSuggestions", false);
  },
  async addNewSection({ commit }, params: { section: string; newSubtitle: string }) {
    const { section, newSubtitle } = params;
    commit("addArticleSection", {
      section: {
        title: newSubtitle,
        text: "",
        images: [],
        loading: false,
      },
      after: section,
    });
  },
  async removeSection({ commit }, params: { section: Subtitle }) {
    commit("removeArticleSection", params.section);
  },
  async generateParagraphForSubtitle({ commit }, params: { title: string; subtitle: string }) {
    commit("article/setLoadingParagraph", params.subtitle, { root: true });
    const r = await axios.get(
      `${BACKEND_PATH}/generate_paragraph?title=${params.title}&subtitle=${params.subtitle}&language=${
        store.state.keywords.keywordWithDetails?.language_name
      }&content_type=${state.contentType}`
    );
    if (r.status === 200) {
      commit("replaceParagraphForSubtitle", {
        paragraph: r.data,
        subtitle: params.subtitle,
      });
    } else {
      commit("setErrorText", "Failed to generate title", { root: true });
    }
    commit("article/setLoadingParagraph", params.subtitle, { root: true });
  },
  async generateArticle({ commit }, article: ArticleState) {
    commit("loading/setLoadingArticle", true, { root: true });
    const r = await axios
      .post(
        `${BACKEND_PATH}/generate_article?language=${
          store.state.keywords.keywordWithDetails?.language_name
        }`,
        article
      )
      .then((data) => data)
      .catch((e) => e);
    if (r.status === 200) {
      commit("setSections", r.data);
    } else {
      commit("setErrorText", "Failed to generate article!", { root: true });
    }
    commit("loading/setLoadingArticle", false, { root: true });
  },
  async reorderSubtitles({ commit }, subtitles: string[]) {
    commit("setArticleSubtitles", subtitles);
  },
  async setAbstract({ commit }, abstract: string) {
    commit("setArticleAbstract", abstract);
  },
  async removeImage({ commit }, image: Image) {
    commit("removeImageFromArticle", image);
  },
  async approveArticleAbstract({ commit }, approved: boolean) {
    commit("setAbstractApproved", approved);
  },
  async approveArticleImages({ commit }, approved: boolean) {
    commit("setImagesApproved", approved);
  },
  async setArticleSubtitles({ commit }, subtitles: string[]) {
    commit("setArticleSubtitles", subtitles);
  },
  async selectTitle({ commit }, title: string) {
    commit("setArticleTitle", title);
  },
  async deleteSubtitle({ commit }, params: { subtitle: string }) {
    commit(
      "setArticleSubtitles",
      state.subtitles?.filter((st) => st !== params.subtitle)
    );
  },
  async addSubtitle({ commit }) {
    commit("setArticleSubtitles", ["", ...(state.subtitles || [])]);
  },
  async generateTitleSuggestions({ commit }, params: { keyword: string; keepArticle: boolean }) {
    if (!params.keepArticle) {
      commit("clearArticle");
    } else {
      commit("setLoadingTitleSuggestions", true);
      commit("setTitleSuggestions", []);
    }
    const response = await axios.get(
      `${BACKEND_PATH}/generate_blog_title?keyword=${params.keyword}&language=${
        store.state.keywords.keywordWithDetails?.language_name
      }&content_type=${state.contentType}`
    );
    if (response.status === 200) {
      commit("setTitleSuggestions", response.data);
      commit("setArticleKeyword", params.keyword);
    } else {
      commit("setErrorText", "Failed to generate title", { root: true });
    }
    commit("setLoadingTitleSuggestions", false);
  },
  async setArticleTitle({ commit }, params: { title: string }) {
    commit("setArticleTitle", params.title);
  },
  async selectContentType({ commit }, contentType: string) {
    commit("setSelectedContentType", contentType);
  },
};

const articleModule: Module<ArticleState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default articleModule;
