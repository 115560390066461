<template>
  <div class="home">
    <AuthenticationBlock msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
// @ is an alias to /src
import AuthenticationBlock from "@/components/admin/AuthenticationBlock.vue";

export default {
  name: "AdminView",
  components: {
    AuthenticationBlock,
  },
};
</script>
