<template>
  <div class="action-icon" @click="handleClick">
    <v-icon class="icon" aria-label="My Account" role="img" aria-hidden="false">{{ icon }}</v-icon>
    <v-icon class="caret">fa-regular {{ toggled ? "fa-angle-up" : "fa-angle-down" }}</v-icon>
  </div>
</template>

<style scoped>
.action-icon {
  color: white;
  display: inline;
  margin-left: 24px;
  cursor: pointer;
}
.icon {
  font-size: 14px;
}
.caret {
  padding-left: 6px;
  font-size: 14px;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ActionIcon",
  props: {
    icon: String,
    onToggle: Function,
    toggled: Boolean,
  },
  data: () => ({
  }),
  mounted() {},
  methods: {
    handleClick() {
      if(this.onToggle) {
        this.onToggle();
      }
    },
  },
  components: {},
});
</script>
