<template>
  <strong>{{ field && field.field === "potential" ? toUSD(fieldValue || 0) : fieldValue }}</strong>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { toUSD } from "@/components/helpers/helpers";

export default defineComponent({
  name: "StrongCell",
  props: {
    fieldValue: [Number, String],
    field: Object,
  },
  methods: {
    toUSD,
  },
});
</script>
