import { Commit } from "vuex";

let socket: WebSocket | undefined = undefined;

const initialize = (commit: Commit): WebSocket => {
  if (socket && (socket.readyState === WebSocket.OPEN || socket.readyState === WebSocket.CONNECTING)) {
    return socket;
  }

  const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
  const socketUrl = `${protocol}${window.location.host}/api/ws`;
  console.log(`Connecting to websocket at ${socketUrl}`);
  socket = new WebSocket(socketUrl);

  socket.onopen = () => {
    console.log("WebSocket connection to Maire backend initialized.");
  };

  const reconnect = () => {
    setTimeout(function () {
      initialize(commit);
    }, 500);
  };

  socket.onclose = (e) => {
    console.log("WebSocket is closed. Reconnecting..", e.reason);
    reconnect();
  };

  socket.onerror = (e) => {
    console.error("WebSocket encountered error: ", e, "Closing and reconnecting");
    socket && socket.close();
    reconnect();
  };

  socket.onmessage = (e) => {
    const data = JSON.parse(e.data);
    if (data.type === "notification") {
      commit("setSuccessText", data.message);
      if (data.message?.includes("Finished refreshing data")) {
        commit("loading/refreshInProgress", false, { root: true });
      }
      if (data.message?.includes("Labeling finished")) {
        commit("loading/setLabelingInProgress", false, { root: true });
      }
    }
    if (data.type === "chat") {
      commit("addMessageToConversation", data.message)
    }
  };

  return socket;
};

export default { initialize };
