<template>
  <a target="_blank" :href="url"> <img :src="image_url" /></a>
</template>
<style scoped>
.thumbnail {
  max-width: 100%;
}
img {
  max-width: 100%;
}
a {
  word-break: break-all;
  font-size: 12px;
  text-overflow: clip;
  white-space: normal !important;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ImageCard",
  props: {
    alt: String,
    image_url: String,
    url: String,
  },
});
</script>
