<template>
  <div class="three-dot-menu" @click.stop>
    <span class="bar-icon"><v-icon @click="handleToggle">fas fa-ellipsis-vertical</v-icon></span>
    <Transition
      ><div
        v-if="props.isOpen"
        class="analysis-menu maire-drop-shadow-intense paper-background xs pa-2"
        @mouseleave="emit('menu-closed')"
      >
        <div
          :class="`action ${action.disabled || action.inProgress ? 'disabled' : ''}`"
          :key="action.name"
          v-for="action in props.actions"
          @click="!(action.disabled || action.inProgress) && invokeAction(action.fn)"
        >
          <span class="inline-block">
            <v-icon>{{ action.icon }}</v-icon>
            {{ action.name }}
            <v-progress-circular v-if="action.inProgress" style="margin-left: 12px" size="18" indeterminate />
          </span>
        </div>
      </div>
    </Transition>
  </div>
</template>
<style scoped>
.disabled {
  filter: grayscale(100%);
  cursor: not-allowed;
}
.three-dot-menu {
  color: rgba(var(--v-theme-mairePurple), 1);
  display: flex;
  position: absolute;
  padding-right: 8px;
  transform:translate(0%, -0%);
  z-index: 2;
  width: 24px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
}
.three-dot-menu i::before {
  padding: 8px;
}
.three-dot-menu:hover .bar-icon i::before {
  background-color: rgba(var(--v-theme-mairePurpleSecondary), 0.1);
  border-radius: 12px;
}
.analysis-menu {
  color: rgba(var(--v-theme-maireDarkBlue), 1);
  position: absolute;
  right: -200px;
  width: 200px;
  z-index: 1;
}
.analysis-menu i {
  margin-right: 12px;
}
.action span,
.action i {
  color: rgba(var(--v-theme-maireDarkBlue), 1) !important;
}
.action {
  padding: 12px;
}
.action:hover {
  background-color: rgba(var(--v-theme-mairePurple), 0.1);
}
</style>

<script lang="ts" setup>
import { Action } from "./types";
import { defineProps, defineEmits, PropType } from "vue";
const props = defineProps({
  isOpen: Boolean,
  actions: { type: Array as PropType<Action[]> },
});

const emit = defineEmits(["menu-opened", "menu-closed"]);

const handleToggle = () => {
  if (props.isOpen) {
    emit("menu-closed");
  } else {
    emit("menu-opened");
  }
};
const invokeAction = (fn: Function) => {
  fn();
  emit("menu-closed");
};
</script>
